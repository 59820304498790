import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * There is functionality on the Error pages that needs to be properly tested by QA. In order to display those pages,
 * the tester needs to be able to throw errors within the site. Our solution is to add error-throwing functions to the window.
 * The current functions & the component they render are:
 * - window.EDWIN.developer.throwSiteError() : app/containers/error/SiteErrorPage
 * - window.EDWIN.developer.throwPageError() : app/containers/error/PageErrorPage
 */

function ErrorThrowerHelper({ message, shouldThrow = false }) {
  if (shouldThrow) {
    throw new Error(message)
  }
  return null
}
ErrorThrowerHelper.propTypes = {
  message: PropTypes.string,
  shouldThrow: PropTypes.bool,
}

function useDevErrorThrower(funcName) {
  const [shouldThrow, setShouldThrow] = useState(false)

  useEffect(() => {
    if (!window.EDWIN.developer) {
      window.EDWIN.developer = {}
    }
    window.EDWIN.developer[funcName] = () => {
      setShouldThrow(true)
    }
  }, [])

  return shouldThrow
}

/**
 * Throws an error with the message `message` when the function `window.EDWIN.developer[funcName]()` is called
 * @param {string} funcName Error-throwing function name in window.EDWIN.developer namespace
 * @param {string} message  Error message in the thrown error
 * @returns {JSX.Element}   React Element
 */
export function DevErrorThrower({
  funcName,
  message = 'Developer-triggered error.',
}) {
  const shouldThrow = useDevErrorThrower(funcName)
  return <ErrorThrowerHelper message={message} shouldThrow={shouldThrow} />
}
DevErrorThrower.propTypes = {
  funcName: PropTypes.string.isRequired,
  message: PropTypes.string,
}
