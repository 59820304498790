import { take, fork, all, delay } from 'redux-saga/effects'
import {
  createServiceWorkerUpdateChannel,
  createServiceWorkerReadyChannel,
} from './channels/serviceWorkerUpdateChannel'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'

function* watchNavigationForUpdateCheck(): Generator {
  window.logger.log(
    '[Service Worker Saga] Starting watch for navigation trigger updates',
  )
  const serviceWorkerReadyChannel = createServiceWorkerReadyChannel()
  const message = yield take(serviceWorkerReadyChannel)
  while (true) {
    // nav for update check trigger
    window.logger.log(
      '[Service Worker Saga] Service worker ready. Watching navigation for update check opertunity',
    )
    const loc = yield take(LOCATION_CHANGE)
    switch ((loc as LocationChangeAction).payload.location.pathname) {
      case '/':
      case '/library':
      case '/collections':
      case '/external-resources': {
        window.logger.log(
          '[Service Worker Saga] Nav Trigger - Performing update check for new service worker',
        )
        // noinspection JSIgnoredPromiseFromCall
        const typedMessage = message as {
          payload: { registration: ServiceWorkerRegistration }
        }
        typedMessage.payload.registration.update()
        break
      }
    }
  }
}

function* watchTimerForUpdateCheck(): Generator {
  window.logger.log(
    '[Service Worker Saga] Starting watch for timmer trigger updates',
  )
  const serviceWorkerReadyChannel = createServiceWorkerReadyChannel()
  const message = yield take(serviceWorkerReadyChannel)
  while (true) {
    // timer for update check trigger
    window.logger.log(
      '[Service Worker Saga] Service worker ready. Watching timer for update check opertunity',
    )
    window.logger.log(
      '[Service Worker Saga] Waiting 1 hour for next update check ',
    )
    yield delay(3600000)
    window.logger.log(
      '[Service Worker Saga] Timer Trigger - Performing update check for new service worker',
    )
    // noinspection JSIgnoredPromiseFromCall
    const typedMessage = message as {
      payload: { registration: ServiceWorkerRegistration }
    }
    typedMessage.payload.registration.update()
  }
}

function* watchServiceWorkerUpdateFlow(): Generator {
  window.logger.log(
    '[Service Worker Saga] Starting watch for service worker updates',
  )
  const serviceWorkerChannel = createServiceWorkerUpdateChannel()
  yield take(serviceWorkerChannel)
  while (true) {
    // nav for trigger
    window.logger.log(
      '[Service Worker Saga] Update installed and waiting. Watching navigation for trigger opertunity',
    )
    const loc = yield take(LOCATION_CHANGE)
    switch ((loc as LocationChangeAction).payload.location.pathname) {
      case '/':
      case '/library':
      case '/collections':
      case '/external-resources': {
        window.logger.log(
          '[Service Worker Saga] Activating service worker on navigation:',
          (loc as LocationChangeAction).payload.location.pathname,
        )
        window.location.reload()
        break
      }
    }
  }
}

export function* serviceWorkerSaga() {
  window.logger.log('[Service Worker Saga] Service worker saga started')
  yield all([
    fork(watchServiceWorkerUpdateFlow),
    fork(watchNavigationForUpdateCheck),
    fork(watchTimerForUpdateCheck),
  ])
}
