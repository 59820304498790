import React, { FC } from 'react'
import styles from './LoadingPage.module.scss'
// todo: replace with @edwin-edu/ui components
import { Spinner, EdwinLogoSvg } from '@edwin-edu/ui-web-components'
import { useTimeoutRedirect } from '../hooks/useTimeoutRedirect'
import SiteErrorPage from './error/SiteErrorPage'
import { Page } from '../components/Page'
import { HOME_PATH } from '../constants'

// NOTE: Locale is not available in this component. If adding text, refactor to add LocaleProvider.
interface Props {
  error?: unknown
  timeoutRedirect?: number
}
export const LoadingPage: FC<Props> = ({ error, timeoutRedirect }) => {
  useTimeoutRedirect({ timeout: timeoutRedirect, url: HOME_PATH })

  if (error) {
    window.logger.error('Loading error')
    window.logger.error(error)
    return <SiteErrorPage />
  } else {
    return (
      <Page className={styles.page}>
        <div className={styles.spinnerContainer}>
          <Spinner onDarkBackground />
          <EdwinLogoSvg className={styles.edwinLogoIconHigherUp} />
        </div>
      </Page>
    )
  }
}
