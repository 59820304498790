import { offsetLimitPagination } from '@apollo/client/utilities'

const mergeItemsById = (existing, incoming, { readField, mergeObjects }) => {
  const merged = existing ? existing.slice(0) : []
  const itemIdToIndex = Object.create(null)
  if (existing) {
    existing.forEach((item, index) => {
      itemIdToIndex[readField('id', item)] = index
    })
  }
  incoming.forEach((item) => {
    const id = readField('id', item)
    const index = itemIdToIndex[id]
    if (typeof index === 'number') {
      // Merge the new item data with the existing item data.
      merged[index] = mergeObjects(merged[index], item)
    } else {
      // First time we've seen this item in this array.
      itemIdToIndex[id] = merged.length
      merged.push(item)
    }
  })
  return merged
}

// DEV NOTE: Hot-reload does not work with this file. Restart the server to see changes.
export const typePolicies = {
  RecentlyViewedLO: {
    fields: {
      // There are duplicate entries with the same id, but different times, which gets mapped to single entries. Always accept latest time entry.
      viewedTime: {
        merge(existing, incoming) {
          if (!existing) {
            return incoming
          }
          return incoming > existing ? incoming : existing
        },
      },
    },
  },
  Collection: {
    fields: {
      items: {
        merge(existing, incoming = []) {
          return incoming
        },
      },
    },
  },
  Course: {
    fields: {
      // always returns full array of students
      students: {
        merge(existing, incoming = []) {
          return incoming
        },
      },
      // always returns full array of teachers
      teachers: {
        merge(existing, incoming = []) {
          return incoming
        },
      },
      coveredObjectives: {
        merge(existing, incoming = []) {
          return incoming
        },
      },
    },
  },
  CourseProvinceTree: {
    keyFields: ['courseId'],
  },
  GradeTree: {
    fields: {
      subjects: {
        merge(existing = [], incoming = []) {
          return [...incoming]
        },
      },
    },
  },
  ListLicensedUsersResponse: {
    fields: {
      users: {
        keyArgs: [
          'sortKey',
          'sortOrder',
          'searchFieldsList',
          'searchValuesList',
          'keywordSearch',
        ],
        merge: mergeItemsById,
      },
    },
  },
  Query: {
    fields: {
      listEdwinCollections: offsetLimitPagination(['searchTerm', 'categoryId']),
    },
  },
}
