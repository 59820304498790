import { createAction } from './index'
import { BrowseCurriculum, LibraryBrowseState } from '../reducers/reducerTypes'

export enum LibraryBrowseActionType {
  UPDATE_BROWSE_SEARCH_TERM = 'EDWIN/LIBRARY/BROWSE/SEARCH_TERM/UPDATE',
  SET_BROWSE_SUBJECT = 'EDWIN/LIBRARY/BROWSE/SET_SUBJECT',
  SET_BROWSE_SUBJECT_SEARCH = 'EDWIN/LIBRARY/BROWSE/SET_SUBJECT_SEARCH',
  SET_BROWSE_TOPIC = 'EDWIN/LIBRARY/BROWSE/SET_TOPIC',
  SET_BROWSE_FOCUS = 'EDWIN/LIBRARY/BROWSE/SET_FOCUS',
  SET_BROWSE_SUBFOCUS = 'EDWIN/LIBRARY/BROWSE/SET_SUBFOCUS',
  SET_BROWSE_CURRICULUM = 'EDWIN/LIBRARY/BROWSE/SET_CURRICULUM',
  SET_BROWSE_OBJECTIVE_COURSE = 'EDWIN/LIBRARY/BROWSE/SET_BROWSE_OBJECTIVE_COURSE',
  UPDATE_BROWSE_CURRICULUM = 'EDWIN/LIBRARY/BROWSE/UPDATE_CURRICULUM',
  TOGGLE_BROWSE_MEDIA_TYPE = 'EDWIN/LIBRARY/BROWSE/TOGGLE_MEDIA_TYPE',
  TOGGLE_BROWSE_TOC_COLLAPSE = 'EDWIN/LIBRARY/BROWSE/TOGGLE_TOC_COLLAPSE',
  RESET_TOC_COLLAPSE = 'EDWIN/LIBRARY/BROWSE/RESET_TOC_COLLAPSE',
  SET_BROWSE_LAST_VISIBLE_LOCATION = 'EDWIN/LIBRARY/BROWSE/LAST_VISIBLE_LOCATION/SET',
  SET_BROWSE_PREVIOUS_RESULTS = 'EDWIN/LIBRARY/BROWSE/PREVIOUS_RESULTS/SET',
  CLEAR_MEDIA_AND_ITEM_TYPES = 'EDWIN/LIBRARY/BROWSE/CLEAR_MEDIA_AND_ITEM_TYPES',
}

// browse search input
export const updateSearchTermAction = (
  searchTerm: LibraryBrowseState['searchTerm'],
) =>
  createAction(LibraryBrowseActionType.UPDATE_BROWSE_SEARCH_TERM, {
    searchTerm,
  })

// browse filters
export const setBrowseSubjectAction = (
  selectedBrowseSubject: LibraryBrowseState['selectedBrowseSubject'],
) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_SUBJECT, {
    selectedBrowseSubject,
  })

export const setBrowseSubjectSearchAction = (
  selectedBrowseSubject: LibraryBrowseState['selectedBrowseSubject'],
) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_SUBJECT_SEARCH, {
    selectedBrowseSubject,
  })

export const setBrowseTopicAction = (
  selectedBrowseTopic: LibraryBrowseState['selectedBrowseTopic'],
) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_TOPIC, {
    selectedBrowseTopic,
  })

export const setBrowseFocusAction = (
  selectedBrowseFocus: LibraryBrowseState['selectedBrowseFocus'],
) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_FOCUS, {
    selectedBrowseFocus,
  })

export const setBrowseSubFocusAction = (
  selectedBrowseSubFocus: LibraryBrowseState['selectedBrowseSubFocus'],
) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_SUBFOCUS, {
    selectedBrowseSubFocus,
  })

export const setBrowseCurriculumAction = (curriculum: BrowseCurriculum) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_CURRICULUM, { curriculum })

export const updateBrowseCurriculumAction = (
  filterType: keyof BrowseCurriculum,
  key: string,
  newValue: boolean,
) =>
  createAction(LibraryBrowseActionType.UPDATE_BROWSE_CURRICULUM, {
    filterType,
    key,
    newValue,
  })

export const toggleBrowseMediaTypeAction = (mediaTypeId: string) =>
  createAction(LibraryBrowseActionType.TOGGLE_BROWSE_MEDIA_TYPE, {
    mediaTypeId,
  })

export const toggleBrowseTocCollapseAction = (id: string) =>
  createAction(LibraryBrowseActionType.TOGGLE_BROWSE_TOC_COLLAPSE, { id })

export const resetBrowseTocCollapseAction = (subjectId: string) =>
  createAction(LibraryBrowseActionType.RESET_TOC_COLLAPSE, { subjectId })

export const setBrowseLastVisibleLocationAction = ({
  path,
  index,
}: LibraryBrowseState['lastVisibleLocation']) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_LAST_VISIBLE_LOCATION, {
    path,
    index,
  })

export const setBrowsePreviousResultsAction = ({
  data = [],
  count,
  nextPaginationToken,
  filters,
}: LibraryBrowseState['previousBrowseResults']) =>
  createAction(LibraryBrowseActionType.SET_BROWSE_PREVIOUS_RESULTS, {
    data,
    count,
    nextPaginationToken,
    filters,
  })

export const clearBrowseItemMediaTypesAction = () =>
  createAction(LibraryBrowseActionType.CLEAR_MEDIA_AND_ITEM_TYPES)
