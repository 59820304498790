import {
  LIBRARY_SUBJECT_ID_PATH,
  LIBRARY_SEARCH_PATH,
  LIBRARY_BROWSE_PATH,
  READER_LO_PATH,
  DASHBOARD_PATH,
  DASHBOARD_ARCHIVED_CLASSES_PATH,
  DASHBOARD_CLASS_PATH,
  DASHBOARD_CLASS_STUDENT_LOS_PATH,
  DASHBOARD_STUDENT_CLASS_PATH,
  DASHBOARD_STUDENT_LOS_PATH,
  COLLECTIONS_PATH,
  SINGLE_COLLECTION_PATH,
  SETTINGS_PATH,
  BROWSE_COLLECTIONS_PATH,
  LIBRARY_CURRICULUM_PATH,
} from '../constants'
import { matchPath } from 'react-router'

export const matchHome = (pathname: string) =>
  matchPath(pathname, {
    path: '/',
    exact: true,
  })

export const matchSettings = (pathname: string) =>
  matchPath(pathname, {
    path: SETTINGS_PATH,
    exact: false,
  })

export const matchCollections = (pathname: string) =>
  matchPath(pathname, {
    path: COLLECTIONS_PATH,
    exact: true,
  })

export const matchSingleCollection = (pathname: string) =>
  matchPath(pathname, {
    path: SINGLE_COLLECTION_PATH,
    exact: true,
  })

export const matchLibraryBrowseSubject = (pathname: string) =>
  matchPath(pathname, {
    path: LIBRARY_SUBJECT_ID_PATH,
    exact: false,
  })

export const matchSearchResults = (pathname: string) =>
  matchPath(pathname, {
    path: LIBRARY_SEARCH_PATH,
    exact: false,
  })

export const matchBrowseResults = (pathname: string) =>
  matchPath(pathname, {
    path: LIBRARY_BROWSE_PATH,
    exact: false,
  })
export const matchLibraryBrowseCurriculum = (pathname: string) =>
  matchPath(pathname, {
    path: LIBRARY_CURRICULUM_PATH,
    exact: false,
  })

export const matchReader = (pathname: string) =>
  matchPath(pathname, {
    path: READER_LO_PATH,
    exact: true,
    strict: true,
  })

export const matchDashboard = (pathname: string) =>
  matchPath(pathname, {
    path: DASHBOARD_PATH,
    exact: true,
  })

export const matchDashboardCourse = (pathname: string) =>
  matchPath(pathname, {
    path: DASHBOARD_CLASS_PATH,
    exact: true,
  })

export const matchDashboardCourseStudentLOs = (pathname: string) =>
  matchPath(pathname, {
    path: DASHBOARD_CLASS_STUDENT_LOS_PATH,
    exact: false,
  })

export const matchDashboardStudentLOs = (pathname: string) =>
  matchPath(pathname, {
    path: DASHBOARD_STUDENT_LOS_PATH,
    exact: false,
  })

export const matchDashboardStudentClass = (pathname: string) =>
  matchPath(pathname, {
    path: DASHBOARD_STUDENT_CLASS_PATH,
    exact: false,
  })

export const matchDashboardArchivedClasses = (pathname: string) =>
  matchPath(pathname, {
    path: DASHBOARD_ARCHIVED_CLASSES_PATH,
    exact: true,
  })

export const matchBrowseCollections = (pathname: string) =>
  matchPath(pathname, {
    path: BROWSE_COLLECTIONS_PATH,
    exact: false,
  })

export const splitPath = (path: string) => {
  const split = path.split('/').filter((x: string) => x !== '')
  return {
    basePath: split.slice(0, -1).join('/') || '',
    finalPath: split[split.length - 1] || '',
  }
}
