import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEqual, uniq, mapValues } from 'lodash'
import { syncApolloDataAction } from '../actions/appActions'
import { LoadingPage } from './LoadingPage'
import { deepDiff } from '../utils/deepDiff'
import { reactChildrenPropType } from '../utils/propTypes'
import { usePrevious } from '../hooks/usePrevious'
import { useActions } from '../hooks/useActions'
import { useUserCapability } from '../hooks/useCapability'
import {
  useGetUserQuery,
  useGetSubjectsQuery,
  useListGradesByProvincesQuery,
} from '../types/graphql'

const mapGradeCmsIdToName = (grades) =>
  grades.map((cmsId) => {
    return cmsId === '1' ? 'K' : String(+cmsId - 1)
  })

const getGPSForUser = () => {
  const { gpsSelf } = useUserCapability()
  const { data: userData, loading: userLoading } = useGetUserQuery({
    variables: { withOpsData: true },
  })
  const user = userData?.user
  const userProvinceId = (user?.provinces || [])[0]
  const userGrades = gpsSelf ? user?.grades || [] : []
  const userMarketingSubjects = user?.opsData?.marketingSubjects || []

  const { data: gradesByProvincesData, loading: gradesByProvincesLoading } =
    useListGradesByProvincesQuery()
  const userProvinces =
    gradesByProvincesData?.listGradesByProvinces?.provinces || []

  const userProvinceName =
    userProvinces.find((province) => {
      if (!province) return false
      return province.cmsId === userProvinceId
    })?.name || undefined

  const { data: subjectsData, loading: subjectsLoading } = useGetSubjectsQuery({
    variables: {
      provinceNames: userProvinceName ? [userProvinceName] : [],
      grades: mapGradeCmsIdToName(userGrades),
      topologyPathIds: [],
    },
  })

  const userSubjects = gpsSelf
    ? subjectsData?.getSubjects || []
    : (subjectsData?.getSubjects || []).filter((subject) =>
        userMarketingSubjects.includes(subject?.marketingSubject),
      )

  const provinces = []
  const grades = []
  const subjects = {}

  userSubjects.forEach((subject) => {
    if (subject?.cmsId) {
      const subjectCmsIds = subject?.cmsId?.split('|')
      provinces.push(subjectCmsIds[0])
      grades.push(subjectCmsIds[1])
      if (!subjects[subjectCmsIds[1]]) {
        subjects[subjectCmsIds[1]] = []
      }
      subjects[subjectCmsIds[1]].push(subjectCmsIds[2])
    }
  })

  const loading = userLoading || gradesByProvincesLoading || subjectsLoading

  return {
    provinces: uniq(provinces),
    grades: uniq(grades),
    subjects: mapValues(subjects, uniq),
    loading,
  }
}

export default function ApolloReduxConnector({ children }) {
  const { loading, grades, provinces, subjects } = getGPSForUser()

  const syncApolloData = useActions(syncApolloDataAction)
  const edwinInitialized = useSelector((state) => state.edwin?.initialized)
  const apolloProps = {
    provinces,
    grades,
    subjects,
    gpsIsLoading: loading,
  }
  const prevApolloProps = usePrevious(apolloProps)

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' && prevApolloProps) {
      console.log(
        '%cApolloReduxConnector Diff',
        `
         color: #fff;
         background-color: #1d4859;
         padding: 2px 4px;
         border-radius: 2px;
       `,
        deepDiff(apolloProps, prevApolloProps),
      )
    }

    if (!isEqual(apolloProps, prevApolloProps)) {
      syncApolloData(apolloProps)
    }
  }, [apolloProps])

  if (process.env.NODE_ENV !== 'production') {
    console.log(
      '%cApolloReduxConnector',
      `
      color: #fff;
      background-color: #1D593B;
      padding: 2px 4px;
      border-radius: 2px;
    `,
      apolloProps,
    )
  }

  // We need to delay rendering of the site until there is some user data initialized by apollo
  if (!edwinInitialized || loading) {
    return <LoadingPage />
  }

  return children
}
ApolloReduxConnector.propTypes = {
  children: reactChildrenPropType.isRequired,
}
