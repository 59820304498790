import { ApolloLink } from '@apollo/client'
import { authService } from '../services/authentication'

// adds authService accessToken to all query headers
export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      authorization: 'Bearer ' + authService.getAccessToken(),
    },
  }))
  return forward(operation)
})
