import { LibraryBrowseActionType } from '../actions/libraryBrowseActions'
import { difference, pickBy, uniq, xor } from 'lodash'
import { BrowseCurriculum, LibraryBrowseState } from './reducerTypes'

const updateFilterKey = (
  filterType: keyof BrowseCurriculum,
  key: string,
  newValue: boolean,
  filters: BrowseCurriculum,
) => {
  // Ported from LibrarySearchFilters
  const filterList = filters[filterType]
  const isActive = filterList?.includes(key) ?? false
  let newFilterList = filterList
  if (Array.isArray(key)) {
    if (filterType === 'subjects') {
      newFilterList = newValue
        ? uniq(filters.subjects.concat(key))
        : difference(filters.subjects, key)
    }
    if (filterType === 'strands') {
      newFilterList = newValue
        ? uniq(filters.strands.concat(key))
        : difference(filters.strands, key)
    }
    if (filterType === 'specificExpectations') {
      newFilterList = newValue
        ? uniq(filters.specificExpectations.concat(key))
        : difference(filters.specificExpectations, key)
    }
    if (filterType === 'overallExpectations') {
      newFilterList = newValue
        ? uniq(filters.overallExpectations.concat(key))
        : difference(filters.overallExpectations, key)
    }
  } else if (isActive && newValue === false && Array.isArray(newFilterList)) {
    // remove filter key
    newFilterList = newFilterList.filter((item) => item !== key)
  } else if (!isActive && newValue === true && Array.isArray(newFilterList)) {
    // add filter key
    newFilterList = [...newFilterList, key]
  }
  return newFilterList
}

export const defaultState: LibraryBrowseState = {
  searchTerm: '',
  updatedDate: new Date().toISOString(),
  // filter are only used on librarySearch reducer atm, but could be used on others in the future
  filters: {
    itemTypes: [],
    mediaTypeIds: [],
    strandIds: [],
    focusIds: [],
    subjectIds: [],
    topicIds: [],
    overallExpectationIds: [],
    specificExpectationIds: [],
  },
  displaySearchClearButton: false,
  lastVisibleLocation: {
    path: undefined,
    index: 0,
  },
  previousBrowseResults: {
    data: [],
  },
  selectedBrowseType: '',
  selectedBrowseSubject: { label: '', value: '' },
  selectedBrowseTopic: { label: '', value: '' },
  selectedBrowseFocus: { label: '', value: '' },
  selectedBrowseSubFocus: { label: '', value: '' },
  selectedBrowseCurriculum: {
    mediaTypes: [],
    strands: [],
    focuses: [],
    subFocuses: [],
    subjects: [],
    topics: [],
    overallExpectations: [],
    specificExpectations: [],
    itemTypes: [],
  },
  expandedTocItems: [],
}

type Action =
  | ({ type: LibraryBrowseActionType.UPDATE_BROWSE_SEARCH_TERM } & Pick<
      LibraryBrowseState,
      'searchTerm'
    >)
  | ({ type: LibraryBrowseActionType.SET_BROWSE_SUBJECT } & Pick<
      LibraryBrowseState,
      'selectedBrowseSubject'
    >)
  | ({ type: LibraryBrowseActionType.SET_BROWSE_SUBJECT_SEARCH } & Pick<
      LibraryBrowseState,
      'selectedBrowseSubject'
    >)
  | ({ type: LibraryBrowseActionType.SET_BROWSE_TOPIC } & Pick<
      LibraryBrowseState,
      'selectedBrowseTopic'
    >)
  | ({ type: LibraryBrowseActionType.SET_BROWSE_FOCUS } & Pick<
      LibraryBrowseState,
      'selectedBrowseFocus'
    >)
  | ({ type: LibraryBrowseActionType.SET_BROWSE_SUBFOCUS } & Pick<
      LibraryBrowseState,
      'selectedBrowseSubFocus'
    >)
  | {
      type: LibraryBrowseActionType.SET_BROWSE_CURRICULUM
      curriculum: BrowseCurriculum
    }
  | {
      type: LibraryBrowseActionType.UPDATE_BROWSE_CURRICULUM
      filterType: keyof BrowseCurriculum
      key: string
      newValue: boolean
    }
  | {
      type: LibraryBrowseActionType.TOGGLE_BROWSE_MEDIA_TYPE
      mediaTypeId: string
    }
  | { type: LibraryBrowseActionType.TOGGLE_BROWSE_TOC_COLLAPSE; id: string }
  | { type: LibraryBrowseActionType.RESET_TOC_COLLAPSE; subjectId: string }
  | { type: LibraryBrowseActionType.SET_BROWSE_LAST_VISIBLE_LOCATION }
  | ({
      type: LibraryBrowseActionType.SET_BROWSE_PREVIOUS_RESULTS
    } & LibraryBrowseState['previousBrowseResults'])
  | { type: LibraryBrowseActionType.CLEAR_MEDIA_AND_ITEM_TYPES }

export function libraryBrowseReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case LibraryBrowseActionType.UPDATE_BROWSE_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        updatedDate: new Date().toISOString(),
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_SUBJECT: {
      return {
        ...state,
        selectedBrowseType: 'subjects',
        selectedBrowseSubject: action.selectedBrowseSubject,
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_SUBJECT_SEARCH: {
      // set browse subject is used by browse page in cases where the subject
      // shouldnt be added to the search curriculum
      // this version is specifically to show browse results for a subject
      return {
        ...state,
        selectedBrowseType: 'subjects',
        selectedBrowseSubject: action.selectedBrowseSubject,
        selectedBrowseCurriculum: {
          ...defaultState.selectedBrowseCurriculum,
          subjects: [action.selectedBrowseSubject?.value],
        },
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_TOPIC: {
      return {
        ...state,
        selectedBrowseType: 'topics',
        selectedBrowseTopic: action.selectedBrowseTopic,
        selectedBrowseCurriculum: {
          ...defaultState.selectedBrowseCurriculum,
          topics: [action.selectedBrowseTopic?.value],
        },
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_FOCUS: {
      return {
        ...state,
        selectedBrowseType: 'focuses',
        selectedBrowseFocus: action.selectedBrowseFocus,
        selectedBrowseCurriculum: {
          ...defaultState.selectedBrowseCurriculum,
          focuses: [action.selectedBrowseFocus?.value],
        },
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_SUBFOCUS: {
      return {
        ...state,
        selectedBrowseType: 'sub-focuses',
        selectedBrowseSubFocus: action.selectedBrowseSubFocus,
        selectedBrowseCurriculum: {
          ...defaultState.selectedBrowseCurriculum,
          subFocuses: [action.selectedBrowseSubFocus?.value],
        },
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_CURRICULUM: {
      const { curriculum = {} } = action
      return {
        ...state,
        selectedBrowseCurriculum: {
          ...state.selectedBrowseCurriculum,
          ...curriculum,
        },
      }
    }

    case LibraryBrowseActionType.UPDATE_BROWSE_CURRICULUM: {
      const { filterType, key, newValue } = action
      const filterKeys = updateFilterKey(
        filterType,
        key,
        newValue,
        state.selectedBrowseCurriculum,
      )

      return {
        ...state,
        selectedBrowseType: 'curriculum',
        selectedBrowseCurriculum: Object.assign(
          {},
          state.selectedBrowseCurriculum,
          {
            [filterType]: filterKeys,
          },
        ),
      }
    }

    case LibraryBrowseActionType.TOGGLE_BROWSE_MEDIA_TYPE: {
      const { selectedBrowseCurriculum } = state
      const toggleCollection = action.mediaTypeId === 'collection'
      const mediaTypes = toggleCollection
        ? selectedBrowseCurriculum.mediaTypes
        : xor(selectedBrowseCurriculum.mediaTypes, [action.mediaTypeId])

      const itemTypes = []
      if (mediaTypes.length) {
        itemTypes.push('learningObject')
      }
      if (
        toggleCollection !==
        selectedBrowseCurriculum.itemTypes.includes('collection')
      ) {
        itemTypes.push('collection')
      }

      return {
        ...state,
        selectedBrowseCurriculum: {
          ...selectedBrowseCurriculum,
          mediaTypes,
          itemTypes,
        },
      }
    }

    case LibraryBrowseActionType.TOGGLE_BROWSE_TOC_COLLAPSE: {
      const { id } = action
      if (state.expandedTocItems.includes(id)) {
        return {
          ...state,
          expandedTocItems: state.expandedTocItems.filter(
            (item) => item !== id,
          ),
        }
      }
      const expandedTocItems = [...state.expandedTocItems]
      expandedTocItems.push(id)
      return {
        ...state,
        expandedTocItems,
      }
    }

    case LibraryBrowseActionType.RESET_TOC_COLLAPSE: {
      const { subjectId } = action
      // only reset if in new subject
      if (state.storedTocSubjectId !== subjectId) {
        return {
          ...state,
          storedTocSubjectId: subjectId,
          expandedTocItems: [],
        }
      }
      return state
    }

    case LibraryBrowseActionType.SET_BROWSE_LAST_VISIBLE_LOCATION: {
      const { type, ...rest } = action
      const payload = pickBy(rest, (val) => val !== undefined)
      return {
        ...state,
        lastVisibleLocation: {
          ...state.lastVisibleLocation,
          ...payload,
        },
      }
    }

    case LibraryBrowseActionType.SET_BROWSE_PREVIOUS_RESULTS: {
      const { data, count, nextPaginationToken, metadata, filters } = action
      return {
        ...state,
        previousBrowseResults: {
          data,
          count,
          nextPaginationToken,
          metadata,
          filters,
        },
      }
    }

    case LibraryBrowseActionType.CLEAR_MEDIA_AND_ITEM_TYPES: {
      const { selectedBrowseCurriculum } = state
      return {
        ...state,
        selectedBrowseCurriculum: {
          ...selectedBrowseCurriculum,
          mediaTypes: [],
          itemTypes: [],
        },
      }
    }

    default: {
      return state
    }
  }
}
