import { createAction } from './index'
import { CurriculumStrand } from '../types/graphql'
import {
  CurriculumSubjectNameWithGrade,
  LibrarySearchState,
} from '../reducers/reducerTypes'

export enum LibrarySearchActionType {
  UPDATE_LIBRARY_SEARCH_TERM = 'EDWIN/LIBRARY/SEARCH/SEARCH_TERM/UPDATE',
  TOGGLE_MEDIA_TYPE = 'EDWIN/LIBRARY/TOGGLE_MEDIA_TYPE',
  TOGGLE_LIBRARY_SUBJECT = 'EDWIN/LIBRARY/TOGGLE_SUBJECT',
  TOGGLE_LIBRARY_STRAND = 'EDWIN/LIBRARY/TOGGLE_STRAND',
  CLEAR_LIBRARY_SEARCH_FILTERS = 'EDWIN/LIBRARY/SEARCH/FILTERS/CLEAR',
  SHOW_SEARCH_CLEAR_BUTTON = 'EDWIN/LIBRARY/SEARCH/KEYPRESS',
  SET_SEARCH_OUTSIDE_USER_SUBJECTS = 'EDWIN/LIBRARY/SEARCH/OUTSIDE_SUBJECTS',
  SET_LIBRARY_SEARCH_FILTERS = 'EDWIN/LIBRARY/SEARCH/FILTERS/SET',
  SET_SEARCH_LAST_VISIBLE_LOCATION = 'EDWIN/LIBRARY/SEARCH/LAST_VISIBLE_LOCATION/SET',
  SET_SEARCH_PREVIOUS_RESULTS = 'EDWIN/LIBRARY/SEARCH/PREVIOUS_RESULTS/SET',
}

export const updateSearchTermAction = (
  searchTerm: LibrarySearchState['searchTerm'],
) =>
  createAction(LibrarySearchActionType.UPDATE_LIBRARY_SEARCH_TERM, {
    searchTerm,
  })

export const toggleMediaTypeAction = (mediaTypeId: string) =>
  createAction(LibrarySearchActionType.TOGGLE_MEDIA_TYPE, { mediaTypeId })

export const toggleLibrarySubjectAction = (
  subject: CurriculumSubjectNameWithGrade,
) => createAction(LibrarySearchActionType.TOGGLE_LIBRARY_SUBJECT, { subject })

export const toggleLibraryStrandAction = ({
  subject,
  strand,
}: {
  subject: CurriculumSubjectNameWithGrade
  strand: CurriculumStrand
}) =>
  createAction(LibrarySearchActionType.TOGGLE_LIBRARY_STRAND, {
    subject,
    strand,
  })

// clears all search filters and filter terms
export const clearSearchFiltersAction = (exceptions = {}) =>
  createAction(LibrarySearchActionType.CLEAR_LIBRARY_SEARCH_FILTERS, {
    exceptions,
  })

export const showSearchClearButtonAction = (
  value: LibrarySearchState['displaySearchClearButton'],
) => createAction(LibrarySearchActionType.SHOW_SEARCH_CLEAR_BUTTON, { value })

export const setSearchOutsideUserSubjectsAction = (
  value: LibrarySearchState['searchOutsideUserSubjects'],
) =>
  createAction(LibrarySearchActionType.SET_SEARCH_OUTSIDE_USER_SUBJECTS, {
    value,
  })

export const setSearchFiltersAction = (
  filters: LibrarySearchState['filters'],
) =>
  createAction(LibrarySearchActionType.SET_LIBRARY_SEARCH_FILTERS, { filters })

export const setSearchLastVisibleLocationAction = ({
  path,
  index,
}: LibrarySearchState['lastVisibleLocation']) =>
  createAction(LibrarySearchActionType.SET_SEARCH_LAST_VISIBLE_LOCATION, {
    path,
    index,
  })

export const setSearchPreviousResultsAction = ({
  data = [],
  count,
  nextPaginationToken,
  metadata,
  filters,
}: LibrarySearchState['previousSearchResults']) =>
  createAction(LibrarySearchActionType.SET_SEARCH_PREVIOUS_RESULTS, {
    data,
    count,
    nextPaginationToken,
    metadata,
    filters,
  })
