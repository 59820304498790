import React, { FC, ReactNode } from 'react'
import { openExternalUrlAction } from '../actions/shellActions'
import Analytics from '../services/googleAnalytics'
import { negotiateDocumentServiceSession } from '../utils/sso'
import { useActions } from '../hooks/useActions'
import { Button, A } from '@edwin-edu/ui'

type ExternalLinkProps = {
  dispatch?: () => void
  children: ReactNode
  onClick?: (e: any) => void
  button?: boolean
  href: string
  className: string
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  button,
  dispatch,
  children,
  onClick,
  href,
  ...restProps
}) => {
  const openExternalUrl = useActions(openExternalUrlAction)
  const openExternalLink = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (onClick) {
      onClick(e)
    }
    Analytics.trackEvent({
      eventName: 'clickedExternalLink',
      category: 'ExternalLink',
      label: href,
    })
    if (href.match(/^https:\/\/documents(?:.*?\.)?edwin\.app\/files\/\S*$/)) {
      // attempt to pre-negotiation auth session with document service
      const docSessionResult = await negotiateDocumentServiceSession()
      console.log('document service session established:', docSessionResult)
    }
    openExternalUrl(href)
  }

  if (button) {
    return (
      <Button onClick={openExternalLink} {...restProps}>
        {children}
      </Button>
    )
  } else {
    return (
      <A href={href} onClick={openExternalLink} {...restProps}>
        {children}
      </A>
    )
  }
}
