// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e40bdbfdf149fcb0eb08,.be63ebc5108992323c42{position:absolute;width:auto;border-radius:50px;background-color:#017acc;color:#fff;font-style:italic;font-size:14px;white-space:nowrap;padding:5px 8px;top:-32px;left:5px;transform:translateX(-50%);z-index:999;visibility:hidden;opacity:0;transition:opacity .5s ease-in}.be63ebc5108992323c42{visibility:visible;opacity:1}", "",{"version":3,"sources":["webpack://./src/core/app/components/SimpleToolTip.module.scss"],"names":[],"mappings":"AAEA,4CAEE,iBAAA,CACA,UAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,SAAA,CACA,QAAA,CACA,0BAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CACA,8BAAA,CAGF,sBACE,kBAAA,CACA,SAAA","sourcesContent":["@use '~@edwin-edu/ui/vars' as *;\n\n.tooltip,\n.tooltipOpen {\n  position: absolute;\n  width: auto;\n  border-radius: 50px;\n  background-color: $color-base-300;\n  color: white;\n  font-style: italic;\n  font-size: 14px;\n  white-space: nowrap;\n  padding: 5px 8px;\n  top: -32px;\n  left: 5px;\n  transform: translateX(-50%);\n  z-index: 999;\n  visibility: hidden;\n  opacity: 0;\n  transition: opacity 0.5s ease-in;\n}\n\n.tooltipOpen {\n  visibility: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "e40bdbfdf149fcb0eb08",
	"tooltipOpen": "be63ebc5108992323c42"
};
export default ___CSS_LOADER_EXPORT___;
