import React, { Component } from 'react'
import { reactChildrenPropType } from '../../utils/propTypes'
import Analytics from '../../services/googleAnalytics'
import SiteErrorPage from './SiteErrorPage'
import AppErrorPage from './AppErrorPage'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class SiteErrorBoundary extends Component {
  static propTypes = {
    children: reactChildrenPropType.isRequired,
    appError: PropTypes.bool.isRequired,
  }

  state = { hasError: false }

  componentDidCatch(error, info) {
    /** There are no Hook equivalents to the uncommon getSnapshotBeforeUpdate, getDerivedStateFromError
    and componentDidCatch lifecycles yet, but we plan to add them soon. https://reactjs.org/docs/hooks-faq.html **/

    /**
     * todo: since LocaleProvider must be inside ApolloProvider, and we want this outside of
     * ApolloProvider to catch Apollo Errors, use getBrowserLanguage() to get the default
     * Browser Language for global errors. Or possibly use the ApolloReduxConnector to add
     * the custom chosen language to redux.
     *
     * example: const locale = getBrowserLanguage()
     */

    this.setState({ hasError: true })

    Analytics.trackException({
      description: `siteError.\n\n error: ${error}\n\n info: ${info.componentStack}`,
    })

    window.logger.error('SiteError:')
    window.logger.error(error, info.componentStack)

    if (error.message && error.message.startsWith('Loading chunk')) {
      window.logger.error('ServiceWorker upgrade error')
      window.location = '/'
    }
  }

  render() {
    const { hasError } = this.state
    const { appError, children } = this.props
    if (hasError) {
      return <SiteErrorPage />
    } else if (appError) {
      return <AppErrorPage />
    }
    return children
  }
}

const mapStateToProps = (state) => ({
  appError: state.edwin.appError,
})

export default connect(mapStateToProps, null)(SiteErrorBoundary)
