import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { tail } from 'lodash'
import {
  ContentMessageDataMap,
  ContentMessages,
  ReaderMessageDataMap,
  ReaderMessages,
} from '@edwin-edu/edwin-types'
// import styled from 'styled-components'

// types to export that shouldn't need syncing (move to another file)
export type Handler<D> = (data: D) => void

export type ContentPostMessageData = {
  messageType: ContentMessages
  messageData: ContentMessageDataMap[ContentMessages]
}

export type ReaderPostMessageData = {
  messageType: ReaderMessages
  messageData: ReaderMessageDataMap[ReaderMessages]
}

export type ContentHandlerRegister = <
  K extends keyof ContentMessageDataMap,
  H extends Handler<ContentMessageDataMap[K]>,
>(
  messageType: K,
  handler: H,
) => void

export type ContentHandler<K extends ContentMessages> = Handler<
  ContentMessageDataMap[K]
>

// const t: Handler<K, H> = (messageType, handler) => []

// examples:
//
// function testMapHandler<
//   K extends ContentMessages,
//   H extends Handler<ContentMessageDataMap[K]>,
//   >(messageType: K, handler: H) {}
// testMapHandler(ContentMessages.Loaded, () => {})
// testMapHandler(ContentMessages.Clicked, ({ e }) => {})
// testMapHandler(ContentMessages.Loaded, ({ e }) => {}) // TS error
// testMapHandler(ContentMessages.Clicked, ({ k }) => {}) // TS error

// type ContentDataFunc<D> = (data: D) => void
//
// function callit<
//   K extends keyof ContentMessageDataMap,
//   H extends ContentDataFunc<ContentMessageDataMap[K]>,
//   >(message: K, handler: H) {}

const suppressLogs = true

const devLog = <T, H>(...args: T[] | H[]) => {
  if (!suppressLogs) {
    console.log(...args)
  }
}

const ReaderPostMessage: React.FC = () => {
  const { handlers, setIFrameWindow } = useContext(ReaderMessageContext)

  const [messageQueue, setMessageQueue] = useState<MessageEvent[]>([])

  useEffect(() => {
    if (messageQueue.length) {
      messageHandler(messageQueue[0])
      setMessageQueue((prevState) => tail(prevState))
    }
  }, [messageQueue.map((event: MessageEvent) => event.data)])

  const parseIncomingMessage = (
    origin: string,
    message: ContentPostMessageData,
    source: MessageEventSource | null,
  ) => {
    const { messageType, messageData } = message

    if (
      !EDWIN_ALLOWED_ORIGINS.includes(origin) &&
      !/https:\/\/content\..{6}\.env\.edwin\.app/.test(origin)
    ) {
      // console.error('PostMessage: Origin not allowed', origin)
      return
    }

    if (!message.messageType) {
      // devLog(`no messageType received "${messageType}"`)
      return
    }

    if (messageType === ContentMessages.Loaded) {
      setIFrameWindow(source)
    }

    const messageHandlers = handlers[messageType]
    if (!messageHandlers) {
      devLog(`no handlers for Content messageType "${messageType}"`)
      return
    }
    // @ts-ignore I'm not sure why handler(messageData) throws a ts error. The data types match up.
    messageHandlers.forEach((handler) => handler(messageData))
  }

  const messageHandler = (event: MessageEvent) => {
    devLog('Message from content')
    // devLog('Origin:', event.origin)
    devLog('Data:', event.data)
    parseIncomingMessage(event.origin, event.data, event.source)
  }

  const addMessageToQueue = useCallback((event: MessageEvent) => {
    if (
      // filter out messages from google auth iframe & react devtools
      event.origin.includes('google') // || event.source === 'react-devtools-bridge'
    ) {
      return
    }
    setMessageQueue((prevState) => [...prevState, event])
  }, [])

  useEffect(() => {
    window.addEventListener('message', addMessageToQueue)

    return () => {
      window.removeEventListener('message', addMessageToQueue)
    }
  }, [])
  return null
}

export default ReaderPostMessage

type MessageHandlerDict = {
  [K in ContentMessages]?: Handler<ContentMessageDataMap[K]>[]
}

interface ReaderMessageContextType {
  iFrameWindow: MessageEventSource | null
  setIFrameWindow:
    | Dispatch<SetStateAction<MessageEventSource | null>>
    | (() => void)
  handlers: MessageHandlerDict
  register: ContentHandlerRegister
  unregister: ContentHandlerRegister
}

export const ReaderMessageContext =
  React.createContext<ReaderMessageContextType>({
    iFrameWindow: null,
    setIFrameWindow: () => {},
    handlers: {},
    register: () => {},
    unregister: () => {},
  })

export const ReaderMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [iFrameWindow, setIFrameWindow] = useState<MessageEventSource | null>(
    null,
  )
  const [handlers, setHandlers] = useState<MessageHandlerDict>({})
  const register: ContentHandlerRegister = (messageType, handler) => {
    // todo: check if exists already?
    setHandlers((prevState) => {
      const newHandlers = Array.from(prevState[messageType] ?? [])
      newHandlers.push(handler)
      // devLog('registered handler ' + messageType, newHandlers)
      return {
        ...prevState,
        [messageType]: newHandlers,
      }
    })
  }

  const unregister: ContentHandlerRegister = (messageType, handler) => {
    // todo: make sure handler function stays the same? useCallback?
    setHandlers((prevState) => {
      // devLog('unregistered handler ' + messageType, handlers)
      return {
        ...prevState,
        [messageType]: (prevState[messageType] ?? []).filter(
          (savedHandler) => savedHandler !== handler,
        ),
      }
    })
  }

  return (
    <ReaderMessageContext.Provider
      value={{
        iFrameWindow,
        setIFrameWindow,
        handlers,
        register,
        unregister,
      }}
    >
      {children}
    </ReaderMessageContext.Provider>
  )
}

// Caveat with useOnContentMessage is that your handler function will not get updated by react re-renders
// so any local state or functions that are referenced inside the handler
// will have the values that they were at the time this hook is called
// not what they are at the time the handler is called
export function useOnContentMessage<
  K extends ContentMessages,
  H extends Handler<ContentMessageDataMap[K]>,
>(messageType: K, handler: H, deps?: React.DependencyList | undefined) {
  const { register, unregister, iFrameWindow } =
    useContext(ReaderMessageContext)

  const effectDeps = deps ? [iFrameWindow, ...deps] : [iFrameWindow]

  useEffect(() => {
    devLog(`📔reader ✅registering handler for '${messageType}'`)
    if (register) {
      register<K, H>(messageType, handler)
    }

    return () => {
      if (unregister) {
        devLog(`📔reader 🗑️unregistering handler for '${messageType}'`)
        unregister<K, H>(messageType, handler)
      }
    }
  }, effectDeps)
}

export function useSendReaderMessage() {
  const { iFrameWindow } = useContext(ReaderMessageContext)

  // noinspection UnnecessaryLocalVariableJS
  const postMessageToIFrame = useCallback(
    <K extends ReaderMessages>(
      messageType: K,
      messageData?: ReaderMessageDataMap[K],
    ) => {
      if (iFrameWindow) {
        // @ts-ignore ts doesn't like the second param because its on a MessageEventSource, but it's valid
        iFrameWindow.postMessage({ messageType, messageData }, '*')
      } else {
        console.error('Postmessage: No iframe window', messageType, messageData)
      }
    },
    [iFrameWindow],
  )

  // useSendReaderMessage tests:
  // postMessageToIFrame(ReaderMessages.Test, null) // OK
  // postMessageToIFrame(ReaderMessages.ToggleTeacherContent, {
  //   teacherId: 'none',
  // }) // TS error

  return postMessageToIFrame
}

// tests to show Typescript throwing errors when wrong data
/*
function useOnContentMessageTest() {
  const succeeds = () => {
    devLog('iframe loaded')
  }
  useOnContentMessage(ContentMessages.Loaded, succeeds)

  const tsError = ({ someData }: { someData: string }) => {
    devLog('iframe loaded', someData)
  }
  useOnContentMessage(ContentMessages.Loaded, tsError) // ts error here

  // could force type before like
  const typed: ContentHandler<ContentMessages.Loaded> = () => {}
  useOnContentMessage(ContentMessages.Loaded, typed) // succeeds

  // wrong type
  const wrongType: ContentHandler<ContentMessages.Clicked> = ({
    someData, // fails here because wrong argument
  }) => {}
  useOnContentMessage(ContentMessages.Loaded, wrongType) // fails here also because wrong type-to-data mismatch
}
 */
