import { cloneDeep, pickBy, difference, xor } from 'lodash'
import { LibrarySearchActionType } from '../actions/librarySearchActions'
import {
  CurriculumSubjectNameWithGrade,
  LibrarySearchState,
} from './reducerTypes'
import { CurriculumStrand } from '../types/graphql'

const defaultFilters: LibrarySearchState['filters'] = {
  itemTypes: [],
  mediaTypeIds: [],
  strandIds: [],
  focusIds: [],
  subjectIds: [],
  topicIds: [],
  overallExpectationIds: [],
  specificExpectationIds: [],
}

const defaultState: LibrarySearchState = {
  searchTerm: '',
  updatedDate: new Date().toISOString(),
  filters: {
    ...defaultFilters,
  },
  displaySearchClearButton: false,
  lastVisibleLocation: {
    index: 0,
  },
  previousSearchResults: {
    data: [],
  },
  searchOutsideUserSubjects: false,
}

type Action =
  | {
      type: LibrarySearchActionType.SET_SEARCH_OUTSIDE_USER_SUBJECTS
      value: LibrarySearchState['searchOutsideUserSubjects']
    }
  | {
      type: LibrarySearchActionType.SHOW_SEARCH_CLEAR_BUTTON
      value: LibrarySearchState['displaySearchClearButton']
    }
  | {
      type: LibrarySearchActionType.UPDATE_LIBRARY_SEARCH_TERM
      searchTerm: LibrarySearchState['searchTerm']
    }
  | {
      type: LibrarySearchActionType.CLEAR_LIBRARY_SEARCH_FILTERS
      exceptions: Pick<LibrarySearchState, 'searchTerm'>
    }
  | { type: LibrarySearchActionType.TOGGLE_MEDIA_TYPE; mediaTypeId: string }
  | {
      type: LibrarySearchActionType.TOGGLE_LIBRARY_SUBJECT
      subject: CurriculumSubjectNameWithGrade
    }
  | {
      type: LibrarySearchActionType.TOGGLE_LIBRARY_STRAND
      subject: CurriculumSubjectNameWithGrade
      strand: CurriculumStrand
    }
  | {
      type: LibrarySearchActionType.SET_LIBRARY_SEARCH_FILTERS
      filters: LibrarySearchState['filters']
    }
  | { type: LibrarySearchActionType.SET_SEARCH_LAST_VISIBLE_LOCATION }
  | ({
      type: LibrarySearchActionType.SET_SEARCH_PREVIOUS_RESULTS
    } & LibrarySearchState['previousSearchResults'])

export function librarySearchReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case LibrarySearchActionType.SET_SEARCH_OUTSIDE_USER_SUBJECTS: {
      return {
        ...state,
        searchOutsideUserSubjects: action.value,
        lastVisibleLocation: {
          ...state.lastVisibleLocation,
          index: 0,
        },
      }
    }
    case LibrarySearchActionType.SHOW_SEARCH_CLEAR_BUTTON: {
      return {
        ...state,
        displaySearchClearButton: action.value,
      }
    }
    case LibrarySearchActionType.UPDATE_LIBRARY_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        updatedDate: new Date().toISOString(),
      }
    }

    case LibrarySearchActionType.CLEAR_LIBRARY_SEARCH_FILTERS: {
      const { searchTerm } = action.exceptions
      return {
        ...state,
        filters: cloneDeep(defaultFilters),
        searchTerm: searchTerm ? state.searchTerm : '',
      }
    }

    case LibrarySearchActionType.TOGGLE_MEDIA_TYPE: {
      const { filters } = state
      const toggleCollection = action.mediaTypeId === 'collection'
      const mediaTypeIds = toggleCollection
        ? filters.mediaTypeIds
        : xor(filters.mediaTypeIds, [action.mediaTypeId])

      const itemTypes = []
      if (mediaTypeIds?.length) {
        itemTypes.push('learningObject')
      }
      if (toggleCollection !== filters.itemTypes?.includes('collection')) {
        itemTypes.push('collection')
      }

      return {
        ...state,
        filters: {
          ...filters,
          mediaTypeIds,
          itemTypes,
        },
      }
    }

    case LibrarySearchActionType.TOGGLE_LIBRARY_SUBJECT: {
      const { subject } = action
      const { filters } = state
      const subjectStrandIds = subject.strands?.map((s) => s?.id)

      return {
        ...state,
        filters: {
          ...filters,
          subjectIds: xor(filters.subjectIds, [subject.id]),
          strandIds: filters.strandIds?.filter(
            (s) => !subjectStrandIds?.includes(s || undefined),
          ),
        },
      }
    }

    // todo: this could be made more generic & combined with code in LibraryCurriculumPage.updateFilterKey()
    case LibrarySearchActionType.TOGGLE_LIBRARY_STRAND: {
      const { subject, strand } = action
      const { filters } = state
      // if strand is set
      if (filters.strandIds?.includes(strand.id)) {
        // if subject is set (this should never happen...)
        // if subject is not set: unset strand
        return {
          ...state,
          filters: {
            ...filters,
            strandIds: filters.strandIds.filter((s) => s !== strand.id),
          },
        }
        // else strand is not set
      } else {
        // if subject is set: unset subject, set all subject strands except this one
        const otherStrandIds = (subject.strands ?? [])
          .map((strand) => strand?.id)
          .filter((s) => s !== strand.id) as string[]
        if (filters.subjectIds?.includes(subject.id)) {
          return {
            ...state,
            filters: {
              ...filters,
              subjectIds: filters.subjectIds.filter((s) => s !== subject.id),
              strandIds: filters.strandIds?.concat(otherStrandIds),
            },
          }
          // else if subject is not set:
        } else {
          // if all other strands in subject are set: unset all those strands & set subject
          if (
            difference(otherStrandIds, filters.strandIds ?? []).length === 0
          ) {
            return {
              ...state,
              filters: {
                ...filters,
                subjectIds: filters.subjectIds?.concat([subject.id]),
                strandIds: filters.strandIds?.filter(
                  (s) => s && !otherStrandIds.includes(s),
                ),
              },
            }

            // else if NOT all other strands...: set this strand
          } else {
            return {
              ...state,
              filters: {
                ...filters,
                strandIds: filters.strandIds?.concat([strand.id]),
              },
            }
          }
        }
      }
    }

    // only used to copy filters from library-browse to search-results when submitting search on browse page
    case LibrarySearchActionType.SET_LIBRARY_SEARCH_FILTERS: {
      const { filters } = action
      return {
        ...state,
        filters: {
          ...cloneDeep(defaultFilters),
          ...filters,
        },
      }
    }

    case LibrarySearchActionType.SET_SEARCH_LAST_VISIBLE_LOCATION: {
      const { type, ...rest } = action
      const payload = pickBy(rest, (val) => val !== undefined)
      return {
        ...state,
        lastVisibleLocation: {
          ...state.lastVisibleLocation,
          ...payload,
        },
      }
    }

    case LibrarySearchActionType.SET_SEARCH_PREVIOUS_RESULTS: {
      const { data, count, nextPaginationToken, metadata, filters } = action
      return {
        ...state,
        previousSearchResults: {
          data,
          count,
          nextPaginationToken,
          metadata,
          filters,
        },
      }
    }

    default:
      return state
  }
}
