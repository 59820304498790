import { NotesPanelActionType } from '../actions/notesPanelActions'
import { NotesPanelState } from './reducerTypes'

const defaultState: NotesPanelState = {
  isCollapsed: true,
}

type Action = { type: NotesPanelActionType.RESIZE_NOTES_PANEL }

export function notesPanelReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case NotesPanelActionType.RESIZE_NOTES_PANEL: {
      return {
        ...state,
        isCollapsed: !state.isCollapsed,
      }
    }

    default: {
      return state
    }
  }
}
