import { all, call, fork, put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import {
  edwinInit,
  goOfflineAction,
  goOnlineAction,
} from '../actions/appActions'
import { initFirebaseAuth } from './authFirebase'
import { serviceWorkerSaga } from './serviceWorker'
import { AuthActionType } from '../actions/auth'

/******************************************************************************/
/** ****************************** WATCHERS ***********************************/
/******************************************************************************/

function* startupFlow() {
  yield put(edwinInit())
}

// Run startupFlow on first session start
function* watchStartupFlow() {
  yield take(AuthActionType.SESSION_STARTED)
  yield call(startupFlow)
}

function* watchGoOfflineFlow() {
  const goOfflineChannel = eventChannel((emit) => {
    const handleGoOffline = (event: Event) => emit(event)
    window.addEventListener('offline', handleGoOffline)
    return () => {
      window.removeEventListener('offline', handleGoOffline)
    }
  })

  while (true) {
    yield take(goOfflineChannel)
    yield put(goOfflineAction())
  }
}

function* watchGoOnlineFlow() {
  const goOnlineChannel = eventChannel((emit) => {
    const handleGoOnline = (event: Event) => emit(event)
    window.addEventListener('online', handleGoOnline)
    return () => {
      window.removeEventListener('online', handleGoOnline)
    }
  })

  while (true) {
    yield take(goOnlineChannel)
    yield put(goOnlineAction())
  }
}

/** *************************** Root Saga ************************************/
export default function* root() {
  yield all([
    fork(watchGoOfflineFlow),
    fork(watchGoOnlineFlow),
    fork(serviceWorkerSaga),
    call(watchStartupFlow),
    fork(initFirebaseAuth),
  ])
}

export function* popoutSaga() {
  yield all([fork(initFirebaseAuth, true)])
}
