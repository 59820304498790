// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d6fcee4e7be325b5e386{color:#017acc;font-size:30px;font-weight:600;letter-spacing:-0.5px}.e53efd3351c6c947ab81{color:#2a2a2a;font-size:36px;font-weight:400;letter-spacing:-0.9px;margin-bottom:10px}.a5b96f79c3cef902cdc4{color:#2a2a2a;font-size:24px;font-weight:400;margin-bottom:25px}", "",{"version":3,"sources":["webpack://./src/core/app/containers/error/SharedComponents.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CAEF,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CACA,kBAAA,CAEF,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":["@use '~@edwin-edu/ui/vars' as *;\n\n.errorType { \n  color: #017acc;\n  font-size: 30px;\n  font-weight: 600;\n  letter-spacing: -0.5px;\n }\n.errorMessage { \n  color: #2a2a2a;\n  font-size: 36px;\n  font-weight: 400;\n  letter-spacing: -0.9px;\n  margin-bottom: 10px;\n }\n.errorActionMessage { \n  color: #2a2a2a;\n  font-size: 24px;\n  font-weight: 400;\n  margin-bottom: 25px;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorType": "d6fcee4e7be325b5e386",
	"errorMessage": "e53efd3351c6c947ab81",
	"errorActionMessage": "a5b96f79c3cef902cdc4"
};
export default ___CSS_LOADER_EXPORT___;
