import { createAction } from './index'
import { ReaderControlsState } from '../reducers/reducerTypes'

export enum ReaderControlsActionType {
  TOGGLE_CITATIONS_DROPDOWN = 'EDWIN/READER/CONTROLS/CITATIONS_DROPDOWN/TOGGLE',
  TOGGLE_COLLECTIONS_DROPDOWN = 'EDWIN/READER/CONTROLS/COLLECTIONS_DROPDOWN/TOGGLE',
  HIDE_ALL_DROPDOWNS = 'EDWIN/READER/CONTROLS/ALL_DROPDOWNS/HIDE',
  TOGGLE_SELF_ASSESSMENT_PANEL = 'EDWIN/READER/CONTROLS/SELF_ASSESSMENT_PANEL/TOGGLE',
  OPEN_SELF_ASSESSMENT_PANEL = 'EDWIN/READER/CONTROLS/SELF_ASSESSMENT_PANEL/OPEN',
  TOGGLE_NOTES_PANEL = 'EDWIN/READER/CONTROLS/NOTES_PANEL/TOGGLE',
  OPEN_NOTES_PANEL = 'EDWIN/READER/CONTROLS/NOTES_PANEL/OPEN',
  SAVE_MATHTOOL_TO_GOOGLE = 'EDWIN/READER/CONTROLS/NOTES_PANEL/SAVE_MATHTOOL_TO_GOOGLE',
  TOGGLE_RELATED_LEARNING_OBJ_PANEL = 'EDWIN/READER/CONTROLS/RELATED_LEARNING_OBJ_PANEL/TOGGLE',
  OPEN_RELATED_LEARNING_OBJ_PANEL = 'EDWIN/READER/CONTROLS/RELATED_LEARNING_OBJ_PANEL/OPEN',
  TOGGLE_EDUCATOR_TOOLS_PANEL = 'EDWIN/READER/CONTROLS/EDUCATOR_TOOLS_PANEL/TOGGLE',
  OPEN_EDUCATOR_TOOLS_PANEL = 'EDWIN/READER/CONTROLS/EDUCATOR_TOOLS_PANEL/OPEN',
  TOGGLE_SEARCH_PANEL = 'EDWIN/READER/CONTROLS/SEARCH_PANEL/TOGGLE',
  OPEN_SEARCH_PANEL = 'EDWIN/READER/CONTROLS/SEARCH_PANEL/OPEN',
  TOGGLE_FRACTION_STRIPS_PANEL = 'EDWIN/READER/CONTROLS/FRACTION_STRIPS/TOGGLE',
  OPEN_FRACTION_STRIPS_PANEL = 'EDWIN/READER/CONTROLS/FRACTION_STRIPS/OPEN',
  TOGGLE_PATTERN_BLOCKS_PANEL = 'EDWIN/READER/CONTROLS/PATTERN_BLOCKS/TOGGLE',
  OPEN_PATTERN_BLOCKS_PANEL = 'EDWIN/READER/CONTROLS/PATTERN_BLOCKS/OPEN',
  TOGGLE_ALGEBRA_TILES_PANEL = 'EDWIN/READER/CONTROLS/ALGEBRA_TILES/TOGGLE',
  OPEN_ALGEBRA_TILES_PANEL = 'EDWIN/READER/CONTROLS/ALGEBRA_TILES/OPEN',
  TOGGLE_SPINNER_PANEL = 'EDWIN/READER/CONTROLS/SPINNER/TOGGLE',
  OPEN_SPINNER_PANEL = 'EDWIN/READER/CONTROLS/SPINNER/OPEN',
  TOGGLE_INTEGER_CHIPS_PANEL = 'EDWIN/READER/CONTROLS/INTEGER_CHIPS/TOGGLE',
  OPEN_INTEGER_CHIPS_PANEL = 'EDWIN/READER/CONTROLS/INTEGER_CHIPS/OPEN',
  TOGGLE_BASE_TEN_BLOCKS_PANEL = 'EDWIN/READER/CONTROLS/BASE_TEN_BLOCKS/TOGGLE',
  OPEN_BASE_TEN_BLOCKS_PANEL = 'EDWIN/READER/CONTROLS/BASE_TEN_BLOCKS/OPEN',
  TOGGLE_BAR_MODEL_PANEL = 'EDWIN/READER/CONTROLS/TOGGLE_BAR_MODEL_PANEL/TOGGLE',
  OPEN_BAR_MODEL_PANEL = 'EDWIN/READER/CONTROLS/TOGGLE_BAR_MODEL_PANEL/OPEN',
  HIDE_ALL_PANELS = 'EDWIN/READER/CONTROLS/ALL_PANELS/HIDE',
  TOGGLE_COLLECTION_INPUT = 'EDWIN/READER/COLLECTIONS/INPUT/TOGGLE',
  TOGGLE_CITATION_LINK_COPIED = 'EDWIN/READER/CITATION/TOGGLE',
  HIDE_ALL_MODALS = 'EDWIN/READER/CONTROLS/ALL_MODALS/HIDE',
  TOGGLE_INFO_MODAL = 'EDWIN/READER/CONTROLS/INFO_MODAL/TOGGLE',
  TOGGLE_PRINT_DROPDOWN = 'EDWIN/READER/CONTROLS/PRINT_DROPDOWN/TOGGLE',
  TOGGLE_MATH_TOOLS_PANEL = 'EDWIN/READER/CONTROLS/MATH_TOOLS_MENU/TOGGLE',
  OPEN_MATH_TOOLS_PANEL = 'EDWIN/READER/CONTROLS/MATH_TOOLS_MENU/OPEN',
  CLOSE_MATH_TOOLS_PANEL = 'EDWIN/READER/CONTROLS/MATH_TOOLS_MENU/CLOSE',
  OPEN_DESMOS_PANEL = 'EDWIN/READER/DESMOS_PANELS/OPEN',
  CLOSE_DESMOS_PANEL = 'EDWIN/READER/DESMOS_PANELS/CLOSE',
  TOGGLE_WHITEBOARD_PANEL = 'EDWIN/READER/WHITEBOARD_PANEL/TOGGLE',
}

export const toggleCitationsDropDownAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_CITATIONS_DROPDOWN)

export const toggleCollectionsDropDownAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_COLLECTIONS_DROPDOWN)

export const hideAllDropdownsAction = () =>
  createAction(ReaderControlsActionType.HIDE_ALL_DROPDOWNS)

export const toggleSelfAssessmentPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_SELF_ASSESSMENT_PANEL)
export const openSelfAssessmentPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_SELF_ASSESSMENT_PANEL)

export const toggleNotesPanelAction = (analytics: {
  eventName: string
  category: string
  label: string
  value: string
}) => createAction(ReaderControlsActionType.TOGGLE_NOTES_PANEL, { analytics })

export const openNotesPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_NOTES_PANEL)

export const saveMathToolToGoogleAction = (
  mathToolIndex: ReaderControlsState['mathToolIndexToSaveToGoogle'],
) =>
  createAction(ReaderControlsActionType.SAVE_MATHTOOL_TO_GOOGLE, {
    mathToolIndex,
  })

export const toggleRelatedLearningObjPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_RELATED_LEARNING_OBJ_PANEL)
export const openRelatedLearningObjPanelAction = (data: {
  scrollTo: ReaderControlsState['scrollToSection']
}) =>
  createAction(ReaderControlsActionType.OPEN_RELATED_LEARNING_OBJ_PANEL, data)

export const toggleEducatorToolsPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_EDUCATOR_TOOLS_PANEL)
export const openEducatorToolsPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_EDUCATOR_TOOLS_PANEL)

export const toggleSearchPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_SEARCH_PANEL)
export const openSearchPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_SEARCH_PANEL)

export const toggleFractionStripsPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_FRACTION_STRIPS_PANEL)
export const openFractionStripsPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_FRACTION_STRIPS_PANEL)

export const togglePatternBlocksPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_PATTERN_BLOCKS_PANEL)
export const openPatternBlocksPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_PATTERN_BLOCKS_PANEL)

export const toggleAlgebraTilesPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_ALGEBRA_TILES_PANEL)
export const openAlgebraTilesPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_ALGEBRA_TILES_PANEL)

export const toggleSpinnerPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_SPINNER_PANEL)
export const openSpinnerPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_SPINNER_PANEL)

export const toggleIntegerChipsPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_INTEGER_CHIPS_PANEL)
export const openIntegerChipsPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_INTEGER_CHIPS_PANEL)

export const toggleBaseTenBlocksPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_BASE_TEN_BLOCKS_PANEL)
export const openBaseTenBlocksPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_BASE_TEN_BLOCKS_PANEL)

export const toggleBarModelPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_BAR_MODEL_PANEL)
export const openBarModelPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_BAR_MODEL_PANEL)

export const hideAllPanelsAction = () =>
  createAction(ReaderControlsActionType.HIDE_ALL_PANELS)

export const toggleCollectionInputAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_COLLECTION_INPUT)

export const toggleLinkCopiedAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_CITATION_LINK_COPIED)

export const hideAllModalsAction = () =>
  createAction(ReaderControlsActionType.HIDE_ALL_MODALS)

export const toggleInfoModalAction = (selectedPanel: string) =>
  createAction(ReaderControlsActionType.TOGGLE_INFO_MODAL, { selectedPanel })

export const togglePrintDropDownAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_PRINT_DROPDOWN)

export const toggleMathToolsPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_MATH_TOOLS_PANEL)
export const openMathToolsPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_MATH_TOOLS_PANEL)

export const toggleWhiteboardPanelAction = () =>
  createAction(ReaderControlsActionType.TOGGLE_WHITEBOARD_PANEL)

export const closeMathToolsMenuAction = () =>
  createAction(ReaderControlsActionType.CLOSE_MATH_TOOLS_PANEL)

export const openDesmosPanelAction = () =>
  createAction(ReaderControlsActionType.OPEN_DESMOS_PANEL)

export const closeDesmosPanelAction = () =>
  createAction(ReaderControlsActionType.CLOSE_DESMOS_PANEL)
