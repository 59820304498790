import { CollectionsSearchActionType } from '../actions/collectionsSearchActions'
import { CollectionsSearchState } from './reducerTypes'

const defaultState: CollectionsSearchState = {
  searchTerm: '',
}

type Action = {
  type: CollectionsSearchActionType.UPDATE_COLLECTION_SEARCH_TERM
  searchTerm: CollectionsSearchState['searchTerm']
}

export function collectionsSearchReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case CollectionsSearchActionType.UPDATE_COLLECTION_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
      }
    }
    default:
      return state
  }
}
