/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime' // Needed for redux-saga es6 generator support
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import 'sanitize.css/sanitize.css'
import { ConnectedRouter } from 'connected-react-router'
import { configureStore } from './configureStore'
import { configureApollo } from './configureApollo'
import { ApolloProvider } from '@apollo/client/react/context'
import possibleTypes from './graphql/schema/possibleTypes.json'
import AppRoot from './containers/AppRoot'
import GlobalStyles from './globalStyles'
import { initLogger } from './utils/logger'

/* eslint-disable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
import ApolloReduxConnector from './containers/ApolloReduxConnector'
import SiteErrorBoundary from './containers/error/SiteErrorBoundary'
import { checkForServiceWorkerUpdates } from './utils/sw'
import { MathJaxProvider } from './components/MathJax'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import LocaleProvider from './components/Locale/LocaleProvider'
import { PrivateRoute } from './components/PrivateRoute'
import { DevErrorThrower } from './containers/error/DevErrorThrowers'
import { storeShareLink } from './utils/sharableUrl'
/* eslint-enable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
// Load the favicon and the manifest.json file
import androidChrome192 from './assets/icon/android-chrome-192x192.png'
import androidChrome512 from './assets/icon/android-chrome-512x512.png'
import appleTouchIcon from './assets/icon/apple-touch-icon.png'
import fav from './assets/icon/favicon.ico'
import fav16 from './assets/icon/favicon-16x16.png'
import fav32 from './assets/icon/favicon-32x32.png'
import safariPinnedTab from './assets/icon/safari-pinned-tab.svg'
import { InMemoryCache } from '@apollo/client'
import { typePolicies } from './typePolicies'
import { ReaderMessageProvider } from './containers/reader/ReaderPostMessage'
import { WhiteboardMessageProvider } from './components/WhiteboardPostMessage'

function runCoreApp() {
  checkForServiceWorkerUpdates()
  window.logger.log('Initializing Edwin Core')

  const MOUNT_NODE = document.getElementById('app')
  const root = createRoot(MOUNT_NODE)
  // Create redux store with history
  const history = createBrowserHistory()
  const { store } = configureStore(history, {})

  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies,
  })

  const apolloClient = configureApollo(cache)

  const onLocationChanged = (location, action, isFirstRendering) => {
    window.logger.log('onLocationChanged', location, action, isFirstRendering)
  }

  const render = () => {
    root.render(
      <BrowserRouter>
        <Switch>
          <Route>
            <GlobalStyles />
            <Provider store={store}>
              <SiteErrorBoundary>
                <DevErrorThrower
                  funcName="throwSiteError"
                  message="Developer-triggered site error."
                />
                <ConnectedRouter
                  onLocationChanged={onLocationChanged}
                  history={history}
                  store={store}
                >
                  <ApolloProvider client={apolloClient}>
                    <LocaleProvider>
                      <PrivateRoute>
                        <ApolloReduxConnector>
                          <MathJaxProvider>
                            <ReaderMessageProvider>
                              <WhiteboardMessageProvider>
                                <AppRoot history={history} />
                              </WhiteboardMessageProvider>
                            </ReaderMessageProvider>
                          </MathJaxProvider>
                        </ApolloReduxConnector>
                      </PrivateRoute>
                    </LocaleProvider>
                  </ApolloProvider>
                </ConnectedRouter>
              </SiteErrorBoundary>
            </Provider>
          </Route>
        </Switch>
      </BrowserRouter>,
    )
  }

  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  if (module.hot) {
    module.hot.accept(['./containers/AppRoot'], () => {
      root.unmount()
      render()
    })
  }

  console.log('core module', module)

  storeShareLink()
  render()
}

// init Service Worker communication
async function runAppAfterSW() {
  window.logger.log('EdwinCore:runApp()')
  runCoreApp()
}

function loadManifestIcons() {
  const paths = [
    androidChrome192,
    androidChrome512,
    appleTouchIcon,
    fav,
    fav16,
    fav32,
    safariPinnedTab,
  ]
  for (const path of paths) {
    const img = new Image()
    img.src = `${path}?v=2`
  }
}

loadManifestIcons()
initLogger()
// noinspection JSIgnoredPromiseFromCall
runAppAfterSW()
