import { config } from './config'
import { errorLogger } from '../loggers'

const trackPageView = (data: { page?: string }) => {
  try {
    // @ts-ignore todo
    window.gtag('config', config.get('app.services.google.analytics.id'), {
      page_path: data.page,
    })
  } catch (error) {
    errorLogger.log(error)
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events#send_events
// `value` can only be a non-negative integer. Possibly use `label` to track strings
const trackEvent = ({
  eventName,
  category,
  label,
  value,
  nonInteraction,
}: {
  eventName: string
  category: string
  label: string
  value?: unknown
  nonInteraction?: boolean | null
}) => {
  try {
    // @ts-ignore todo
    window.gtag('event', eventName, {
      event_category: category,
      event_label: label,
      value,
      non_interaction: nonInteraction ?? false,
    })
  } catch (error) {
    errorLogger.log(error)
  }
}

const trackException = ({
  description,
  fatal,
}: {
  description: string
  fatal?: boolean | null
}) => {
  try {
    // @ts-ignore todo
    window.gtag('event', 'exception', {
      description,
      fatal: fatal || false,
    })
  } catch (error) {
    errorLogger.log(error)
  }
}

export default {
  trackEvent,
  trackPageView,
  trackException,
}
