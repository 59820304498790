import { EventEmitter } from 'events'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import { differenceInMilliseconds, subMilliseconds } from 'date-fns'
import { authServiceLogger as logger } from '../loggers'
import {
  EDWIN_AUTH_PATH,
  USER_CAPABILITIES,
  EXTERNAL_CAPABILITIES,
} from '../constants'
import { config } from './config'

// Configure Firebase.
const firebaseConfig = config.get('app.services.firebase')
const firebaseApp = firebase.initializeApp({
  ...firebaseConfig,
  authDomain: 'auth.edwin.app',
})

const firebaseAuth = firebaseApp.auth()
const firebaseFunctions = firebaseApp.functions('northamerica-northeast1')

const exchangeProviderToken = firebaseFunctions.httpsCallable(
  'exchangeProviderToken',
)
const sendVerificationEmail = firebaseFunctions.httpsCallable(
  'sendVerificationEmail',
)
const sendPasswordResetEmail = firebaseFunctions.httpsCallable(
  'sendPasswordResetEmail',
)
const verifyEdwinUser = firebaseFunctions.httpsCallable('verifyEdwinUser')
// const checkAuthStatus = firebaseFunctions.httpsCallable('checkAuthStatus')

const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('profile')
googleProvider.addScope('email')
googleProvider.setCustomParameters({ prompt: 'select_account' })

const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com')
microsoftProvider.addScope('profile')
microsoftProvider.addScope('email')
microsoftProvider.setCustomParameters({ prompt: 'select_account' })

const providers = {
  google: googleProvider,
  microsoft: microsoftProvider,
}
const prefixCapability = (capability: string) =>
  'edwin.capability.' + capability

export interface AuthCredential {
  idToken: firebase.auth.IdTokenResult
  accessToken: firebase.auth.IdTokenResult
}

const inMemoryStorage = (function () {
  const secrets: Record<string, string> = {}

  function getSecret(key: string) {
    return secrets[key] ?? false
  }

  function setSecret(key: string, value: string) {
    secrets[key] = value
  }

  function delSecret(key: string) {
    return delete secrets[key]
  }

  return {
    get: function (key: string) {
      return getSecret(key)
    },
    set: function (key: string, value: string) {
      setSecret(key, value)
      return true
    },
    del: function (key: string) {
      delSecret(key)
      return true
    },
  }
})()

class AuthService extends EventEmitter {
  init = async () => {
    window.logger.log('auth service init called')
    firebaseAuth.onIdTokenChanged(
      this.handleTokenCallback,
      this.handleAuthErrorCallback,
    )
    firebaseAuth.onAuthStateChanged(
      this.handleAuthStateChange,
      this.handleAuthErrorCallback,
    )
  }

  handleAuthErrorCallback = async (err: firebase.auth.Error) => {
    window.logger.error('auth service error callback', JSON.stringify(err))
  }

  handleAuthStateChange = async (user: firebase.User | null) => {
    if (user != null) {
      const userIdToken = await user.getIdTokenResult()
      console.log('firebase user sign in provider:', userIdToken.signInProvider)
      if (userIdToken.signInProvider !== 'custom') {
        // unwind provider signin to allow custom token signin
        console.log('non custom token logins are noop for auth state changes')
        await firebaseAuth.signOut()
        return
      }
    }

    // allow null user push to handle logout
    window.logger.log('auth service handle auth state change called', user)
    this.emit('state_change_no_user', user)
  }

  handleTokenCallback = async (user: firebase.User | null) => {
    if (user != null) {
      // custom token provider check
      const userIdToken = await user.getIdTokenResult()
      console.log('firebase user sign in provider:', userIdToken.signInProvider)
      if (userIdToken.signInProvider !== 'custom') {
        // unwind provider signin to allow custom token signin
        console.log('non custom token logins are noop for auth state changes')
        await firebaseAuth.signOut()
        return
      }

      window.logger.log('auth service handle token callback called:', user)
      // as far as I can tell, this does nothing since there is no auth_handle_token_callback event
      this.emit('auth_handle_token_callback', user)

      const idToken = await user.getIdTokenResult()

      window.logger.log(
        'auth service handle token callback token event triggered:',
        idToken,
      )

      this.emit('auth_credential', {
        idToken,
        accessToken: idToken, // firebase only provides id tokens
      })
    }
  }

  startSession = (authResult: AuthCredential) => {
    /* Handle Web Auth */
    window.logger.log('AuthService.startSession()')
    window.logger.log('auth result data:', authResult)
    window.logger.log(JSON.stringify(authResult))

    // set refresh token time to halfway between now & token expirationTime
    const expirationDateUTC = new Date(authResult?.accessToken?.expirationTime)
    const refreshTime = subMilliseconds(
      expirationDateUTC,
      0.3 * differenceInMilliseconds(expirationDateUTC, new Date()),
    )

    inMemoryStorage.set('edwin.accessToken', authResult.accessToken.token)
    inMemoryStorage.set('edwin.idToken', authResult.idToken.token)
    inMemoryStorage.set('edwin.userUid', authResult.idToken.claims.user_id)
    inMemoryStorage.set(
      'edwin.userId',
      authResult.idToken.claims['https://api.edwin.app/user/id'],
    )
    inMemoryStorage.set(
      'edwin.isTeacher',
      (
        authResult.idToken.claims['https://api.edwin.app/user/roles'] ?? []
      ).some((role: string) => role === 'Teacher')
        ? 'true'
        : 'false',
    )
    inMemoryStorage.set(
      'edwin.isAdmin',
      (
        authResult.idToken.claims['https://api.edwin.app/user/roles'] ?? []
      ).some((role: string) => role === 'Admin')
        ? 'true'
        : 'false',
    )
    inMemoryStorage.set(
      'edwin.userData',
      JSON.stringify(authResult.idToken.token),
    )
    inMemoryStorage.set(
      'edwin.expirationTime',
      authResult.accessToken.expirationTime,
    )
    inMemoryStorage.set('edwin.refreshTime', refreshTime.toISOString())

    // DO NOT localStorage.removeItem('edwin.previousEmail'). Used by `ResetLinkExpired` component
    window.localStorage.setItem(
      'edwin.previousEmail',
      authResult.idToken?.claims?.email,
    )

    // keep these in localStorage for math tools popout auth
    window.localStorage.setItem(
      'edwin.hasToken',
      authResult.accessToken.token ? 'true' : 'false',
    )
    window.localStorage.setItem(
      'edwin.expirationTime',
      authResult.accessToken.expirationTime,
    )

    const capabilities =
      authResult?.idToken?.claims['https://api.edwin.app/user/capabilities']
    if (Array.isArray(capabilities)) {
      capabilities.forEach((capability) => {
        inMemoryStorage.set(prefixCapability(capability), 'true')
      })
    }
  }

  hasCapability = (capability: string) => {
    if (
      capability === USER_CAPABILITIES.GPS_SELF &&
      inMemoryStorage.get('edwin.isAdmin') === 'true'
    ) {
      return true
    }
    return inMemoryStorage.get(prefixCapability(capability)) === 'true'
  }

  endSession = () => {
    logger.info('authService.endSession()')
    localStorage.removeItem('edwin.hasToken')
    localStorage.removeItem('edwin.expirationTime')
    inMemoryStorage.del('edwin.accessToken')
    inMemoryStorage.del('edwin.idToken')
    inMemoryStorage.del('edwin.userId')
    inMemoryStorage.del('edwin.userUid')
    inMemoryStorage.del('edwin.isTeacher')
    inMemoryStorage.del('edwin.userData')
    inMemoryStorage.del('edwin.userData')
    inMemoryStorage.del('edwin.expirationTime')
    inMemoryStorage.del('edwin.refreshTime')
    inMemoryStorage.del('edwin.passedEmail')
    inMemoryStorage.del('edwin.passedCourseCode')

    Object.values(USER_CAPABILITIES).forEach((capability) => {
      inMemoryStorage.del(prefixCapability(capability))
    })

    Object.values(EXTERNAL_CAPABILITIES).forEach((capability) => {
      inMemoryStorage.del(prefixCapability(capability))
    })

    // remove legacy localStorage
    window.localStorage.removeItem('edwin.accessToken')
    window.localStorage.removeItem('edwin.idToken')
    window.localStorage.removeItem('edwin.userId')
    window.localStorage.removeItem('edwin.userUid')
    window.localStorage.removeItem('edwin.isTeacher')
    window.localStorage.removeItem('edwin.userData')
    window.localStorage.removeItem('edwin.refreshTime')

    Object.values(USER_CAPABILITIES).forEach((capability) => {
      window.localStorage.removeItem(prefixCapability(capability))
    })

    Object.values(EXTERNAL_CAPABILITIES).forEach((capability) => {
      window.localStorage.removeItem(prefixCapability(capability))
    })
  }

  logout = async () => {
    await firebaseAuth.signOut()
    window.location = EDWIN_AUTH_PATH as unknown as Location
  }

  localHasToken = () => window.localStorage.getItem('edwin.hasToken')

  tokenNeedsRefresh = () => {
    const refreshTime = new Date(inMemoryStorage.get('edwin.refreshTime') ?? '')

    if (!refreshTime) {
      return true
    }
    return new Date() >= new Date(refreshTime)
  }

  tokenIsExpired = () => {
    const expirationTime = new Date(
      inMemoryStorage.get('edwin.expirationTime') ?? '',
    )

    if (!expirationTime) {
      return true
    }

    return new Date() >= new Date(expirationTime)
  }

  localTokenIsExpired = () => {
    const expirationTime = new Date(
      window.localStorage.getItem('edwin.expirationTime') ?? '',
    )

    if (!expirationTime) {
      return true
    }

    return new Date() >= new Date(expirationTime)
  }

  getUserId = () => inMemoryStorage.get('edwin.userId')
  getUserUid = () => inMemoryStorage.get('edwin.userUid')
  getAccessToken = () => inMemoryStorage.get('edwin.accessToken')
  // getRefreshAt = () => inMemoryStorage.get('edwin.refreshAt')
  getPreviousEmail = () => window.localStorage.getItem('edwin.previousEmail')
  userData = () => JSON.parse(inMemoryStorage.get('edwin.userData') ?? '')
  getIsTeacherOrAdmin = () =>
    inMemoryStorage.get('edwin.isTeacher') === 'true' ||
    inMemoryStorage.get('edwin.isAdmin') === 'true'

  getIsAdmin = () => inMemoryStorage.get('edwin.isAdmin') === 'true'
  getIsTeacherNotAdmin = () => inMemoryStorage.get('edwin.isTeacher') === 'true'

  // passed url login params used to pass email from usermgt to core
  setPassedUrlLoginParams = (email: string, courseCode: string) => {
    inMemoryStorage.set('edwin.urlEmail', email)
    inMemoryStorage.set('edwin.urlCourseCode', courseCode)
  }

  getPassedUrlLoginParams = () => {
    const email = inMemoryStorage.get('edwin.urlEmail')
    const courseCode = inMemoryStorage.get('edwin.urlCourseCode')
    return {
      email,
      courseCode,
    }
  }

  resetPassedUrlLoginParams = () => {
    inMemoryStorage.del('edwin.urlEmail')
    inMemoryStorage.del('edwin.urlCourseCode')
  }

  // Note: When Firebase auth is refreshed, firebaseAuth.onIdTokenChanged calls this.startSession(token), resetting the token in memory storage(and IndexedDB)
  refreshToken = async () => {
    window.logger.log('firebase auth refresh token')
    try {
      const token = (await firebaseAuth.currentUser?.getIdToken(true)) ?? ''
      window.logger.log('firebase auth refreshed', token)
      const uid = inMemoryStorage.get('edwin.userUid')
      const uri = config.get('app.services.apollo.graphqlEndpoint')
      const mutation = `mutation {
        updateLastSeen(
            uid: "${uid}",
        )
      }`

      const graphqlResponse = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query: mutation }),
      })

      if (graphqlResponse.ok) {
        const payload = await graphqlResponse.json()
        if (payload.data.updateLastSeen === true) {
          window.logger.log('lastSeen date updated')
        }
      }

      return { token }
    } catch (error) {
      return { error }
    }
  }
}

const authService = new AuthService()

export {
  authService,
  firebaseAuth,
  firebaseFunctions,
  exchangeProviderToken,
  sendVerificationEmail,
  sendPasswordResetEmail,
  verifyEdwinUser,
  // checkAuthStatus,
  providers,
}
