import { Middleware } from 'redux'
import Analytics from '../services/googleAnalytics'
import { LOCATION_CHANGE } from 'connected-react-router'
import { ReaderControlsActionType } from '../actions/readerControlsActions'
import { CollectionActionType } from '../actions/collectionsActions'
import { LibraryActionType } from '../actions/libraryActions'
import { MathToolsActionType } from '../actions/mathToolsActions'
import { LibrarySearchActionType } from '../actions/librarySearchActions'

const analyticsMiddleware: Middleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action)

    switch (action.type) {
      case LOCATION_CHANGE: {
        Analytics.trackPageView({
          page: action.payload.location.pathname,
        })
        break
      }
      case ReaderControlsActionType.TOGGLE_SELF_ASSESSMENT_PANEL: {
        const state = getState()
        if (state.readerControls.assessmentPanelIsVisible === true) {
          Analytics.trackEvent({
            eventName: 'showSelfAssessmentPanel',
            category: 'readerPanels',
            label: 'selfAssessment',
          })
        }
        break
      }
      case ReaderControlsActionType.TOGGLE_NOTES_PANEL: {
        const { eventName, category, label, value } = action.analytics
        Analytics.trackEvent({
          eventName,
          category,
          label,
          value,
        })
        break
      }

      case CollectionActionType.SELECT_SORTMETHOD_COLLECTION_SUBJECT: {
        Analytics.trackEvent({
          eventName: 'sortCollection',
          category: 'collections',
          label: 'sortMenu',
        })
        break
      }
      case LibraryActionType.GET_SHARABLE_LESSON_LINK: {
        const { id } = action
        Analytics.trackEvent({
          eventName: 'getShareableLink',
          category: 'reader',
          label: 'shareableLink',
          value: id,
        })
        break
      }

      // Math tools

      case MathToolsActionType.RESIZE_FRACTION_STRIPS: {
        Analytics.trackEvent({
          eventName: 'resizeFractionStrips',
          category: 'mathTools',
          label: 'fractionStrips',
        })
        break
      }
      case ReaderControlsActionType.TOGGLE_FRACTION_STRIPS_PANEL: {
        Analytics.trackEvent({
          eventName: 'openFractionStripsPanel',
          category: 'mathTools',
          label: 'fractionStrips',
        })
        break
      }
      case MathToolsActionType.TOGGLE_INFO_SCREEN: {
        Analytics.trackEvent({
          eventName: 'toggleFractionStripsInfo',
          category: 'mathTools',
          label: 'fractionStrips',
        })
        break
      }

      // Library search

      case LibrarySearchActionType.UPDATE_LIBRARY_SEARCH_TERM: {
        Analytics.trackEvent({
          eventName: 'searchTerm',
          category: 'librarySearch',
          label: action.searchTerm,
        })
        break
      }
      case LibrarySearchActionType.TOGGLE_LIBRARY_SUBJECT: {
        Analytics.trackEvent({
          eventName: 'toggleLibrarySubject',
          category: 'librarySearch',
          label: action.subject,
        })
        break
      }
      case LibrarySearchActionType.TOGGLE_LIBRARY_STRAND: {
        Analytics.trackEvent({
          eventName: 'toggleLibraryStrand',
          category: 'librarySearch',
          label: action.strand,
        })
        break
      }

      default: {
        break
      }
    }

    return result
  }
}

export default analyticsMiddleware
