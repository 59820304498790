// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f04ff864a5707a2fc256{position:relative;display:flex;flex:1 1 auto;flex-direction:column;width:100%;height:100%;background-size:cover;background-repeat:no-repeat}", "",{"version":3,"sources":["webpack://./src/core/app/components/Page.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,aAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,2BAAA","sourcesContent":[".page {\n  position: relative;\n  display: flex;\n  flex: 1 1 auto;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "f04ff864a5707a2fc256"
};
export default ___CSS_LOADER_EXPORT___;
