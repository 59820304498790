import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useScript } from '../hooks/useScript'
import { config } from '../services/config'
import * as PropTypes from 'prop-types'

const MathJaxContext = createContext({
  loaded: false,
  error: false,
})

export function MathJaxProvider({ children }) {
  const [loaded, error] = useScript(config.get('app.services.content.mathjax'))

  useEffect(() => {
    if (loaded && window.MathJax) {
      window.logger.log('MathJax: loaded & configured')
    } else if (error) {
      window.logger.warn('Error loading MathJax')
    }
  }, [loaded, error])

  return (
    <MathJaxContext.Provider value={{ loaded, error, MathJax: window.MathJax }}>
      {children}
    </MathJaxContext.Provider>
  )
}
MathJaxProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Right now we only support mathML (It's what the Edwin Content Team uses)
export function MathJaxNode({ mathML, className }) {
  const { loaded } = useContext(MathJaxContext)
  const el = useRef(null)

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typeset()
    } else {
      window.logger.warn('attempting to typeset mathjax before it loaded')
    }
  }, [loaded, mathML])

  return (
    <span
      ref={el}
      dangerouslySetInnerHTML={{ __html: mathML }}
      className={className}
    />
  )
}

MathJaxNode.propTypes = {
  mathML: PropTypes.node,
  className: PropTypes.string,
}
