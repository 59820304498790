import { config } from '../services/config'

window.dataLayer = window.dataLayer || []

function gtag() {
  // eslint-disable-next-line no-undef
  dataLayer.push(arguments)
}
gtag('js', new Date())
gtag('config', config.get('app.services.google.analytics.id'))
window.gtag = gtag
