import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'

// todo: deprecate this in favor of useOnClickOutside
export default function withOnClickOutside(WrappedComponent) {
  return class extends Component {
    static propTypes = {
      handleClickOutside: PropTypes.func,
    }

    constructor(props) {
      super(props)
      // noinspection JSUnusedGlobalSymbols
      this.elRef = React.createRef()
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.onClickOutside)
      document.addEventListener('touchstart', this.onClickOutside)
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.onClickOutside)
      document.removeEventListener('touchstart', this.onClickOutside)
    }

    render() {
      // noinspection JSUnusedLocalSymbols,JSPotentiallyInvalidUsageOfThis
      const { handleClickOutside, ...restProps } = this.props
      // noinspection JSUnusedGlobalSymbols
      return <WrappedComponent ref={(el) => (this.elRef = el)} {...restProps} />
    }

    onClickOutside = (e) => {
      const { elRef } = this
      const { handleClickOutside } = this.props
      // todo: Research if this the best way to get the final DOMNode in the stack of children. Should be able to find
      // via passing refs down the stack or with forwardRef or Context but that seems overly complex.
      // eslint-disable-next-line react/no-find-dom-node
      if (
        handleClickOutside &&
        typeof handleClickOutside === 'function' &&
        elRef &&
        // eslint-disable-next-line react/no-find-dom-node
        !findDOMNode(elRef).contains(e.target)
      ) {
        handleClickOutside()
      }
    }
  }
}
