import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  fadeIn,
  svgStrokeSelector,
  ACTIVE_COLOR,
  svgFillSelector,
  LIGHT_GRAY_COLOR,
  WHITE_COLOR,
  GRAY_COLOR,
} from '@edwin-edu/ui-web-components'
import {
  FormattedLink,
  FormattedClickButton,
} from '../../components/Locale/FormattedUIComponents'
import { NAV_MENU_BUTTON_PADDING } from '../../constants'
import ActiveDotSVG from '../../assets/svg/icon-dot-active.svg'

export default function FormattedMainMenuButton(props) {
  const { active, to, onClick, id, ...restProps } = props
  const renderArr = active ? [<ActiveIcon key="active-icon" />] : []
  if (to) {
    // If it has a `to` prop, it's a link. Otherwise, it's a button
    renderArr.push(
      <MenuLink
        active={active}
        key="navmenu-link"
        id={id || 'MenuLink_fallback'}
        tooltipMargin={1}
        {...props}
      />,
    )
  } else {
    renderArr.push(
      <MenuButton
        active={active}
        key="navmenu-button"
        id={id || 'MenuButton_fallback'}
        tooltipMargin={1}
        clickHandler={onClick}
        {...restProps}
      />,
    )
  }
  return renderArr
}

FormattedMainMenuButton.propTypes = {
  to: PropTypes.string,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  clickValue: PropTypes.any,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
}

const getColor = ({ active, gray }) => {
  if (active) {
    return ACTIVE_COLOR
  }
  if (gray) {
    return LIGHT_GRAY_COLOR
  }
  return WHITE_COLOR
}

const sharedStyles = css`
  position: relative;
  display: block;
  width: 50px;
  padding: ${NAV_MENU_BUTTON_PADDING};

  ${svgStrokeSelector} {
    stroke: ${(props) => getColor(props)};
  }
  ${svgFillSelector} {
    fill: ${(props) => getColor(props)};
  }

  :enabled:hover,
  :hover {
    ${svgStrokeSelector} {
      stroke: ${({ active }) => (active ? ACTIVE_COLOR : GRAY_COLOR)};
    }
    ${svgFillSelector} {
      fill: ${({ active }) => (active ? ACTIVE_COLOR : GRAY_COLOR)};
    }
  }
`
const MenuLink = styled(FormattedLink).attrs(({ white }) => ({
  white,
}))`
  ${sharedStyles}
`
const MenuButton = styled(FormattedClickButton).attrs(({ white }) => ({
  white,
}))`
  ${(props) =>
    props.color &&
    css`
      ${svgStrokeSelector} {
        stroke: ${props.color} !important;
      }
    `};
  ${sharedStyles};
`
const ActiveIcon = styled(ActiveDotSVG)`
  position: absolute;
  bottom: 4px;
  left: calc(50% - 3px);
  opacity: 0;
  animation: 200ms ease-out forwards ${fadeIn};
`
