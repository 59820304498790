import { createAction } from './index'

export enum NavMenuActionType {
  CLOSE_NAV_FLYOUT = 'EDWIN/NAV_MENU/CLOSE',
  TOGGLE_NOTIFICATIONS_MENU = 'EDWIN/NAV_MENU/NOTIFICATIONS/TOGGLE',
}

export const closeNavFlyoutAction = () =>
  createAction(NavMenuActionType.CLOSE_NAV_FLYOUT)

export const toggleNotificationsMenuAction = () =>
  createAction(NavMenuActionType.TOGGLE_NOTIFICATIONS_MENU)
