import React, { useState, useEffect, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { parseISO, isBefore } from 'date-fns'
import { useLocation } from 'react-router'
import styles from './NotificationsDrawer.module.scss'
import { ClickButton, Switch, Notification } from '@edwin-edu/ui-web-components'
import XWhiteSVG from '../../assets/svg/icon-x-white.svg'
import { useActions } from '../../hooks/useActions'
import { useLocale } from '../../graphql/user/locale'
import {
  useUpdateNotificationsLastViewed,
  useUpdateNotificationsShouldAlert,
} from '../../graphql/notifications/notifications'
import { toggleNotificationsMenuAction } from '../../actions/navMenuActions'
import { BottomFlyoutMenu } from './StyledComponents'
import { useReduxSelector } from '../../reducers/reducerTypes'
import {
  useGetNotificationsProfileQuery,
  useListNotificationsQuery,
} from '../../types/graphql'
import { exists } from '../../utils/exists'
import VirtualSessionsIcon from '../../assets/svg/sidebar/icon-virtual-session.svg'
import { Flex } from '@edwin-edu/ui'
import { ExternalLink } from '../../components/ExternalLink'
import { authService } from '../../services/authentication'

type NotificationsDrawerProps = {
  notificationType: string
}

const NotificationsDrawer: FC<NotificationsDrawerProps> = () => {
  const isTeacher = authService.getIsTeacherOrAdmin()
  const { data } = useListNotificationsQuery()
  const notifications = data?.notifications ?? []
  const { data: notificationData } = useGetNotificationsProfileQuery()
  const notificationsShouldAlert =
    notificationData?.user?.notificationsShouldAlert ?? false
  const notificationsViewedTime =
    notificationData?.user?.notificationsViewedTime ?? ''
  const updateNotificationsShouldAlert = useUpdateNotificationsShouldAlert()
  const updateNotificationsLastViewed = useUpdateNotificationsLastViewed()
  const locale = useLocale()
  const { notificationsMenuIsOpen } = useReduxSelector((state) => state.navMenu)
  const [prevViewedTime, setPrevViewedTime] = useState<string>()
  const isScreenshotAnimating = useReduxSelector(
    (state) => state.mathTools.screenShotIsAnimating,
  )
  const [toggleNotificationsMenu] = useActions([toggleNotificationsMenuAction])

  useEffect(() => {
    if (notificationsMenuIsOpen) {
      setPrevViewedTime(notificationsViewedTime)
      updateNotificationsLastViewed()
    }
  }, [notificationsMenuIsOpen])

  return (
    <>
      {!isScreenshotAnimating && (
        <BottomFlyoutMenu isOpen={notificationsMenuIsOpen}>
          <div className={styles.container}>
            <ClickButton
              // @ts-ignore todo: type shared styledComponents file
              className={styles.closeButton}
              aria-label="Close notifications drawer"
              onClick={toggleNotificationsMenu}
              white
            >
              <XWhiteSVG width="15px" />
            </ClickButton>
            <Flex className={styles.drawerTitle}>
              <FormattedMessage id="NotificationsDrawer_Title" />
            </Flex>
            <Flex className={styles.toggleNotificationsContainer}>
              {isTeacher && (
                <Flex>
                  <VirtualSessionsIcon className={styles.icon} />
                  <ExternalLink
                    className={styles.link}
                    href={'https://edwin.app/success/#calendar'}
                  >
                    <FormattedMessage id="NotificationsDrawer_virtualSessions" />
                  </ExternalLink>
                </Flex>
              )}
              <div className={styles.switchContainer}>
                <FormattedMessage id="NotificationsDrawer_Alerts" />
                <Switch
                  className={styles.switch}
                  aria-label="Toggle notifications switch"
                  active={notificationsShouldAlert}
                  onClick={() =>
                    updateNotificationsShouldAlert(!notificationsShouldAlert)
                  }
                />
              </div>
            </Flex>
            <div className={styles.notificationList}>
              {notifications
                .filter(exists)
                .filter((notification) => {
                  return isBefore(
                    parseISO(notification?.availableDate ?? ''),
                    new Date(),
                  )
                })
                .map((notification) => (
                  <Notification
                    key={'notification-' + notification.id}
                    // @ts-ignore todo: fix typing of availableDate
                    notification={notification}
                    labels={{
                      today: <FormattedMessage id="today" />,
                      yesterday: <FormattedMessage id="yesterday" />,
                    }}
                    locale={(!notification?.fr?.title && 'en') || locale}
                    lastViewedTime={prevViewedTime || ''}
                  />
                ))}
            </div>
          </div>
        </BottomFlyoutMenu>
      )}
    </>
  )
}

type RenderOnLocationChangeProps = {
  notificationType: string
}
export default function RerenderOnLocationChange({
  notificationType,
}: RenderOnLocationChangeProps) {
  const { pathname = '' } = useLocation()
  return (
    <NotificationsDrawer key={pathname} notificationType={notificationType} />
  )
}
