import React from 'react'
import { ReactSVG } from 'react-svg'

// Icons
import SubjectCanadianSVG from './assets/svg/library/canadian.svg'
import SubjectDigitalCitizenshipSVG from './assets/svg/library/digitalcitizenship.svg'
import SubjectFrenchSVG from './assets/svg/library/french.svg'
import SubjectGeographySVG from './assets/svg/library/geography.svg'
import SubjectGeopoliticsSVG from './assets/svg/library/geopolitics.svg'
import SubjectHealthSVG from './assets/svg/library/health.svg'
import SubjectHistorySVG from './assets/svg/library/history.svg'
import SubjectLiteracySVG from './assets/svg/library/literacy.svg'
import SubjectMathSVG from './assets/svg/library/math.svg'
import SubjectScienceSVG from './assets/svg/library/science.svg'

// User Capabilities
export const USER_CAPABILITIES = {
  GPS_SINGLE: 'user:gps:single',
  GPS_MULTI: 'user:gps:multi',
  GPS_SELF: 'user:gps:self',
}
export const EXTERNAL_CAPABILITIES = {
  TOOLS_DESMOS: 'external:tools:desmos',
}
// export const NELSON_USER_ID = '419bd058-dfce-431d-8617-13a7b03cd06f'

// IPAD
// export const IPAD_MIN_WIDTH = 768
// export const IPAD_MAX_WIDTH = 1024

// Paths & Files
export const HOME_PATH = '/'
export const LIBRARY_PATH = '/library'
export const READER_PATH = '/reader'
export const READER_LO_PATH = `${READER_PATH}/:learningObjectId`
export const LIBRARY_BROWSE_PATH = `${LIBRARY_PATH}/browse-results`
export const LIBRARY_BROWSE_CURRICULUM_ID_PATH = `${LIBRARY_BROWSE_PATH}/:browseType/:curriculumId`
export const LIBRARY_CURRICULUM_PATH = `${LIBRARY_PATH}/curriculum`
export const LIBRARY_CURRICULUM_SUBJECT_ID_PATH = `${LIBRARY_CURRICULUM_PATH}/:subjectId`
export const LIBRARY_SEARCH_PATH = `${LIBRARY_PATH}/search-results`
export const LIBRARY_SUBJECT_PATH = `${LIBRARY_PATH}/browse-by-subject`
export const LIBRARY_SUBJECT_ID_PATH = `${LIBRARY_SUBJECT_PATH}/:subjectId`
export const BROWSE_OBJECTIVE_PATH = `${LIBRARY_PATH}/browse-objective`
export const BROWSE_OBJECTIVE_TYPE_ID_PATH = `${BROWSE_OBJECTIVE_PATH}/:courseId/:objectiveType/:objectiveId`
export const COLLECTIONS_PATH = '/collections'
export const BROWSE_COLLECTIONS_PATH = '/browse-collections'
export const SINGLE_COLLECTION_PATH = `${COLLECTIONS_PATH}/:collectionId`
// dashboard teacher paths
export const DASHBOARD_PATH = '/dashboard'
export const DASHBOARD_JOIN_PATH = `${DASHBOARD_PATH}/join` // only used when passing edwinAuth email and courseCode from usermgt to join course
export const DASHBOARD_CLASS_PATH = `${DASHBOARD_PATH}/course/:courseId`
export const DASHBOARD_CLASS_PROGRESS_PATH = `${DASHBOARD_CLASS_PATH}/progress`
export const DASHBOARD_CLASS_MEMBERS_PATH = `${DASHBOARD_CLASS_PATH}/members`
export const DASHBOARD_CLASS_INFO_PATH = `${DASHBOARD_CLASS_PATH}/info`
export const DASHBOARD_CLASS_PLANNER_NAME = `planner`
export const DASHBOARD_CLASS_PLANNER_PATH = `${DASHBOARD_CLASS_PATH}/${DASHBOARD_CLASS_PLANNER_NAME}`
export const DASHBOARD_CLASS_ASSESSMENTS_PATH = `${DASHBOARD_CLASS_PATH}/assessments`
export const DASHBOARD_TEACHER_IND_PROGRESS_PATH =
  '/dashboard/course/:courseId/student/:studentId' // Teacher Only
export const DASHBOARD_CLASS_STUDENT_LOS_PATH = `${DASHBOARD_CLASS_PATH}/students/learning-objects/:studentId` // Teacher Only
export const DASHBOARD_ARCHIVED_CLASSES_PATH = `${DASHBOARD_PATH}/archived` // Teacher Only
// dashboard student paths
export const DASHBOARD_STUDENT_PATH = '/dashboard'
export const DASHBOARD_STUDENT_COURSES_PATH = `${DASHBOARD_STUDENT_PATH}/courses`
export const DASHBOARD_STUDENT_PROGRESS_PATH = `${DASHBOARD_STUDENT_PATH}/progress`
export const DASHBOARD_STUDENT_CLASS_PATH = `${DASHBOARD_STUDENT_PATH}/course/:courseId`
export const DASHBOARD_STUDENT_LOS_PATH = `${DASHBOARD_STUDENT_PATH}/learning-objects/:studentId`
export const DASHBOARD_STUDENT_ASSESSMENTS_PATH = `${DASHBOARD_STUDENT_PATH}/assessments`

export const ASSESSMENTS_LANDING_PATH = '/assessments'
export const ASSESSMENT_PATH = `${ASSESSMENTS_LANDING_PATH}/:assessmentId`
export const ASSESSMENT_STEP_PATH = `${ASSESSMENT_PATH}/:step`
export const ASSESSMENT_RESULTS_PATH = `${ASSESSMENT_PATH}/results`
export const ASSESSMENT_REVIEW_PATH = `${ASSESSMENT_PATH}/review/:step`
export const ASSESSMENT_PRACTICE_PATH = `${ASSESSMENT_PATH}/practice`

export const ADMIN_PATH = '/admin'
export const SETTINGS_PATH = '/settings'
export const SETTINGS_USER_SETTINGS = `${SETTINGS_PATH}/user-settings`
export const LIBRARY_ERROR_PATH = '/library-error'
export const EDWIN_AUTH_PATH = '/login'
export const CONFIRM_EMAIL_PATH = '/confirm'
export const RESET_LINK_EXPIRED_PATH = '/password-reset-link-expired'
export const RETRY_PATH = '/retry'
export const PASSAUTH_PATH = '/auth'
export const ONBOARDING_PATH = '/onboarding'
export const NO_MENU_PATHS = `(${EDWIN_AUTH_PATH}|${ONBOARDING_PATH}|${PASSAUTH_PATH})`

// Assessments
export const COMPLETE = 'COMPLETE'

// Math Tools
export const NOTES_PATH = '/notes'
export const MATH_TOOLS_PATH = '/math-tools'
export const ALGEBRA_TILES = 'algebra-tiles'
export const PATTERN_BLOCKS = 'pattern-blocks'
export const FRACTION_STRIPS = 'fraction-strips'
export const INTEGER_CHIPS = 'integer-chips'
export const SPINNER = 'spinner'
export const BASE_TEN_BLOCKS = 'base-ten-blocks'
export const BAR_MODEL = 'bar-model'

// Popout Paths
export const ROOT_POPOUT_PATH = '/popout'
export const POPOUT_SPINNER_PATH = `${ROOT_POPOUT_PATH}/spinner`
export const POPOUT_ALGEBRA_TILES_PATH = `${ROOT_POPOUT_PATH}/${ALGEBRA_TILES}`
export const POPOUT_FRACTION_STRIPS_PATH = `${ROOT_POPOUT_PATH}/fraction-strips`
export const POPOUT_PATTERN_BLOCKS_PATH = `${ROOT_POPOUT_PATH}/${PATTERN_BLOCKS}`
export const POPOUT_INTEGER_CHIPS_PATH = `${ROOT_POPOUT_PATH}/${INTEGER_CHIPS}`
export const POPOUT_BASE_TEN_BLOCKS_PATH = `${ROOT_POPOUT_PATH}/${BASE_TEN_BLOCKS}`
export const POPOUT_BAR_MODEL_PATH = `${ROOT_POPOUT_PATH}/${BAR_MODEL}`
export const POPOUT_DESMOS_GEOMETRY_TOOLS_PATH = `${ROOT_POPOUT_PATH}/desmos/geometry-tools`
export const POPOUT_DESMOS_GRAPHING_CALCULATOR_PATH = `${ROOT_POPOUT_PATH}/desmos/math-tools`
export const POPOUT_DESMOS_SCIENTIFIC_CALCULATOR_PATH = `${ROOT_POPOUT_PATH}/desmos/scientific-calculator`
export const POPOUT_WHITEBOARD_PATH = `${ROOT_POPOUT_PATH}/whiteboard`

// Sizes & Colors
// check app reducer for min width per page
export const SITE_MIN_WIDTH = 975
export const HOME_MIN_WIDTH = 1150
export const READER_MIN_WIDTH = 975
export const DASHBOARD_MIN_WIDTH = 975
export const COLLECTIONS_MIN_WIDTH = 1020
export const SITE_MIN_HEIGHT = '610px'
export const TOPBAR_HEIGHT = 50
export const BOTTOMBAR_HEIGHT = 50
export const HIDE_TOPBAR_ANIM_TIME = '500ms'
export const NAV_MENU_WIDTH = '50px'
export const NAV_MENU_BUTTON_PADDING = '11px 0'
export const NAV_MENU_FLYOUT_WIDTH = '260px'
export const TOOLS_MENU_FLYOUT_WIDTH = '345px'
export const READER_BOTTOM_MENU_HEIGHT = 50
export const BREADCRUMBS_HEIGHT = '50px'
export const NAV_ARROWS_WIDTH = '165px'

// Reader Side Panel Widths
export const DEFAULT_TRAY_WIDTH = 970
export const DEFAULT_TRAY_COLLAPSED_WIDTH = 700
export const MAX_TRAY_WIDTH = 160
export const MIN_TRAY_WIDTH = 100
export const SIDE_PANEL_WIDTH = 450
export const FRACTION_STRIPS_WIDTH = 965
export const FRACTION_STRIPS_COLLAPSED_WIDTH = 657
export const PATTERN_BLOCKS_WIDTH = DEFAULT_TRAY_WIDTH
export const PATTERN_BLOCKS_COLLAPSED_WIDTH = DEFAULT_TRAY_COLLAPSED_WIDTH
export const SPINNER_WIDTH = 975
export const SPINNER_COLLAPSED_WIDTH = DEFAULT_TRAY_COLLAPSED_WIDTH
export const ALGEBRA_TILES_WIDTH = DEFAULT_TRAY_WIDTH
export const ALGEBRA_TILES_COLLAPSED_WIDTH = DEFAULT_TRAY_COLLAPSED_WIDTH
export const INTEGER_CHIPS_WIDTH = DEFAULT_TRAY_WIDTH
export const INTEGER_CHIPS_COLLAPSED_WIDTH = DEFAULT_TRAY_COLLAPSED_WIDTH
export const BASE_TEN_BLOCKS_WIDTH = DEFAULT_TRAY_WIDTH
export const BASE_TEN_BLOCKS_COLLAPSED_WIDTH = DEFAULT_TRAY_COLLAPSED_WIDTH
export const BAR_MODEL_WIDTH = DEFAULT_TRAY_WIDTH
export const BAR_MODEL_COLLAPSED_WIDTH = DEFAULT_TRAY_COLLAPSED_WIDTH
export const NOTES_PANEL_EXPANDED_WIDTH = 664
export const DESMOS_PANEL_WIDTH = 850
export const DESMOS_PANEL_COLLAPSED_WIDTH = 400

// popout app heights and widths
export const MATH_TOOL_POPOUT_HEIGHT = 600
export const MATH_TOOL_POPOUT_WIDTH = 1100
export const DESMOS_POPOUT_HEIGHT = 540
export const DESMOS_POPOUT_WIDTH = 880

// media query widths
export const READER_DESKTOP_NARROW = '1050px'

export const DEFAULT_VOICE_ID = 0
export const DEFAULT_VOICE_RATE = 1

// Fuzzy logic backup in case primary manifest fails
const GET_SUBJECT_ICON_FUZZY = (subjectName) => {
  subjectName = subjectName || ''
  if (subjectName.includes('Geography')) {
    return <SubjectGeographySVG />
  }
  if (subjectName.includes('Math')) {
    return <SubjectMathSVG />
  }
  if (subjectName.includes('World')) {
    return <SubjectGeopoliticsSVG />
  }
  if (subjectName.includes('Language') || subjectName.includes('English')) {
    return <SubjectLiteracySVG />
  }
  if (subjectName.includes('History')) {
    return <SubjectHistorySVG />
  }
  if (subjectName.includes('Science')) {
    return <SubjectScienceSVG />
  }
  if (subjectName.includes('Canada')) {
    return <SubjectCanadianSVG />
  }
  if (subjectName.includes('French')) {
    return <SubjectFrenchSVG />
  }
  if (subjectName.includes('Digital Citizenship')) {
    return <SubjectDigitalCitizenshipSVG />
  }
  if (subjectName.includes('Health') || subjectName.includes('health')) {
    return <SubjectHealthSVG />
  }

  return <SubjectLiteracySVG />
}

// Subject Icon Mapping
export const GET_SUBJECT_ICON = (subjectName, svgUrl = null) => {
  if (svgUrl) {
    return <ReactSVG src={svgUrl} />
  }
  switch (subjectName) {
    case 'Social Studies':
    case 'Democracy: Action and Participation':
    case 'Historical Worldviews Examined':
    case 'Perspectives on Globalization':
    case 'Living in a Globalizing World':
      return <SubjectLiteracySVG />
    case 'Geography':
      return <SubjectGeographySVG />
    case 'History':
      return <SubjectHistorySVG />
    case 'English Academic':
    case 'Language':
    case 'English Language Arts':
    case 'English/Language Arts':
      return <SubjectLiteracySVG />
    case 'Mathematics':
    case 'Principles of Mathematics':
      return <SubjectMathSVG />
    case 'Science':
    case 'Science and Technology':
    case 'Science Academic (SNC1D)':
      return <SubjectScienceSVG />
    case 'Issues in Canadian Geography':
    case 'Canadian World History since WWI':
    case 'Canada: Opportunities and Challenges':
    case 'Canada: Origins, Histories and Movement of Peoples':
      return <SubjectCanadianSVG />
    case 'Digital Citizenship':
      return <SubjectDigitalCitizenshipSVG />
    default:
      return GET_SUBJECT_ICON_FUZZY(subjectName)
  }
}

// Marketing Subjects
export const MARKETING_SUBJECT_OTHER = 'Other Subjects'
export const MARKETING_SUBJECT_OTHER_ID = '7679f022-a8d2-42fb-bd64-17b7c7aa7716'
export const MARKETING_SUBJECT_MICROLEARNING = 'Microlearning'
export const MARKETING_SUBJECT_MICROLEARNING_ID =
  '8d617625-d953-4929-b4e3-882bec4abf8a'

// export const ONBOARDED = 'onboarded'

export const teacherTypesOptions = [
  {
    label: 'As a classroom teacher (full time or part time)',
    value: 'Classroom Teacher',
  },
  {
    label: 'Another way (e.g., Principal, Special Education)',
    value: 'Other',
  },
]
