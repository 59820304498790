import React, { FC } from 'react'
import styles from './SharedComponents.module.scss'
import { Flex } from '@edwin-edu/ui'

export const renderErrorType = (children) => {
  return <Flex className={styles.errorType}>{children}</Flex>
}
export const renderErrorMessage = (children) => {
  return <Flex className={styles.errorMessage}>{children}</Flex>
}
export const renderErrorActionMessage = (children) => {
  return <Flex className={styles.errorActionMessage}>{children}</Flex>
}
