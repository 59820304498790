import { eventChannel } from 'redux-saga'

export function createServiceWorkerReadyChannel() {
  return eventChannel((emitter) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        emitter({ type: 'SERVICE_WORKER_READY', payload: { registration } })
      })
    }

    return () => {}
  })
}

export function createServiceWorkerUpdateChannel() {
  return eventChannel((emitter) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.addEventListener('updatefound', () => {
          window.logger.log('[Service Worker] Service worker update found')
          const worker = registration.installing

          worker?.addEventListener('statechange', (event) => {
            const target = event.target as ServiceWorker
            window.logger.log(
              '[Service Worker] State change detected in incoming service worker:',
              target?.state,
            )
            switch (target.state) {
              case 'installed': {
                // service worker is installed and waiting for activation
                emitter({ type: 'SERVICE_WORKER', name: 'installed' })
                break
              }
            }
          })
        })
      })
    }

    return () => {}
  })
}
