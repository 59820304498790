import { createAction } from './index'
import { CurriculumState } from '../reducers/reducerTypes'

export enum UserCurriculumActionType {
  STORE_USER_CURRICULUM = 'EDWIN/USER_CURRICULUM/STORE',
}

export const storeUserCurriculumAction = (
  gpsArray: CurriculumState['gpsArray'],
  userCurriculum: CurriculumState['userCurriculum'],
  marketingSubjects: CurriculumState['marketingSubjects'],
  timestamp: CurriculumState['timestamp'],
) =>
  createAction(UserCurriculumActionType.STORE_USER_CURRICULUM, {
    gpsArray,
    userCurriculum,
    marketingSubjects,
    timestamp,
  })
