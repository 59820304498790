import React from 'react'
import ErrorPage from './ErrorPage'
import {
  renderErrorType,
  renderErrorMessage,
  renderErrorActionMessage,
} from './SharedComponents'
import ErrorIllustrationSVG from '../../assets/svg/icon-error-illustration-blue.svg'
import { handleRetry } from '../../apollo/store'
import { Button } from '@edwin-edu/ui'
// import { FormattedMessage } from 'react-intl'

// Doesn't work yet bc SiteErrorBoundary is outside of LocaleProvider which will be reworked soon

const AppErrorPage = () => {
  return (
    <ErrorPage pageIconSVG={<ErrorIllustrationSVG />}>
      {renderErrorType('Error 300')}
      {renderErrorMessage('Application Error')}
      {renderErrorActionMessage(
        <p>
          Something went wrong. Check your internet connection and try again.
        </p>,
      )}
      <Button style={{ width: 'fit-content' }} onClick={handleRetry}>
        Try again
      </Button>
    </ErrorPage>
  )
}

export default AppErrorPage
