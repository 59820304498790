import React, { FC, PropsWithChildren } from 'react'
import { useLocation } from 'react-router'
import { useGetShouldOnboardQuery } from '../../types/graphql'
import { ONBOARDING_PATH } from '../../constants'
import { LoadingPage } from '../LoadingPage'
import { useActions } from '../../hooks/useActions'
import { pushAction } from '../../actions/pushAction'

export const OnboardingCheck: FC<PropsWithChildren> = ({ children }) => {
  const [push] = useActions([pushAction])
  const location = useLocation()
  const { data, loading } = useGetShouldOnboardQuery()

  if (loading) {
    return <LoadingPage />
  }

  if (
    !location.pathname.startsWith(ONBOARDING_PATH) &&
    data?.getShouldOnboard
  ) {
    push(ONBOARDING_PATH)
  }

  return <>{children}</>
}
