import { createAction } from './index'
import { CollectionsSearchState } from '../reducers/reducerTypes'

export enum CollectionsSearchActionType {
  UPDATE_COLLECTION_SEARCH_TERM = 'EDWIN/COLLECTIONS/SEARCH/SEARCH_TERM/UPDATE',
}

export const updateSearchTermAction = (
  searchTerm: CollectionsSearchState['searchTerm'],
) =>
  createAction(CollectionsSearchActionType.UPDATE_COLLECTION_SEARCH_TERM, {
    searchTerm,
  })
