import { useEffect } from 'react'
import { usePushAction } from '../actions/pushAction'

/**
 * Hook that redirects to `url` in `timeout` milliseconds if it's parent component is not unmounted before.
 */
interface Props {
  timeout?: number
  url: string
}
export function useTimeoutRedirect({ timeout, url }: Props) {
  const push = usePushAction()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined
    if (timeout && timeout > 0) {
      window.logger.warn('setting timeout', timeout)
      timeoutId = setTimeout(() => {
        window.logger.warn(`useTimeoutRedirect redirecting to ${url}`)
        push(url)
      }, timeout)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeout])
}
