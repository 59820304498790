import en from './en'
import fr from './fr'
import { LOCALES, Messages } from '@edwin-edu/ui-web-components'

export default {
  [LOCALES.EN.value]: Object.assign(
    {},
    en.messages,
    Messages[LOCALES.EN.value],
  ),
  [LOCALES.FR.value]: Object.assign(
    {},
    fr.messages,
    Messages[LOCALES.FR.value],
  ),
}
