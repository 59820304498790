import { createAction } from './index'
import { BreadcrumbsState, UserFiltersState } from '../reducers/reducerTypes'

export enum AppActionType {
  EDWIN_INIT = '@@EDWIN/INIT',
  ONLINE = 'EDWIN/ONLINE',
  OFFLINE = 'EDWIN/OFFLINE',
  SYNC_APOLLO_DATA = 'EDWIN/SYNC_APOLLO_DATA',
  VERSION_CHECKED = 'EDWIN/VERSION_CHECKED',
  OVERWRITE_BREADCRUMBS = 'BREADCRUMBS/OVERWRITE',
}

export const edwinInit = () => createAction(AppActionType.EDWIN_INIT)

export const goOnlineAction = () => createAction(AppActionType.ONLINE)

export const goOfflineAction = () => createAction(AppActionType.OFFLINE)

export const syncApolloDataAction = (
  data: { gpsIsLoading: UserFiltersState['isInitialized'] } & Pick<
    UserFiltersState,
    'grades' | 'provinces' | 'subjects'
  >,
) => createAction(AppActionType.SYNC_APOLLO_DATA, data)

export const versionCheckedAction = () =>
  createAction(AppActionType.VERSION_CHECKED)

export const overwriteBreadcrumbsAction = (
  crumbs: BreadcrumbsState['crumbs'],
) => createAction(AppActionType.OVERWRITE_BREADCRUMBS, { crumbs })
