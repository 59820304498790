import { TTSActionType } from '../actions/textToSpeechActions'
import { DEFAULT_VOICE_RATE, DEFAULT_VOICE_ID } from '../constants'
import { TextToSpeechState } from './reducerTypes'

export const defaultState: TextToSpeechState = {
  showInitialControls: true,
  isPaused: false,
  showSettingsMenu: false,
  voiceSpeed: DEFAULT_VOICE_RATE,
  voiceId: DEFAULT_VOICE_ID,
  readingPositions: {},
}

type Action =
  | ({ type: TTSActionType.SET_SHOW_INITIAL_CONTROLS } & Pick<
      TextToSpeechState,
      'showInitialControls'
    >)
  | ({ type: TTSActionType.SET_IS_PAUSED } & Pick<
      TextToSpeechState,
      'isPaused'
    >)
  | ({ type: TTSActionType.SET_SHOW_SETTINGS_MENU } & Pick<
      TextToSpeechState,
      'showSettingsMenu'
    >)

export function textToSpeechReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case TTSActionType.SET_SHOW_INITIAL_CONTROLS: {
      return {
        ...state,
        showInitialControls: action.showInitialControls,
      }
    }

    case TTSActionType.SET_IS_PAUSED: {
      return {
        ...state,
        isPaused: action.isPaused,
      }
    }

    case TTSActionType.SET_SHOW_SETTINGS_MENU: {
      return {
        ...state,
        showSettingsMenu: action.showSettingsMenu,
      }
    }

    default: {
      return state
    }
  }
}
