import { SortDirection } from 'react-virtualized'
import { DashboardActionType } from '../actions/dashboardActions'
import { DashboardState } from './reducerTypes'

export const STUDENTS_PAGE = 'studentsPage'
export const STUDENT_LOS_PAGE = 'studentLOsPage'

export const defaultState = {
  dashboardStudentInfoModalIsVisible: false,
  dashboardLOInfoModalIsVisible: false,
  dashboardStudentInfoModalLoId: '',
  tables: {
    [STUDENTS_PAGE]: {
      sortBy: 'name',
      sortDirection: SortDirection.ASC,
    },
    [STUDENT_LOS_PAGE]: {
      sortBy: 'title',
      sortDirection: SortDirection.ASC,
    },
  },
}

type Action = {
  type: DashboardActionType.TOGGLE_DASHBOARD_INFO_MODAL
  dashboardInfoModalType: DashboardActionType
  dashboardInfoModalIsVisible: DashboardState['dashboardLOInfoModalIsVisible']
  dashboardInfoModalLoId: DashboardState['dashboardStudentInfoModalLoId']
}

export function dashboardReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case DashboardActionType.TOGGLE_DASHBOARD_INFO_MODAL: {
      if (
        action.dashboardInfoModalType ===
        DashboardActionType.DASHBOARD_LO_INFO_MODAL
      ) {
        return {
          ...state,
          dashboardLOInfoModalIsVisible:
            action.dashboardInfoModalIsVisible ||
            !state.dashboardLOInfoModalIsVisible,
        }
      }
      return {
        ...state,
        dashboardStudentInfoModalIsVisible:
          action.dashboardInfoModalIsVisible ||
          !state.dashboardStudentInfoModalIsVisible,
        dashboardStudentInfoModalLoId: action.dashboardInfoModalLoId || '',
      }
    }

    default: {
      return state
    }
  }
}
