import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import {
  List,
  WHITE_COLOR,
  PRIMARY_COLOR,
  TEXT_BLACK_COLOR,
} from '@edwin-edu/ui-web-components'
import { useActions } from '../../hooks/useActions'
import {
  closeNavFlyoutAction,
  toggleNotificationsMenuAction,
} from '../../actions/navMenuActions'
import {
  READER_LO_PATH,
  NAV_MENU_WIDTH,
  SETTINGS_USER_SETTINGS,
  LIBRARY_PATH,
  MATH_TOOLS_PATH,
  NOTES_PATH,
  ADMIN_PATH,
} from '../../constants'
import OnlineStatus from '../OnlineStatus'
import FormattedMainMenuButton from './FormattedMainMenuButton'
import NotificationsDrawer from './NotificationsDrawer'
import HomeSVG from '../../assets/svg/icon-home.svg'
import CollectionsSVG from '../../assets/svg/collections/icon-collections-nav.svg'
import DashboardSVG from '../../assets/svg/icon-dashboard.svg'
import NotesSVG from '../../assets/svg/sidebar/icon-notes.svg'
import ToolsSVG from '../../assets/svg/sidebar/icon-tools.svg'
import HelpSVG from '../../assets/svg/sidebar/icon-help.svg'
import NotificationSVG from '../../assets/svg/sidebar/icon-notification.svg'
import PersonalizeSVG from '../../assets/svg/icon-personalize.svg'
import withOnClickOutside from '../../utils/OnClickOutside'
import LibrarySVG from '../../assets/svg/icon-library-large.svg'
import AdminSVG from '../../assets/svg/onboarding/admin-icon.svg'
import { NotificationsToast } from './NotificationToast'
import { authService } from '../../services/authentication'

const DASHBOARD = 'Dashboard'
const NOTIFICATION = 'Notification'

const NavMenu = () => {
  const [toggleNotificationsMenu, closeNavFlyout] = useActions([
    toggleNotificationsMenuAction,
    closeNavFlyoutAction,
  ])

  const {
    aMenuIsOpen,
    homeIsActive,
    collectionsIsActive,
    dashboardIsActive,
    toolsIsActive,
    notesIsActive,
    libraryIsActive,
    settingsIsActive,
    helpIsActive,
    notificationsMenuIsActive,
    adminIsActive,
  } = useSelector((state) => state.navMenu)

  const readerMatch = useRouteMatch(READER_LO_PATH)
  const isAdmin = authService.getIsAdmin()

  const closeNavFlyoutIfAMenuIsOpen = () => {
    if (aMenuIsOpen) closeNavFlyout()
  }

  return (
    <NavContainer handleClickOutside={closeNavFlyoutIfAMenuIsOpen}>
      <SkipToContent href={`#${readerMatch ? 'reader-iframe' : 'main'}`}>
        <FormattedMessage id="skip_to_main_content" />
      </SkipToContent>
      <StyledList role="menubar">
        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-home-btn"
            id="NavMenu_home"
            to="/"
            active={homeIsActive}
            tooltipPosition="right"
            white
            role="menuitem"
            aria-label="Home Button Icon"
          >
            <HomeSVG />
          </FormattedMainMenuButton>
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-library-btn"
            id="NavMenu_library"
            to={LIBRARY_PATH}
            active={libraryIsActive}
            tooltipPosition="right"
            white
            role="menuitem"
            aria-label="Library Icon"
          >
            <LibraryIcon />
          </FormattedMainMenuButton>
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-tools-btn"
            id="ReaderControls_mathTools"
            to={MATH_TOOLS_PATH}
            active={toolsIsActive}
            tooltipPosition="right"
            white
            role="menuitem"
            aria-label="Interactive Tools Icon"
          >
            <ToolsSVG />
          </FormattedMainMenuButton>
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-notes-btn"
            id="HomePage_yourNotes"
            to={NOTES_PATH}
            active={notesIsActive}
            tooltipPosition="right"
            white
            role="menuitem"
            aria-label="Notes Icon"
          >
            <NotesSVG />
          </FormattedMainMenuButton>
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-collections-btn"
            id="NavMenu_collections"
            to="/collections"
            active={collectionsIsActive}
            white
            tooltipPosition="right"
            role="menuitem"
            aria-label="Collections Icon"
          >
            <CollectionsSVG />
          </FormattedMainMenuButton>
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-dashboard-btn"
            id="NavMenu_dashboard"
            to="/dashboard/"
            active={dashboardIsActive}
            white
            tooltipPosition="right"
            role="menuitem"
            aria-label="Your Dashboards Icon"
          >
            <DashboardSVG />
          </FormattedMainMenuButton>
          <NotificationsToast notificationType={DASHBOARD} />
        </MainMenuItem>

        {isAdmin && (
          <MainMenuItem role="none">
            <FormattedMainMenuButton
              data-qa-id="nav-menu-admin-btn"
              id="NavMenu_admin"
              to={ADMIN_PATH}
              active={adminIsActive}
              white
              tooltipPosition="right"
              role="menuitem"
              aria-label="Admin Icon"
            >
              <AdminSVG />
            </FormattedMainMenuButton>
          </MainMenuItem>
        )}

        <MainMenuItem style={{ marginTop: 'auto' }} role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-notifications-btn"
            id="NavMenu_notifications"
            onClick={toggleNotificationsMenu}
            active={notificationsMenuIsActive}
            tooltipPosition="right"
            role="menuitem"
            aria-haspopup="true"
            aria-expanded={notificationsMenuIsActive}
            gray="true"
            aria-label="Notifications Icon"
          >
            <NotificationSVG />
          </FormattedMainMenuButton>
          <NotificationsToast notificationType={NOTIFICATION} />
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-help-btn"
            id="NavMenu_help"
            onClick={() =>
              window.open('https://help.edwin.app/', '_blank').focus()
            }
            active={helpIsActive}
            tooltipPosition="right"
            role="menuitem"
            gray="true"
            aria-label="Help Icon"
          >
            <HelpSVG />
          </FormattedMainMenuButton>
        </MainMenuItem>

        <MainMenuItem role="none">
          <FormattedMainMenuButton
            data-qa-id="nav-menu-settings-btn"
            id="NavMenu_settings"
            to={SETTINGS_USER_SETTINGS}
            active={settingsIsActive}
            color={PRIMARY_COLOR}
            tooltipPosition="right"
            role="menuitem"
            gray="true"
            aria-label="Settings Icon"
          >
            <PersonalizeSVG />
          </FormattedMainMenuButton>
        </MainMenuItem>
      </StyledList>

      <NotificationsDrawer notificationType={NOTIFICATION} />
      <OnlineStatus />
    </NavContainer>
  )
}

export default NavMenu

const NavContainerStyles = styled.nav`
  flex-direction: column;
  width: ${NAV_MENU_WIDTH};
  height: 100%;
  text-align: center;
  list-style: none;
  background: ${TEXT_BLACK_COLOR};
`

const NavContainer = withOnClickOutside(NavContainerStyles)

const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;
`
const MainMenuItem = styled.li`
  position: relative;
  &::before {
    content: none;
  }
`
const SkipToContent = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  &:focus {
    width: auto;
    height: auto;
    padding: 2px 20px 5px;
    border-radius: 0 15px 15px 0;
    overflow: visible;
    clip: auto;
    background: #003b61;
    color: ${WHITE_COLOR};
    font-weight: 600;
    z-index: 1;
  }
`
const LibraryIcon = styled(LibrarySVG)`
  margin: 0 0 4px 0;
  height: 26px;
`
