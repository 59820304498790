import { createAction } from './index'
import { DashboardState } from '../reducers/reducerTypes'

export enum DashboardActionType {
  DASHBOARD_STUDENT_INFO_MODAL = 'DASHBOARD_STUDENT_INFO_MODAL',
  DASHBOARD_LO_INFO_MODAL = 'DASHBOARD_LO_INFO_MODAL',
  TOGGLE_DASHBOARD_INFO_MODAL = 'EDWIN/DASHBOARD/INFO_MODAL/TOGGLE',
}

export const toggleDashboardInfoModalAction = (
  dashboardInfoModalIsVisible: DashboardState['dashboardLOInfoModalIsVisible'],
  dashboardInfoModalType: DashboardActionType,
  dashboardInfoModalLoId: DashboardState['dashboardStudentInfoModalLoId'],
) =>
  createAction(DashboardActionType.TOGGLE_DASHBOARD_INFO_MODAL, {
    dashboardInfoModalIsVisible,
    dashboardInfoModalType,
    dashboardInfoModalLoId,
  })
