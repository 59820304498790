import { ReaderiFrameActionType } from '../actions/readeriFrameActions'
import { ReaderIFrameState } from './reducerTypes'

export const defaultState: ReaderIFrameState = {
  scrollTop: {},
  showTopBar: true,
  tableOfContents: [],
  loaded: false,
  sidePanelWidth: 0,
}

type Action =
  | ({ type: ReaderiFrameActionType.STORE_HEADERS } & Pick<
      ReaderIFrameState,
      'tableOfContents'
    >)
  | { type: ReaderiFrameActionType.LOAD_IFRAME }
  | { type: ReaderiFrameActionType.IFRAME_LOADED }

export function readerIFrameReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case ReaderiFrameActionType.STORE_HEADERS: {
      return {
        ...state,
        tableOfContents: action.tableOfContents,
      }
    }

    case ReaderiFrameActionType.LOAD_IFRAME: {
      return {
        ...state,
        loaded: false,
      }
    }

    case ReaderiFrameActionType.IFRAME_LOADED: {
      return {
        ...state,
        loaded: true,
      }
    }

    default: {
      return state
    }
  }
}
