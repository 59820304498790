import { ReaderActionType } from '../actions/readerActions'
import { ReaderState } from './reducerTypes'

export const defaultState: ReaderState = {
  learningObjectId: null,
  enableQuery: false,
  selectedDocumentId: '',
  showScreenshotChoice: false,
  mathToolScreenShot: '',
}

type Action =
  | {
      type: ReaderActionType.LOAD_READER
      learningObjectId: ReaderState['learningObjectId']
    }
  | {
      type: ReaderActionType.TOGGLE_READER_SCREENSHOT_CHOICE
      value: ReaderState['mathToolScreenShot']
    }

export function readerReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case ReaderActionType.LOAD_READER: {
      return {
        ...state,
        learningObjectId: action.learningObjectId,
        enableQuery: false,
      }
    }

    case ReaderActionType.TOGGLE_READER_SCREENSHOT_CHOICE: {
      return {
        ...state,
        showScreenshotChoice: !state.showScreenshotChoice,
        mathToolScreenShot: action.value,
      }
    }

    default: {
      return state
    }
  }
}
