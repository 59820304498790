import styles from './SimpleToolTip.module.scss'
import React, { useState } from 'react'
import * as PropTypes from 'prop-types'

export const SimpleToolTip = ({ children, text, ...restProps }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
      {...restProps}
    >
      {children}
      <div className={isOpen ? styles.tooltipOpen : styles.tooltip}>{text}</div>
    </div>
  )
}

SimpleToolTip.propTypes = {
  children: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
}
