import { createGlobalStyle } from 'styled-components'

// Load custom fonts
// Styled-components injectGlobal would be better, but causes flickering on dev environment
const fonts = `
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro200.ttf')});
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro200italic.ttf')});
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro300.ttf')});
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro300italic.ttf')});
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansProRegular.ttf')});
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansProItalic.ttf')});
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro600.ttf')});
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro600italic.ttf')});
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro700.ttf')});
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: Source Sans Pro;
    src: url(${require('./assets/fonts/SourceSansPro700italic.ttf')});
    font-weight: bold;
    font-style: italic;
  }
`
const css = document.createElement('style')
css.setAttribute('type', 'text/css')
css.appendChild(document.createTextNode(fonts))
document.head.appendChild(css)

// Note: Only do this once, for fonts and <html>/<body> styles
const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    color: #4A4A4A;
    box-sizing: border-box;
  }
  body {
    min-height: inherit;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }
  h1 {
    font-size: 5.57rem;
    font-weight: 300;
  }
  h3 {
    /* TODO: Based on Library's search result title styles. May need to change later for global scope.*/
    font-size: 1.86rem;
    font-weight: 600;
    color: #606060;
  }
  button {
    font-family: inherit;
  }
  strong {
    font-weight: 700;
  }
  a:not([disabled]), button:not([disabled]) {
    cursor: pointer;
  }
  
  // todo: disable this (text-sizing testing for DEV-5145)
  //* { 
  //    line-height: 1.5 !important;
  //    letter-spacing: 0.12em !important;
  //    word-spacing: 0.16em !important;
  //}
  //p {
  //    margin-bottom: 2em !important;
  //}
`
export default GlobalStyles
