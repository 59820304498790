import { eventChannel } from 'redux-saga'
import { delay, take, put, call, fork, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  AuthActionType,
  clearUserDataAction,
  startSessionAction,
  endSessionAction,
  storeUserDataAction,
  logoutAction,
} from '../actions/auth'
import { authService } from '../services/authentication'
import {
  CONFIRM_EMAIL_PATH,
  EDWIN_AUTH_PATH,
  RESET_LINK_EXPIRED_PATH,
  HOME_PATH,
  PASSAUTH_PATH,
  DASHBOARD_STUDENT_CLASS_PATH,
  DASHBOARD_CLASS_PATH,
  DASHBOARD_PATH,
  DASHBOARD_JOIN_PATH,
} from '../constants'
import validator from 'validator'

const authCredentialChannel = eventChannel((emitter) => {
  authService.on('auth_credential', (tokens) => emitter({ tokens }))
  return () => authService.off('auth_credential', () => undefined)
})

const authStateChangeChannel = eventChannel((emitter) => {
  authService.on('state_change_no_user', (user) => emitter({ user }))
  return () => authService.off('state_change_no_user', () => undefined)
})

export function* initFirebaseAuth(isPopout = false) {
  window.logger.log('init auth firebase saga called')
  yield call(authService.init)
  const { user } = yield take(authStateChangeChannel)

  const pathname = window?.location?.pathname

  if (!user) {
    if (pathname === RESET_LINK_EXPIRED_PATH) {
      console.log('non-logged-in forgot-password path')
    } else if (pathname === PASSAUTH_PATH) {
      console.log('not-logged-in auth path')
    } else if (pathname !== CONFIRM_EMAIL_PATH) {
      if (!isPopout) {
        // if email & course code passed from usermgt, set in auth session to be used to show password login, join course & redirect to dashboard
        authService.resetPassedUrlLoginParams()
        const urlParams = new URLSearchParams(window.location.search)
        const urlEmail = urlParams.get('email')
        const urlCourseCode = urlParams.get('code')
        if (urlEmail && validator.isEmail(urlEmail) && urlCourseCode) {
          authService.setPassedUrlLoginParams(urlEmail, urlCourseCode)
        }
        yield put(
          push(EDWIN_AUTH_PATH, {
            prevSearch: window.location.search,
          }),
        )
      }
    }
  }

  if (user) {
    if (pathname === RESET_LINK_EXPIRED_PATH) {
      console.log('logged-in forgot-password path')
    } else if (pathname === CONFIRM_EMAIL_PATH || pathname === PASSAUTH_PATH) {
      yield put(push(HOME_PATH))
    }
  }

  yield all([
    fork(watchAuthCredentials),
    fork(initWatchTokenExpired),
    fork(watchLogout, isPopout),
  ])
}

/** Watchers */

function* watchAuthCredentials() {
  window.logger.log('watch auth credentials firebase saga called')

  while (true) {
    const { tokens } = yield take(authCredentialChannel)

    window.logger.log('received new firebase auth credentials:', tokens)

    authService.startSession(tokens)

    yield put(storeUserDataAction(tokens.idToken))
    yield put(startSessionAction())

    // edwinAuth email and courseCode passed from usermgt, send to custom page to join course & redirect
    const { email, courseCode } = authService.getPassedUrlLoginParams()
    if (email && validator.isEmail(email) && courseCode) {
      window.logger.log(
        'user had passed url login params & logged in. join course & redirect',
      )
      yield put(push(DASHBOARD_JOIN_PATH))
      return
    }

    // sso auth passed from usermgt, look for actions
    const pathname = document?.location?.pathname
    if (pathname === PASSAUTH_PATH) {
      const params = new URLSearchParams(document?.location?.search)
      const action = params.get('action')
      const data = params.get('data')
      if (!action) {
        yield put(push(HOME_PATH))
      }
      // get student or teacher dashboard path
      if (action === 'dashboard-redirect') {
        if (!data) {
          yield put(push(DASHBOARD_PATH))
        } else if (authService.getIsTeacherOrAdmin()) {
          yield put(push(DASHBOARD_CLASS_PATH.replace(':courseId', data)))
        } else {
          yield put(
            push(DASHBOARD_STUDENT_CLASS_PATH.replace(':courseId', data)),
          )
        }
      } else {
        yield put(push(HOME_PATH))
      }
    }
  }
}

function* watchLogout(isPopout = false) {
  window.logger.log('watch auth logout firebase saga called')
  while (true) {
    yield take(AuthActionType.LOGOUT)
    window.logger.log('auth logout firebase saga action taken')
    yield put(clearUserDataAction())
    yield put(endSessionAction())
    yield call(authService.endSession)

    // unregister service worker (if exists)
    if (navigator && navigator.serviceWorker) {
      yield call(async () => {
        const registrations = await navigator.serviceWorker.getRegistrations()
        for (const registration of registrations) {
          await registration.unregister()
        }
      })
    }

    if (!isPopout) {
      yield call(authService.logout)
    }
  }
}

function* initWatchTokenExpired() {
  window.logger.log('auth init watch token expired called')
  while (true) {
    yield take(AuthActionType.SESSION_STARTED)
    yield call(watchTokenExpired)
    window.logger.log('auth stopped watching for expired token')
  }
}

function* watchTokenExpired() {
  window.logger.log('auth watch token expired called')
  while (true) {
    yield delay(30000) // 30s in ms
    // if online && token is expired, refresh token
    if (
      window.navigator.onLine &&
      (authService.tokenNeedsRefresh() || authService.tokenIsExpired())
    ) {
      const { error } = yield call(authService.refreshToken)
      if (error) {
        console.warn('error refreshing access token. logging out.')
        yield put(logoutAction())
        return
      }
    }
  }
}
