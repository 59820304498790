import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { shellReducer } from './shellReducer'
import { authenticationReducer } from './authenticationReducer'
import { textToSpeechReducer } from './textToSpeechReducer'
import { readerReducer } from './readerReducer'
import { navMenuReducer } from './navMenuReducer'
import { readerControlsReducer } from './readerControlsReducer'
import { notesPanelReducer } from './notesPanelReducer'
import { readerIFrameReducer } from './readerIFrameReducer'
import { appReducer } from './appReducer'
import { breadcrumbsReducer } from './breadcrumbsReducer'
import { librarySearchReducer } from './librarySearchReducer'
import { collectionsGridReducer } from './collectionsGridReducer'
import { collectionReducer } from './collectionReducer'
import { libraryBrowseReducer } from './libraryBrowseReducer'
import { libraryReducer } from './libraryReducer'
import { mathToolsReducer } from './mathToolsReducer'
import { dashboardReducer } from './dashboardReducer'
import { userFiltersReducer } from './userFiltersReducer'
import { userCurriculumReducer } from './userCurriculumReducer'
import { collectionsSearchReducer } from './collectionsSearchReducer'

export default function createReducer(history: History) {
  return combineReducers({
    edwin: appReducer,
    shell: shellReducer,
    router: connectRouter(history),
    authentication: authenticationReducer,
    readerIFrame: readerIFrameReducer,
    dashboard: dashboardReducer,
    textToSpeech: textToSpeechReducer,
    libraryPage: libraryReducer,
    reader: readerReducer,
    readerControls: readerControlsReducer,
    notesPanel: notesPanelReducer,
    mathTools: mathToolsReducer,
    navMenu: navMenuReducer,
    breadcrumbs: breadcrumbsReducer,
    search: librarySearchReducer,
    libraryBrowse: libraryBrowseReducer,
    collectionsSearch: collectionsSearchReducer,
    collectionsGrid: collectionsGridReducer,
    collectionPage: collectionReducer,
    userFilters: userFiltersReducer,
    curriculum: userCurriculumReducer,
  })
}
