import React, { Fragment, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import messages from '../../data/messages'
import { selectAuthorized } from '../../selectors/index'
import { getBrowserLanguage } from '@edwin-edu/ui-web-components'
import { reactChildrenPropType } from '../../utils/propTypes'
import { getLocaleFromData } from '../../graphql/user/locale'
import GET_LOCALE from '../../graphql/user/queries/getLocale.graphql'

export default function LocaleProvider({ children }) {
  const client = useApolloClient()
  const isAuthorized = useSelector(selectAuthorized)
  const [locale, setLocale] = useState(getBrowserLanguage())

  // Only query for locale if authorized. Need to use client.watchQuery for 'cache-then-network'
  useEffect(() => {
    if (isAuthorized) {
      // prettier-ignore
      client
        .watchQuery({ query: GET_LOCALE })
        .subscribe(({ data }) => {
          setLocale(getLocaleFromData(data))
        })
    }
  }, [isAuthorized])

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={locale}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  )
}
LocaleProvider.propTypes = {
  children: reactChildrenPropType,
}
