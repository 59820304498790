import { ACTIVE_COLOR } from '@edwin-edu/ui-web-components'

let lastFocusedElement = null

export const initTabNavigation = () => {
  const css = document.createElement('style')
  css.type = 'text/css'
  css.innerHTML = `
      .focused{
        outline: solid 2px ${ACTIVE_COLOR};
        outline-offset: -2px;
      }
    `
  document.head.appendChild(css)
  window.addEventListener('keyup', (e) => {
    if (e.keyCode === 9) {
      document.activeElement.classList.add('focused')
      lastFocusedElement = document.activeElement
    } else {
      if (lastFocusedElement !== null) {
        lastFocusedElement.classList.remove('focused')
        lastFocusedElement = null
      }
    }
  })
  window.addEventListener(
    'blur',
    (e) => {
      if (lastFocusedElement !== null) {
        lastFocusedElement.classList.remove('focused')
        lastFocusedElement = null
      }
    },
    true,
  )
}
