import React, { FC, lazy, PropsWithChildren, Suspense, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { Route } from 'react-router'
import { useActions } from '../hooks/useActions'
import {
  HOME_PATH,
  EDWIN_AUTH_PATH,
  RESET_LINK_EXPIRED_PATH,
  PASSAUTH_PATH,
} from '../constants'
import { LoadingPage } from '../containers/LoadingPage'
import { authService } from '../services/authentication'
import { useReduxSelector } from '../reducers/reducerTypes'
import { pushAction } from '../actions/pushAction'

const LazyAuthPage = lazy(() => import('../containers/auth/AuthPage'))
const LazyPassAuthPage = lazy(() => import('../containers/auth/PassAuthPage'))
const LazyLinkExpiredPage = lazy(
  () => import('../containers/auth/ResetLinkExpired'),
)

export const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const client = useApolloClient()
  const authorized = useReduxSelector(
    (state) => state.authentication.authorized,
  )
  const user = useReduxSelector((state) => state.authentication.userData)

  const isLoggedIn = authorized && user.userId

  const push = useActions(pushAction)

  useEffect(() => {
    if (isLoggedIn) {
      if (window.location.pathname === EDWIN_AUTH_PATH) {
        const shareLink = window.sessionStorage.getItem('shareLink')
        push(shareLink ?? HOME_PATH)
        if (shareLink) {
          window.sessionStorage.removeItem('shareLink')
        }
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!authorized && !authService.getUserId()) {
      client.clearStore()
    }
  }, [authorized])

  if (isLoggedIn) {
    return <>{children}</>
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Route path={PASSAUTH_PATH} render={() => <LazyPassAuthPage />} />
      {/* @ts-ignore ReactNode types are incompatible due to dynamic import? */}
      <Route
        path={RESET_LINK_EXPIRED_PATH}
        render={() => <LazyLinkExpiredPage />}
      />
      {/* @ts-ignore ReactNode types are incompatible due to dynamic import? */}
      <Route path={EDWIN_AUTH_PATH} render={() => <LazyAuthPage />} />
    </Suspense>
  )
}
