import { Dispatch } from 'redux'
import { createAction } from './index'
import { LibraryPageState } from '../reducers/reducerTypes'

export enum LibraryActionType {
  TOGGLE_COLLECTION_MENU = 'EDWIN/LIBRARY/TOGGLE_COLLECTION_MENU',
  GET_SHARABLE_LESSON_LINK = 'GET_SHARABLE_LESSON_LINK',
  RESET_SHARABLE_LESSON_LINK_ANIMATION = 'RESET_SHARABLE_LESSON_LINK_ANIMATION',
}

export const toggleCollectionMenuAction = (
  id: LibraryPageState['collectionsMenuOpen'],
) => createAction(LibraryActionType.TOGGLE_COLLECTION_MENU, { id })

// STATIC
export const resetSharableLessonLinkAnimation = (id: string) =>
  createAction(LibraryActionType.RESET_SHARABLE_LESSON_LINK_ANIMATION, { id })

// ASYNC
export const getSharableLessonLink =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: LibraryActionType.GET_SHARABLE_LESSON_LINK,
      id,
    })

    setTimeout(() => {
      dispatch(resetSharableLessonLinkAnimation(id))
    }, 2200)
  }
