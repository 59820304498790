import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectIsOnline } from '../selectors'
import OfflineSVG from '../assets/svg/icon-offline.svg'

class OnlineStatus extends PureComponent {
  static propTypes = {
    isOnline: PropTypes.bool.isRequired,
  }

  render() {
    return !this.props.isOnline && <OfflineSVG />
  }
}

const mapStateToProps = (state) => ({
  isOnline: selectIsOnline(state),
})

export default connect(mapStateToProps)(OnlineStatus)
