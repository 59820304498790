import { ApolloClient, ApolloLink, split } from '@apollo/client'
import { config } from './services/config'
import { authLink } from './apollo/authLink'
import { onError } from '@apollo/client/link/error'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { createUploadLink } from 'apollo-upload-client'
import { authService } from './services/authentication'

export function configureApollo(cache) {
  const uri = config.get('app.services.apollo.graphqlEndpoint')

  // eslint-disable-next-line no-unused-vars
  const batchHttpLink = new BatchHttpLink({
    uri,
    batchMax: 7,
  })

  const isFile = (value) =>
    (typeof File !== 'undefined' && value instanceof File) ||
    (typeof Blob !== 'undefined' && value instanceof Blob)

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        window.logger.warn(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`,
        ),
      )
    }
    if (networkError) {
      window.logger.warn(`[Network error]: ${networkError}`)
      const { statusCode, result } = networkError
      if (statusCode === 401 && result?.code === 'INVALID_ACCESS_TOKEN') {
        authService.endSession()
        authService.logout()
      }
    }
  })

  const removeTypenameLink = removeTypenameFromVariables()

  const isUpload = ({ variables }) => Object.values(variables).some(isFile)

  const batchExemptOperations = [
    'getUser',
    'getRecentlyViewed',
    'searchLearningObjects',
    'searchLearningObjectCounts',
    'getUserCurriculumByCmsIds',
    'getAllLearningObjectsByIds',
    'getAllLearningObjectsByCmsIds',
  ]

  const shouldNotBatch = (operation) => {
    // Check if the operation is an upload
    if (isUpload(operation)) {
      return true
    }

    if (batchExemptOperations.includes(operation.operationName)) {
      return true
    }

    // Return false for other operations, which will be batched
    return false
  }

  const link = ApolloLink.from([
    authLink,
    removeTypenameLink,
    split(
      shouldNotBatch,
      ApolloLink.from([
        errorLink,
        createUploadLink({
          uri,
          headers: { 'Apollo-Require-Preflight': 'true' },
          fetchOptions: { mode: 'cors' },
        }), // terminal link. Dedup & Batch will not be used if isUpload
      ]),
    ),
    errorLink,
    batchHttpLink,
  ])

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  }

  return new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
    defaultOptions,
  })
}
