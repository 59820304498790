import firebase from 'firebase/app'
import { AuthActionType } from '../actions/auth'
import { AuthenticationState, AuthUserData } from './reducerTypes'

const defaultUserState: AuthUserData = {
  // from token
  expirationTime: null,
  authTime: null,
  issuedAtTime: null,
  signInProvider: null,
  signInSecondFactor: null,
  // from token.claims
  name: null,
  picture: null,
  userId: null,
  profile: null,
  email: null,
  emailVerified: false,
  // derived from data

  // not used, but in token.claims:
  // iss: null,
  // aud: null,
  // iat: null,
  // exp: null,
  // firebase.identities: {}
}

export const defaultState: AuthenticationState = {
  authorized: false,
  userData: { ...defaultUserState },
}

export const mapTokenToUserData = (token: firebase.auth.IdTokenResult) => {
  if (!token || !token.claims) {
    throw new Error('Invalid user token. ' + JSON.stringify(token))
  }

  return {
    // from token
    expirationTime: token.expirationTime,
    authTime: token.authTime,
    issuedAtTime: token.issuedAtTime,
    signInProvider: token.signInProvider,
    signInSecondFactor: token.signInSecondFactor,
    // from token.claims
    name: token.claims.name,
    givenName: token.claims.givenName,
    familyName: token.claims.familyName,
    picture: token.claims.picture,
    userId: token.claims['https://api.edwin.app/user/id'],
    profile: token.claims['https://api.edwin.app/app/profile'],
    email: token.claims.email,
    emailVerified: token.claims.emailVerified,
  }
}

type Action =
  | { type: AuthActionType.SESSION_STARTED }
  | { type: AuthActionType.SESSION_ENDED }
  | { type: AuthActionType.STORE_USER_DATA; token: firebase.auth.IdTokenResult }
  | { type: AuthActionType.CLEAR_USER_DATA }
  | { type: AuthActionType.OFFLINE_AUTH_ERROR }

export function authenticationReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case AuthActionType.SESSION_STARTED: {
      return {
        ...state,
        authorized: true,
      }
    }

    case AuthActionType.SESSION_ENDED: {
      return {
        ...state,
        authorized: false,
      }
    }

    case AuthActionType.STORE_USER_DATA: {
      return {
        ...state,
        userData: mapTokenToUserData(action.token),
      }
    }

    case AuthActionType.CLEAR_USER_DATA: {
      return {
        ...state,
        userData: { ...defaultUserState },
      }
    }

    case AuthActionType.OFFLINE_AUTH_ERROR: {
      return {
        ...state,
      }
    }

    default: {
      return state
    }
  }
}
