/* New Text to Speech Actions */
import { createAction } from './index'
import { TextToSpeechState } from '../reducers/reducerTypes'

export enum TTSActionType {
  SET_SHOW_INITIAL_CONTROLS = 'EDWIN/TTS/SET_SHOW_INITIAL_CONTROLS',
  SET_IS_PAUSED = 'EDWIN/TTS/SET_IS_PAUSED',
  SET_SHOW_SETTINGS_MENU = 'EDWIN/TTS/SET_SHOW_SETTINGS_MENU',
}

export const setShowInitialControlsAction = (
  showInitialControls: TextToSpeechState['showInitialControls'],
) =>
  createAction(TTSActionType.SET_SHOW_INITIAL_CONTROLS, { showInitialControls })

export const setIsPausedAction = (isPaused: TextToSpeechState['isPaused']) =>
  createAction(TTSActionType.SET_IS_PAUSED, { isPaused })

export const setShowSettingsMenuAction = (
  showSettingsMenu: TextToSpeechState['showSettingsMenu'],
) => createAction(TTSActionType.SET_SHOW_SETTINGS_MENU, { showSettingsMenu })
