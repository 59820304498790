import get from 'lodash/get'
import has from 'lodash/has'

export const config = {
  get: (key) => {
    // eslint-disable-next-line no-undef
    const configVal = get(EDWIN.CONFIG, key)

    if (typeof configVal === 'undefined') {
      throw new Error(
        `EdwinConfigError. Config value does not exist for key ${key}`,
      )
    }

    return configVal
  },
  has: (key) => {
    // eslint-disable-next-line no-undef
    return has(EDWIN.CONFIG, key)
  },
}
