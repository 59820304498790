import { Location } from 'history'
import { createAction } from './index'
import { ReaderState } from '../reducers/reducerTypes'

export enum ReaderActionType {
  LOAD_READER = 'LOAD_READER',
  TOGGLE_READER_SCREENSHOT_CHOICE = 'EDWIN/READER/SCREENSHOT_CHOICE/TOGGLE',
  READER_PAGE_LOADED = 'EDWIN/READER/LOADED',
}

export const loadReaderAction = (
  learningObjectId: ReaderState['learningObjectId'],
) => createAction(ReaderActionType.LOAD_READER, { learningObjectId })

export const toggleReaderScreenshotChoiceAction = (
  value: ReaderState['mathToolScreenShot'],
) => createAction(ReaderActionType.TOGGLE_READER_SCREENSHOT_CHOICE, { value })

export const readerPageLoadedAction = (payload: {
  location: Location<{
    openReaderPanel: boolean
  }>
}) => createAction(ReaderActionType.READER_PAGE_LOADED, { payload })
