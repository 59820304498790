import { AppActionType } from '../actions/appActions'
import { mapValues } from 'lodash'
import { UserFiltersState } from './reducerTypes'

export const defaultUserFiltersState: UserFiltersState = {
  provinces: [],
  grades: [],
  subjects: {},
  isTeacher: false,
  isInitialized: false,
}

const mapProvinces = (provinces: UserFiltersState['provinces']) =>
  (provinces || []).map((province) => province + '')

const mapGrades = (grades: UserFiltersState['grades']) =>
  (grades || []).filter((grade) => grade).map((grade) => grade + '')

const mapSubjects = (gradesSubjects: UserFiltersState['subjects']) =>
  mapValues(gradesSubjects || {}, (subjects) =>
    subjects.map((subject) => subject + ''),
  )

type Action = {
  type: AppActionType.SYNC_APOLLO_DATA
  gpsIsLoading: UserFiltersState['isInitialized']
} & Pick<UserFiltersState, 'grades' | 'provinces' | 'subjects'>

export function userFiltersReducer(
  state = defaultUserFiltersState,
  action: Action,
) {
  switch (action.type) {
    case AppActionType.SYNC_APOLLO_DATA: {
      const { type, ...actionData } = action

      const { grades, subjects, provinces, gpsIsLoading } = actionData

      return {
        ...state,
        provinces: mapProvinces(provinces),
        grades: mapGrades(grades),
        subjects: mapSubjects(subjects),
        isInitialized: !gpsIsLoading,
      }
    }
    default:
      return state
  }
}
