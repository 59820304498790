import React, { ReactNode, useRef, MouseEvent, TouchEvent } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import Transition, { TransitionStatus } from 'react-transition-group/Transition'
import {
  ClickButton,
  DROPDOWN_SHADOW,
  fadeIn,
  fadeOut,
  modalZoomFadeIn,
  modalZoomFadeOut,
  ERROR_COLOR,
  SCROLLBAR_STYLES_HOVER,
} from '@edwin-edu/ui-web-components'
import CloseSVG from '../assets/svg/icon-x-blue.svg'
import { SITE_MIN_WIDTH, SITE_MIN_HEIGHT } from '../constants'
import { useOnClickOutside } from '../hooks/useOnClickOutside'

export const MODAL_THEME_DARK = 'modal-theme-dark'

type ModalProps = {
  children: ReactNode
  isOpen?: boolean
  handleClose?: () => void
  insertCloseButton?: boolean
  boxStyle?: object
  width?: string
  padding?: string
  height?: string
  minWidth?: string
  minHeight?: string
  overlayHeight?: string
  margin?: string
  noOverflow?: boolean
  theme?: string
  overlapNav?: boolean
  errorBorder?: boolean
  scroll?: boolean
  top?: number
  left?: number
  opacity?: number
  onExited?: () => void
  zIndex?: number
}

const Modal = ({
  children,
  isOpen,
  handleClose,
  insertCloseButton,
  boxStyle,
  width,
  padding,
  height,
  minWidth,
  minHeight,
  noOverflow,
  theme,
  overlapNav,
  errorBorder,
  scroll,
  top,
  left,
  overlayHeight,
  margin,
  opacity,
  onExited,
  zIndex,
}: ModalProps) => {
  const modalBoxRef = useRef<HTMLDivElement>(null)

  useOnClickOutside<HTMLDivElement>(modalBoxRef, handleClose)

  const stopPropagation = (e: TouchEvent | MouseEvent) => {
    e.stopPropagation()
  }

  return ReactDOM.createPortal(
    <Transition
      in={isOpen}
      timeout={300}
      onExited={onExited}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <Container noOverflow={noOverflow} overlapNav={overlapNav}>
          <Overlay
            onMouseDown={handleClose}
            state={state}
            isOpen={isOpen}
            theme={theme}
            opacity={opacity}
            zIndex={zIndex}
            overlayHeight={overlayHeight}
          >
            <ModalBox
              ref={modalBoxRef}
              style={boxStyle}
              onMouseDown={stopPropagation}
              state={state}
              isOpen={isOpen}
              width={width}
              height={height}
              padding={padding}
              minWidth={minWidth}
              minHeight={minHeight}
              theme={theme}
              errorBorder={errorBorder}
              scroll={scroll}
              top={top}
              left={left}
              margin={margin}
            >
              {insertCloseButton && (
                <CloseButton
                  data-qa-id="modal-close-btn"
                  aria-label="Modal Close Button"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </CloseButton>
              )}
              {React.Children.map(children, (child) => child)}
            </ModalBox>
          </Overlay>
        </Container>
      )}
    </Transition>,
    document.getElementById('modal-root') as Element,
  )
}

Modal.focusFirstElement = (element?: string) => {
  // keyboard accessibility: focus the first element when modal is opened
  setTimeout(() => {
    const elements = (
      document.getElementById('modal-root') as HTMLElement
    ).getElementsByTagName(element || 'button')
    if (elements.length) (elements[0] as HTMLElement).focus()
  }, 300)
}

export default Modal

const CloseIcon = styled(CloseSVG)`
  width: 20px;
  height: 20px;
  g {
    stroke-width: 1px;
  }
`
const CloseButton = styled(ClickButton)`
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  display: block;
  padding: 5px;
`
const Overlay = styled.div<
  { state: TransitionStatus } & Pick<
    ModalProps,
    'isOpen' | 'opacity' | 'zIndex' | 'overlayHeight'
  >
>`
  width: 100%;
  min-width: ${SITE_MIN_WIDTH}px;
  height: ${(p) => p.overlayHeight || '100%'};
  min-height: ${SITE_MIN_HEIGHT};
  position: absolute;
  display: flex;
  background: ${(p) =>
    p.opacity
      ? `rgba(0, 0, 0, ${p.opacity})`
      : p.theme === MODAL_THEME_DARK
      ? 'rgba(0, 0, 0, 0.75)'
      : 'rgba(0, 0, 0, 0.6)'};
  pointer-events: ${(p) => (p.isOpen ? 'initial' : 'none')};
  z-index: ${(p) => p.zIndex || 'unset'};
  ${(p) =>
    p.state === 'entering' &&
    css`
      animation: 250ms ease-out ${fadeIn};
    `}
  ${(p) =>
    p.state === 'exiting' &&
    css`
      animation: 150ms ease-out ${fadeOut};
      opacity: 0;
    `}
`
const ModalBox = styled.div<
  { state: TransitionStatus } & Pick<
    ModalProps,
    | 'isOpen'
    | 'top'
    | 'left'
    | 'padding'
    | 'width'
    | 'height'
    | 'minWidth'
    | 'minHeight'
    | 'errorBorder'
    | 'scroll'
    | 'margin'
  >
>`
  position: ${(p) => (p.top ? 'absolute' : 'unset')};
  top: ${(p) => (p.top ? `${p.top}px` : 'unset')};
  left: ${(p) => (p.left ? `${p.left}px` : 'unset')};
  flex: 0 1 auto;
  margin: ${(p) => (p.margin ? `${p.margin}` : 'auto')};
  padding: ${(p) => p.padding || '20px'};
  min-width: ${(p) => p.minWidth || '200px'};
  min-height: ${(p) => p.minHeight || 'auto'};
  width: ${(p) => p.width || 'auto'};
  height: ${(p) => p.height || 'auto'};
  max-width: calc(100% - 150px);
  max-height: 100%;
  background: ${(p) =>
    p.theme === MODAL_THEME_DARK ? 'rgba(0,0,0,0.6)' : '#fff'};
  border-radius: 3px;
  cursor: default;
  z-index: 100;
  border: ${(p) => p.errorBorder && `1px solid ${ERROR_COLOR};`};
  ${DROPDOWN_SHADOW};
  overflow-y: ${(p) => p.scroll && 'scroll'};
  ${SCROLLBAR_STYLES_HOVER};
  transform: scale(1) translateY(0);
  ${(p) =>
    p.state === 'entering' &&
    css`
      animation: 200ms ease-out ${modalZoomFadeIn};
    `}
  ${(p) =>
    p.state === 'exiting' &&
    css`
      animation: 100ms ease-in ${modalZoomFadeOut};
      opacity: 0;
    `}
`
const Container = styled.div<Pick<ModalProps, 'noOverflow' | 'overlapNav'>>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: ${(p) => (p.noOverflow ? 'hidden' : 'auto')};
  z-index: ${(p) => (p.overlapNav ? '20' : 'auto')};
`
