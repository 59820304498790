import {
  useUpdateNotificationsShouldAlertMutation,
  useUpdateNotificationsViewedTimeMutation,
  useUpdateNewUserDashboardToastClickedMutation,
} from '../../types/graphql'
import { authService } from '../../services/authentication'

export const useUpdateNotificationsLastViewed = () => {
  const [mutate] = useUpdateNotificationsViewedTimeMutation()

  return async () => {
    const notificationsViewedTime = new Date().toISOString()
    const id = authService.getUserId()
    if (!id) {
      window.logger.error('no user id in useUpdateNotificationsLastViewed')
      return
    }

    return mutate({
      variables: {
        notificationsViewedTime,
      },
      optimisticResponse: {
        updateUser: {
          id,
          notificationsViewedTime,
          __typename: 'User',
        },
      },
    })
  }
}

export const useUpdateNotificationsShouldAlert = () => {
  const [mutate] = useUpdateNotificationsShouldAlertMutation()

  return async (notificationsShouldAlert: boolean) => {
    const id = authService.getUserId()
    if (!id) {
      window.logger.error('no user id in useUpdateNotificationsLastViewed')
      return
    }

    return mutate({
      variables: {
        notificationsShouldAlert,
      },
      optimisticResponse: {
        updateUser: {
          id,
          notificationsShouldAlert,
          __typename: 'User',
        },
      },
    })
  }
}

export const useUpdateNewUserDashboardToastClicked = () => {
  const [mutate] = useUpdateNewUserDashboardToastClickedMutation()

  return async (newUserDashboardToastClicked: boolean) => {
    const id = authService.getUserId()
    if (!id) {
      window.logger.error('no user id in useUpdateNotificationsLastViewed')
      return
    }

    return mutate({
      variables: {
        newUserDashboardToastClicked,
      },
      optimisticResponse: {
        updateUser: {
          id,
          newUserDashboardToastClicked,
          __typename: 'User',
        },
      },
    })
  }
}
