import { createAction } from './index'
import { ReaderIFrameState } from '../reducers/reducerTypes'

export enum ReaderiFrameActionType {
  STORE_HEADERS = 'EDWIN/READER/IFRAME/STORE_HEADERS',
  LOAD_IFRAME = 'EDWIN/READER/IFRAME/LOAD',
  IFRAME_LOADED = 'EDWIN/READER/IFRAME/LOADED',
}

export const storeHeadersAction = (
  tableOfContents: ReaderIFrameState['tableOfContents'],
) => createAction(ReaderiFrameActionType.STORE_HEADERS, { tableOfContents })

export const loadIFrameAction = () =>
  createAction(ReaderiFrameActionType.LOAD_IFRAME)

export const iFrameLoadedAction = () =>
  createAction(ReaderiFrameActionType.IFRAME_LOADED)
