import { push } from 'connected-react-router'
import { useActions } from '../hooks/useActions'

/**
 * This is a dumb alias so that I don't have to always type `import { push as pushAction } from 'connected-react-router'`
 */
export const pushAction = push

export const usePushAction = () => {
  return useActions(pushAction)
}
