import { get } from 'lodash'
import { AppActionType } from '../actions/appActions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import {
  DASHBOARD_PATH,
  COLLECTIONS_PATH,
  READER_PATH,
  SITE_MIN_WIDTH,
  DASHBOARD_MIN_WIDTH,
  COLLECTIONS_MIN_WIDTH,
  READER_MIN_WIDTH,
  HOME_MIN_WIDTH,
} from '../constants'
import { AppState } from './reducerTypes'

export const defaultState: AppState = {
  initialized: false,
  online: get(window, ['navigator', 'onLine']) || false,
  resetCache: false,
  appError: false,
  locationChangedSinceVersionCheck: false,
  isUpdateModalOpen: false,
  rootScrollable: false,
}

interface Location {
  pathname: string
}

interface Payload {
  location: Location
}

export interface AppAction {
  type: string
  payload: Payload
  error?: Error
}

const defaultAction: AppAction = {
  type: '',
  payload: {
    location: {
      pathname: '',
    },
  },
}

export function appReducer(
  state: AppState = defaultState,
  action: AppAction = defaultAction,
) {
  switch (action.type) {
    case AppActionType.EDWIN_INIT: {
      return {
        ...state,
        initialized: true,
      }
    }

    case AppActionType.ONLINE: {
      return {
        ...state,
        online: true,
      }
    }

    case AppActionType.OFFLINE: {
      return {
        ...state,
        online: false,
      }
    }

    case AppActionType.VERSION_CHECKED: {
      return {
        ...state,
        locationChangedSinceVersionCheck: false,
      }
    }

    case LOCATION_CHANGE: {
      const {
        payload: {
          location: { pathname },
        },
      } = action

      const newState = {
        ...state,
        locationChangedSinceVersionCheck: true,
      }

      if (
        matchPath(pathname, {
          path: DASHBOARD_PATH,
          exact: false,
        })
      ) {
        return {
          ...newState,
          appRootMinWidth: DASHBOARD_MIN_WIDTH,
        }
      } else if (
        matchPath(pathname, {
          path: READER_PATH,
          exact: false,
        })
      ) {
        return {
          ...newState,
          appRootMinWidth: READER_MIN_WIDTH,
        }
      } else if (
        matchPath(pathname, {
          path: COLLECTIONS_PATH,
          exact: false,
        })
      ) {
        return {
          ...newState,
          appRootMinWidth: COLLECTIONS_MIN_WIDTH,
        }
      } else if (
        matchPath(pathname, {
          path: '/',
          exact: true,
        })
      ) {
        return {
          ...newState,
          appRootMinWidth: HOME_MIN_WIDTH,
        }
      } else {
        return {
          ...newState,
          appRootMinWidth: SITE_MIN_WIDTH,
        }
      }
    }

    default:
      return state
  }
}
