/*
 * This is a wrapper of react-router-dom's Link component, but it destructures additional props
 * so React doesn't try to pass them to the dom tag <a>
 */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import warning from 'warning'
import { Tooltip } from '@edwin-edu/ui'
import {
  buttonAndLinkStyles,
  buttonStyles,
  stripButtonStyleProps,
} from '@edwin-edu/ui-web-components'
import { tooltipPositions } from '../utils/propTypes'

class Link extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool,
    focused: PropTypes.bool,
    focusStyle: PropTypes.string,
    tooltipText: PropTypes.string,
    tooltipPosition: tooltipPositions,
    tooltipMargin: PropTypes.number,
    title: PropTypes.string,
  }

  componentDidMount() {
    const { tooltipText, tooltipPosition } = this.props

    warning(
      !(
        typeof tooltipPosition !== 'undefined' &&
        typeof tooltipText === 'undefined'
      ),
      'tooltipPosition specified, but no tooltipText in Link',
    )
  }

  render() {
    const nonButtonProps = stripButtonStyleProps(this.props)
    const {
      isActive,
      focused,
      focusStyle,
      tooltipText,
      tooltipPosition,
      tooltipMargin,
      title,
      ...restProps
    } = nonButtonProps // eslint-disable-line no-unused-vars

    if (tooltipText) {
      return (
        <Tooltip label={tooltipText} placement={tooltipPosition ?? 'bottom'}>
          <RouterLink aria-label={title} {...restProps} />
        </Tooltip>
      )
    } else {
      return <RouterLink aria-label={title} {...restProps} />
    }
  }
}

export default styled(Link)`
  ${buttonAndLinkStyles};
`

export const LinkButton = styled(Link)`
  ${buttonAndLinkStyles};
  ${buttonStyles};
`

// todo: refactor this to remove complexity, and utilize the active state of Router: https://reacttraining.com/react-router/web/example/custom-link
