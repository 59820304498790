import { getBrowserLanguage } from '@edwin-edu/ui-web-components'
import { authService } from '../../services/authentication'
import {
  GetLocaleQuery,
  useGetLocaleQuery,
  UserLocale,
  useUpdateLocaleMutation,
} from '../../types/graphql'

// util
export const getLocaleFromData = (data: GetLocaleQuery | undefined): string =>
  (data?.user?.locale ?? getBrowserLanguage()).toUpperCase()

// query
export const useLocale = (): string => {
  const { data } = useGetLocaleQuery()
  return getLocaleFromData(data)
}

// mutation
export const useSetLocale = () => {
  const [setLocaleMutation] = useUpdateLocaleMutation()

  return (val: string | undefined) => {
    const variables = { locale: (val as UserLocale) ?? '' }

    setLocaleMutation({
      variables,
      optimisticResponse: {
        updateUser: {
          ...variables,
          id: authService.getUserId() as string,
          __typename: 'User',
        },
      },
    })
  }
}
