import PropTypes from 'prop-types'

// noinspection JSUnusedGlobalSymbols
export const coords = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
})

export const tooltipPositions = PropTypes.oneOf([
  'top',
  'right',
  'bottom',
  'left',
])

// noinspection JSUnusedGlobalSymbols
export const apolloClient = PropTypes.shape({
  cache: PropTypes.shape({
    readQuery: PropTypes.func.isRequired,
    writeQuery: PropTypes.func.isRequired,
    readFragment: PropTypes.func.isRequired,
    writeFragment: PropTypes.func.isRequired,
    writeData: PropTypes.func.isRequired,
  }).isRequired,
}).isRequired

export const reactChildrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
])

export const collectionPropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  teacherOnly: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  studentDescription: PropTypes.string,
  teacherDescription: PropTypes.string,
  teachingNotes: PropTypes.string,
  assessment: PropTypes.string,
  skillsCompetencies: PropTypes.string,
  curriculum: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      __typename: PropTypes.string,
    }),
  ),
})

// LOMetadata learningObject from client-api-service schema.graphql, not user-specific learningObject data
export const learningObjectPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  cmsId: PropTypes.string,
  nelsonId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  year: PropTypes.string,
  teacherOnly: PropTypes.bool,
  mediaTypes: PropTypes.arrayOf(PropTypes.string),
  authors: PropTypes.arrayOf(PropTypes.string),
  teacherTools: PropTypes.arrayOf(PropTypes.string),
  geographicAreas: PropTypes.arrayOf(PropTypes.string),
  relatedLearningObjects: PropTypes.arrayOf(PropTypes.string),
  relatedCollections: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.string,
  publisher: PropTypes.string,
  orderId: PropTypes.string,
  unitNumber: PropTypes.string,
  chapterNumber: PropTypes.string,
  startPage: PropTypes.string,
  endPage: PropTypes.string,
  expirationDate: PropTypes.string,
  curriculum: PropTypes.string,
  focuses: PropTypes.arrayOf(PropTypes.string),
  topics: PropTypes.arrayOf(PropTypes.string),
  overallExpectations: PropTypes.arrayOf(PropTypes.string),
  specificExpectations: PropTypes.arrayOf(PropTypes.string),
  keywords: PropTypes.arrayOf(PropTypes.string),
  skillDevelopmentLearningObjects: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  successCriteriaQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
    }),
  ),
  provinceId: PropTypes.string,
  gradeId: PropTypes.string,
  subjectId: PropTypes.string,
})
