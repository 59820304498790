import logdown from 'logdown'

// export const reactComponentLogger = logdown('react-component', {
//   markdown: false,
//   prefixColor: '#cc99cc',
// })
export const authServiceLogger = logdown('auth-services', {
  markdown: false,
  prefixColor: '#cc8f68',
})
export const errorLogger = logdown('error', {
  markdown: false,
  prefixColor: '#cc001d',
})
