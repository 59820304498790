import React from 'react'
import classNames from 'classnames'
import { El, ElProps } from '@edwin-edu/ui'
import styles from './Page.module.scss'
import { FCWC } from '../types/fcwc'

export const Page: FCWC<ElProps> = (props) => {
  const { className, ...rest } = props
  return (
    <El as="div" className={classNames(styles.page, className)} {...rest} />
  )
}
