import { createSelector } from 'reselect'
import { Gps, RootState, SubjectsObject } from '../reducers/reducerTypes'
import { get, map, flatten } from 'lodash'
import { SITE_MIN_WIDTH } from '../constants'

// app
export const selectAppRootMinWidth = (state: RootState) =>
  get(state, ['edwin', 'appRootMinWidth']) || SITE_MIN_WIDTH

// auth
export const selectAuthorized = (state: RootState) =>
  get(state, ['authentication', 'authorized']) || false

export const selectUserId = (state: RootState) =>
  get(state, ['authentication', 'userData', 'userId']) || ''

export const selectUserEmail = (state: RootState) =>
  get(state.authentication, ['userData', 'email']) || ''

export const selectIsOnline = () => navigator.onLine || false

export const selectBreadcrumbsMap = (state: RootState) =>
  get(state.breadcrumbs, 'crumbs') || []

// wired selectors
export const selectUserProvinces = (state: RootState) =>
  get(state, ['userFilters', 'provinces']) || []

export const selectUserSubjectsByGrades = (state: RootState) =>
  get(state, ['userFilters', 'subjects']) || []

const mapSubjectsToGpsArray = (
  provinces: string[],
  subjectsByGrades: SubjectsObject = {},
): Gps[] => {
  // user only has one province, for now
  const provinceId = provinces[0]?.toString()
  return flatten(
    map(subjectsByGrades, (subjects, gradeId) => {
      return subjects.map((subject) => {
        return {
          provinceId,
          gradeId,
          subjectId: subject?.toString(),
        }
      })
    }),
  )
}

export const selectUserGpsArray = createSelector(
  selectUserProvinces,
  selectUserSubjectsByGrades,
  mapSubjectsToGpsArray,
)

// only used in useUserCurriculum
export const selectUserCurriculumState = (state: RootState) =>
  get(state, ['curriculum']) || {}
