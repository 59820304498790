import { LibraryActionType } from '../actions/libraryActions'
import { LibraryPageState } from './reducerTypes'

export const defaultState: LibraryPageState = {
  collectionsMenuOpen: null,
}

type Action = {
  type: LibraryActionType.TOGGLE_COLLECTION_MENU
  id: LibraryPageState['collectionsMenuOpen']
}

export function libraryReducer(state = defaultState, action: Action) {
  switch (action.type) {
    // new edwin-core
    case LibraryActionType.TOGGLE_COLLECTION_MENU: {
      // close current
      if (action.id === state.collectionsMenuOpen) {
        return {
          ...state,
          collectionsMenuOpen: null,
        }
      }
      // open new
      return {
        ...state,
        collectionsMenuOpen: action.id,
      }
    }

    default: {
      return state
    }
  }
}
