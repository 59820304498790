import { MathToolsActionType } from '../actions/mathToolsActions'
import { MathToolsState } from './reducerTypes'

const defaultState: MathToolsState = {
  fractionTrayIsCollapsed: false,
  patternBlocksIsCollapsed: false,
  algebraTilesIsCollapsed: false,
  spinnerPanelIsCollapsed: false,
  integerChipsIsCollapsed: false,
  baseTenBlocksIsCollapsed: false,
  barModelIsCollapsed: false,
  whiteboardIsCollapsed: false,
  infoScreenIsOpen: false,
  savedFractionStripsState: {},
  savedFractionStripsNotes: {},
  desmosPanelIsCollapsed: false,
  desmosPanelType: '',
  screenShotIsAnimating: false,
  screenShotIsDisabled: false,
}

type Action =
  | { type: MathToolsActionType.RESIZE_FRACTION_STRIPS }
  | { type: MathToolsActionType.RESIZE_PATTERN_BLOCKS }
  | { type: MathToolsActionType.RESIZE_ALGEBRA_TILES }
  | { type: MathToolsActionType.RESIZE_SPINNER }
  | { type: MathToolsActionType.TOGGLE_DISABLE_SCREENSHOT }
  | { type: MathToolsActionType.RESIZE_INTEGER_CHIPS }
  | { type: MathToolsActionType.RESIZE_BASE_TEN_BLOCKS }
  | { type: MathToolsActionType.RESIZE_BAR_MODEL }
  | { type: MathToolsActionType.RESIZE_WHITEBOARD }
  | {
      type: MathToolsActionType.SAVE_FRACTION_STRIPS
      stripsState: MathToolsState['savedFractionStripsState']
      notesState: MathToolsState['savedFractionStripsNotes']
    }
  | { type: MathToolsActionType.TOGGLE_INFO_SCREEN }
  | { type: MathToolsActionType.RESIZE_DESMOS_PANEL }
  | {
      type: MathToolsActionType.SET_DESMOS_PANEL_TYPE
      panelType: MathToolsState['desmosPanelType']
    }
  | {
      type: MathToolsActionType.SCREENSHOT_IS_ANIMATING
      isAnimating: MathToolsState['screenShotIsAnimating']
    }

export function mathToolsReducer(state = defaultState, action: Action) {
  switch (action.type) {
    // fraction strips
    case MathToolsActionType.RESIZE_FRACTION_STRIPS: {
      return {
        ...state,
        fractionTrayIsCollapsed: !state.fractionTrayIsCollapsed,
      }
    }
    // pattern blocks
    case MathToolsActionType.RESIZE_PATTERN_BLOCKS: {
      return {
        ...state,
        patternBlocksIsCollapsed: !state.patternBlocksIsCollapsed,
      }
    }
    // algebra tiles
    case MathToolsActionType.RESIZE_ALGEBRA_TILES: {
      return {
        ...state,
        algebraTilesIsCollapsed: !state.algebraTilesIsCollapsed,
      }
    }
    // spinner
    case MathToolsActionType.RESIZE_SPINNER: {
      return {
        ...state,
        spinnerPanelIsCollapsed: !state.spinnerPanelIsCollapsed,
      }
    }
    case MathToolsActionType.TOGGLE_DISABLE_SCREENSHOT: {
      return {
        ...state,
        screenShotIsDisabled: !state.screenShotIsDisabled,
      }
    }
    // integer chips
    case MathToolsActionType.RESIZE_INTEGER_CHIPS: {
      return {
        ...state,
        integerChipsIsCollapsed: !state.integerChipsIsCollapsed,
      }
    }
    // base ten blocks
    case MathToolsActionType.RESIZE_BASE_TEN_BLOCKS: {
      return {
        ...state,
        baseTenBlocksIsCollapsed: !state.baseTenBlocksIsCollapsed,
      }
    }
    case MathToolsActionType.RESIZE_BAR_MODEL: {
      return {
        ...state,
        barModelIsCollapsed: !state.barModelIsCollapsed,
      }
    }
    case MathToolsActionType.RESIZE_WHITEBOARD: {
      return {
        ...state,
        whiteboardIsCollapsed: !state.whiteboardIsCollapsed,
      }
    }
    case MathToolsActionType.SAVE_FRACTION_STRIPS: {
      return {
        ...state,
        savedFractionStripsState: action.stripsState,
        savedFractionStripsNotes: action.notesState,
      }
    }
    case MathToolsActionType.TOGGLE_INFO_SCREEN: {
      return {
        ...state,
        infoScreenIsOpen: !state.infoScreenIsOpen,
      }
    }
    // desmos panel
    case MathToolsActionType.RESIZE_DESMOS_PANEL: {
      return {
        ...state,
        desmosPanelIsCollapsed: !state.desmosPanelIsCollapsed,
      }
    }
    case MathToolsActionType.SET_DESMOS_PANEL_TYPE: {
      return {
        ...state,
        desmosPanelType: action.panelType,
      }
    }
    // animations
    case MathToolsActionType.SCREENSHOT_IS_ANIMATING: {
      return {
        ...state,
        screenShotIsAnimating: action.isAnimating,
      }
    }
    default: {
      return state
    }
  }
}
