import { UserCurriculumActionType } from '../actions/userCurriculumActions'
import { CurriculumState } from './reducerTypes'

export const defaultState = {
  gpsArray: [],
  userCurriculum: [],
  marketingSubjects: [],
  timestamp: 0,
}

type Action = {
  type: UserCurriculumActionType.STORE_USER_CURRICULUM
} & CurriculumState

export function userCurriculumReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case UserCurriculumActionType.STORE_USER_CURRICULUM: {
      return {
        ...state,
        gpsArray: action.gpsArray,
        userCurriculum: action.userCurriculum,
        marketingSubjects: action.marketingSubjects,
        timestamp: action.timestamp,
      }
    }

    default: {
      return state
    }
  }
}
