import React from 'react'
import ErrorPage from './ErrorPage'
import {
  renderErrorType,
  renderErrorMessage,
  renderErrorActionMessage,
} from './SharedComponents'
import ErrorIllustrationSVG from '../../assets/svg/icon-error-illustration-blue.svg'
import { RoundButton } from '../../components/Button'
import { handleRetry } from '../../apollo/store'
import { FormattedMessage } from 'react-intl'
import { Button } from '@edwin-edu/ui'

// NOTE: Locale is not available in this component. If adding text, refactor to add LocaleProvider.
const SiteErrorPage = () => {
  return (
    <ErrorPage pageIconSVG={<ErrorIllustrationSVG />}>
      {renderErrorType('Error 100')}
      {renderErrorMessage('Application Error')}
      {renderErrorActionMessage(<p>Something went wrong.</p>)}

      <RoundButton onClick={handleRetry}>Try again</RoundButton>
      <Button style={{ width: 'fit-content' }} onClick={handleRetry}>
        <FormattedMessage id="tryAgain" />
      </Button>
    </ErrorPage>
  )
}

export default SiteErrorPage
