let windowRef

export default function openPopOut(path, width = 800, height = 600) {
  const strWindowFeatures = `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${width},height=${height}`
  if (windowRef) {
    windowRef.close()
  }
  windowRef = window.open(
    `${window.location.origin}${path}`,
    `edwin-popout`,
    strWindowFeatures,
  )
}
