import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import styled from 'styled-components'
import { PRIMARY_COLOR, ClickButton } from '@edwin-edu/ui-web-components'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { valid, compare } from 'semver'
import { FormattedMessage } from 'react-intl'
import { config } from '../services/config'
import { selectIsOnline } from '../selectors'
import { useActions } from '../hooks/useActions'
import { versionCheckedAction } from '../actions/appActions'
import GET_APP_VERSION from '../graphql/app/getAppVersion.graphql'
import Modal from './Modal'
import UpdateVersionSVG from '../assets/svg/icon-new-version.svg'

function UpdateModal() {
  const reloadSite = () => {
    window.location.reload()
  }

  return (
    <Modal isOpen={true} width={'370px'} height={'217px'}>
      <UpdateVersion>
        <UpdateVersionIcon />
        <UpdateInfo>
          <span>edwin</span>&nbsp;
          <FormattedMessage id="HomePage_needsUpdate" />
        </UpdateInfo>
        <ConfirmButton clickHandler={reloadSite} round>
          <FormattedMessage id="HomePage_versionUpdateButton" />
        </ConfirmButton>
      </UpdateVersion>
    </Modal>
  )
}

const CheckVersion = () => {
  const client = useApolloClient()
  const isOnline = useSelector(selectIsOnline)
  const locationChangedSinceVersionCheck = useSelector(
    (state) => state.edwin.locationChangedSinceVersionCheck,
  )
  const versionChecked = useActions(versionCheckedAction)
  const [isUpdateModalOpen, setModalIsOpen] = useState(false)

  console.log(
    'locationChangedSinceVersionCheck',
    locationChangedSinceVersionCheck,
  )

  useEffect(() => {
    const checkVersionAsync = async () => {
      const { data } = await client.query({
        query: GET_APP_VERSION,
        fetchPolicy: 'network-only',
      })

      const installedVersion = config.get('app.build.version')
      const latestVersion = get(data, 'appVersion.version')
      // window.logger.log(
      //   `Edwin version: latest ${latestVersion} | installed ${installedVersion}`,
      // )

      if (latestVersion) {
        versionChecked()
        window.logger.log(
          `v latest ${latestVersion} | installed ${installedVersion}`,
        )
        // window.logger.log(`valid(installedVersion) ` + valid(installedVersion))
        // window.logger.log(`valid(latestVersion) ` + valid(latestVersion))

        // if (valid(installedVersion) && valid(latestVersion)) {
        //   window.logger.log(`compare(latestVersion, installedVersion): ` + compare(latestVersion, installedVersion))
        // }

        if (
          valid(installedVersion) &&
          valid(latestVersion) &&
          compare(latestVersion, installedVersion) > 0
        ) {
          window.logger.log(
            `v latest ${latestVersion} | installed ${installedVersion}`,
          )
          window.logger.log('refreshing to install latest version')
          setModalIsOpen(true)
        }
      } else {
        window.logger.warn(
          'Failed to get latest app version from graphQL',
          data,
        )
      }
    }

    if (isOnline && locationChangedSinceVersionCheck) {
      // noinspection JSIgnoredPromiseFromCall
      checkVersionAsync()
    }
  }, [isOnline, locationChangedSinceVersionCheck])

  if (isUpdateModalOpen) {
    return <UpdateModal />
  }
  return null
}

export default CheckVersion

const UpdateVersion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px;
`
const UpdateVersionIcon = styled(UpdateVersionSVG)`
  display: flex;
`
const UpdateInfo = styled.div`
  display: flex;
  color: ${PRIMARY_COLOR};
  margin: 20px 0 8px 0;
  font-size: 18px;
  span {
    font-weight: 900;
  }
`
const ConfirmButton = styled(ClickButton)`
  height: 29px;
  padding: 4px 15px 6px;
  text-transform: inherit;
`
