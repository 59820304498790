import update from 'immutability-helper'
import { CollectionActionType } from '../actions/collectionsActions'
import { CollectionsGridState } from './reducerTypes'

export const allCollections = {
  labelId: 'CollectionsFilterDropDown_allCollections',
  value: '',
}
export const newest = {
  label: 'Newest',
  value: 'newest',
}

export const createTagOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
})

export const defaultCollectionsGridState = {
  createCardIsVisible: false,
  isSavingCollection: false,
  selectedSubject: allCollections,
  showFilterDropdown: false,
  sortMethod: newest,
  sortDropDownIsOpen: false,
  // new
  tagOptions: [
    createTagOption('Tag One'),
    createTagOption('Tag Two'),
    createTagOption('A Very Loooooooooooooong Tag'),
  ],
  tagValue: undefined,
  cards: {},
}

type Action =
  | { type: CollectionActionType.TOGGLE_CREATE_CARD }
  | { type: CollectionActionType.TOGGLE_SAVING_COLLECTION }
  | { type: CollectionActionType.TOGGLE_FILTER_COLLECTION_DROPDOWN }
  | {
      type: CollectionActionType.SELECT_FILTER_COLLECTION_SUBJECT
      selectedSubject: CollectionsGridState['selectedSubject']
    }
  | { type: CollectionActionType.TOGGLE_SORTMETHOD_COLLECTION_DROPDOWN }
  | {
      type: CollectionActionType.SELECT_SORTMETHOD_COLLECTION_SUBJECT
      sortMethod: CollectionsGridState['sortMethod']
    }
  | {
      type: CollectionActionType.FILTER_CHANGE_TAGS
      payload: { tagValue: CollectionsGridState['tagValue'] }
    }

export function collectionsGridReducer(
  state = defaultCollectionsGridState,
  action: Action,
) {
  switch (action.type) {
    // collections grid actions
    case CollectionActionType.TOGGLE_CREATE_CARD: {
      return {
        ...state,
        createCardIsVisible: !state.createCardIsVisible,
      }
    }

    case CollectionActionType.TOGGLE_SAVING_COLLECTION: {
      return {
        ...state,
        isSavingCollection: !state.isSavingCollection,
      }
    }

    case CollectionActionType.TOGGLE_FILTER_COLLECTION_DROPDOWN: {
      return {
        ...state,
        showFilterDropdown: !state.showFilterDropdown,
      }
    }

    case CollectionActionType.SELECT_FILTER_COLLECTION_SUBJECT: {
      return update(state, {
        selectedSubject: { $set: action.selectedSubject },
        showFilterDropdown: { $set: false },
      })
    }

    case CollectionActionType.TOGGLE_SORTMETHOD_COLLECTION_DROPDOWN: {
      return {
        ...state,
        sortDropDownIsOpen: !state.sortDropDownIsOpen,
      }
    }

    case CollectionActionType.SELECT_SORTMETHOD_COLLECTION_SUBJECT: {
      return update(state, {
        sortMethod: { $set: action.sortMethod },
        sortDropDownIsOpen: { $set: false },
      })
    }

    // new v2 actions
    case CollectionActionType.FILTER_CHANGE_TAGS: {
      return {
        ...state,
        tagValue: action.payload.tagValue,
      }
    }

    default: {
      return state
    }
  }
}
