import { ReaderControlsActionType } from '../actions/readerControlsActions'
import { ReaderControlsState } from './reducerTypes'

const defaultPanelsState = {
  aSlideOutPanelIsVisible: false,
  assessmentPanelIsVisible: false,
  notesPanelIsVisible: false,
  relatedLearningObjPanelIsVisible: false,
  educatorToolsPanelIsVisible: false,
  searchPanelIsVisible: false,
  fractionStripsPanelIsVisible: false,
  patternBlocksPanelIsVisible: false,
  algebraTilesPanelIsVisible: false,
  spinnerPanelIsVisible: false,
  integerChipsPanelIsVisible: false,
  baseTenBlocksPanelIsVisible: false,
  barModelPanelIsVisible: false,
  mathToolsPanelIsVisible: false,
  whiteboardPanelIsVisible: false,
  desmosCalcPanelIsVisible: false,
  mathToolIndexToSaveToGoogle: false,
  scrollToSection: undefined,
}

const defaultDropDownsState = {
  aDropDownIsVisible: false,
  citationsDropDownIsVisible: false,
  collectionsDropDownIsVisible: false,
  printDropDownIsVisible: false,
}

const defaultModalsState = {
  infoModalIsVisible: false,
}

const defaultState = {
  ...defaultPanelsState,
  ...defaultDropDownsState,
  ...defaultModalsState,
  collectionInputIsVisible: false,
  citationLinkCopied: false,
}

type Action =
  | { type: ReaderControlsActionType.TOGGLE_CITATIONS_DROPDOWN }
  | { type: ReaderControlsActionType.TOGGLE_COLLECTIONS_DROPDOWN }
  | { type: ReaderControlsActionType.TOGGLE_PRINT_DROPDOWN }
  | { type: ReaderControlsActionType.TOGGLE_MATH_TOOLS_PANEL }
  | { type: ReaderControlsActionType.OPEN_MATH_TOOLS_PANEL }
  | { type: ReaderControlsActionType.CLOSE_MATH_TOOLS_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_CITATION_LINK_COPIED }
  | { type: ReaderControlsActionType.HIDE_ALL_DROPDOWNS }
  | { type: ReaderControlsActionType.TOGGLE_SELF_ASSESSMENT_PANEL }
  | { type: ReaderControlsActionType.OPEN_SELF_ASSESSMENT_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_RELATED_LEARNING_OBJ_PANEL }
  | {
      type: ReaderControlsActionType.OPEN_RELATED_LEARNING_OBJ_PANEL
      scrollTo: ReaderControlsState['scrollToSection']
    }
  | { type: ReaderControlsActionType.TOGGLE_NOTES_PANEL }
  | { type: ReaderControlsActionType.OPEN_NOTES_PANEL }
  | {
      type: ReaderControlsActionType.SAVE_MATHTOOL_TO_GOOGLE
      mathToolIndex: ReaderControlsState['mathToolIndexToSaveToGoogle']
    }
  | { type: ReaderControlsActionType.TOGGLE_EDUCATOR_TOOLS_PANEL }
  | { type: ReaderControlsActionType.OPEN_EDUCATOR_TOOLS_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_SEARCH_PANEL }
  | { type: ReaderControlsActionType.OPEN_SEARCH_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_FRACTION_STRIPS_PANEL }
  | { type: ReaderControlsActionType.OPEN_FRACTION_STRIPS_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_PATTERN_BLOCKS_PANEL }
  | { type: ReaderControlsActionType.OPEN_PATTERN_BLOCKS_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_ALGEBRA_TILES_PANEL }
  | { type: ReaderControlsActionType.OPEN_ALGEBRA_TILES_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_SPINNER_PANEL }
  | { type: ReaderControlsActionType.OPEN_SPINNER_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_INTEGER_CHIPS_PANEL }
  | { type: ReaderControlsActionType.OPEN_INTEGER_CHIPS_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_BASE_TEN_BLOCKS_PANEL }
  | { type: ReaderControlsActionType.OPEN_BASE_TEN_BLOCKS_PANEL }
  | { type: ReaderControlsActionType.TOGGLE_BAR_MODEL_PANEL }
  | { type: ReaderControlsActionType.OPEN_BAR_MODEL_PANEL }
  | { type: ReaderControlsActionType.OPEN_DESMOS_PANEL }
  | { type: ReaderControlsActionType.CLOSE_DESMOS_PANEL }
  | { type: ReaderControlsActionType.HIDE_ALL_PANELS }
  | { type: ReaderControlsActionType.TOGGLE_COLLECTION_INPUT }
  | { type: ReaderControlsActionType.HIDE_ALL_MODALS }
  | { type: ReaderControlsActionType.TOGGLE_INFO_MODAL; selectedPanel: string }
  | { type: ReaderControlsActionType.TOGGLE_WHITEBOARD_PANEL }

export function readerControlsReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case ReaderControlsActionType.TOGGLE_CITATIONS_DROPDOWN: {
      return {
        ...state,
        ...defaultDropDownsState,
        aDropDownIsVisible: !state.citationsDropDownIsVisible,
        citationsDropDownIsVisible: !state.citationsDropDownIsVisible,
      }
    }

    case ReaderControlsActionType.TOGGLE_COLLECTIONS_DROPDOWN: {
      return {
        ...state,
        ...defaultDropDownsState,
        aDropDownIsVisible: !state.collectionsDropDownIsVisible,
        collectionsDropDownIsVisible: !state.collectionsDropDownIsVisible,
      }
    }

    case ReaderControlsActionType.TOGGLE_PRINT_DROPDOWN: {
      return {
        ...state,
        ...defaultDropDownsState,
        aDropDownIsVisible: !state.printDropDownIsVisible,
        printDropDownIsVisible: !state.printDropDownIsVisible,
      }
    }

    case ReaderControlsActionType.TOGGLE_MATH_TOOLS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        mathToolsPanelIsVisible: !state.mathToolsPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_MATH_TOOLS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        mathToolsPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.CLOSE_MATH_TOOLS_PANEL: {
      return {
        ...state,
        ...defaultDropDownsState,
        aDropDownIsVisible: false,
        mathToolsPanelIsVisible: false,
      }
    }

    case ReaderControlsActionType.TOGGLE_CITATION_LINK_COPIED: {
      return {
        ...state,
        citationLinkCopied: !state.citationLinkCopied,
      }
    }

    case ReaderControlsActionType.HIDE_ALL_DROPDOWNS: {
      return {
        ...state,
        ...defaultDropDownsState,
      }
    }

    case ReaderControlsActionType.TOGGLE_SELF_ASSESSMENT_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.assessmentPanelIsVisible,
        assessmentPanelIsVisible: !state.assessmentPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_SELF_ASSESSMENT_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        assessmentPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_RELATED_LEARNING_OBJ_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.relatedLearningObjPanelIsVisible,
        relatedLearningObjPanelIsVisible:
          !state.relatedLearningObjPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_RELATED_LEARNING_OBJ_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        relatedLearningObjPanelIsVisible: true,
        scrollToSection: action.scrollTo,
      }
    }

    case ReaderControlsActionType.TOGGLE_NOTES_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.notesPanelIsVisible,
        notesPanelIsVisible: !state.notesPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_NOTES_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        notesPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.SAVE_MATHTOOL_TO_GOOGLE: {
      return {
        ...state,
        mathToolIndexToSaveToGoogle: action.mathToolIndex,
      }
    }

    case ReaderControlsActionType.TOGGLE_EDUCATOR_TOOLS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.educatorToolsPanelIsVisible,
        educatorToolsPanelIsVisible: !state.educatorToolsPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_EDUCATOR_TOOLS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        educatorToolsPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_SEARCH_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.searchPanelIsVisible,
        searchPanelIsVisible: !state.searchPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_SEARCH_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        searchPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_FRACTION_STRIPS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.fractionStripsPanelIsVisible,
        fractionStripsPanelIsVisible: !state.fractionStripsPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_FRACTION_STRIPS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        fractionStripsPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_PATTERN_BLOCKS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.patternBlocksPanelIsVisible,
        patternBlocksPanelIsVisible: !state.patternBlocksPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_PATTERN_BLOCKS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        patternBlocksPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_ALGEBRA_TILES_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.algebraTilesPanelIsVisible,
        algebraTilesPanelIsVisible: !state.algebraTilesPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_ALGEBRA_TILES_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        algebraTilesPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_SPINNER_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.spinnerPanelIsVisible,
        spinnerPanelIsVisible: !state.spinnerPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_SPINNER_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        spinnerPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_INTEGER_CHIPS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.integerChipsPanelIsVisible,
        integerChipsPanelIsVisible: !state.integerChipsPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_INTEGER_CHIPS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        integerChipsPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_BASE_TEN_BLOCKS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.baseTenBlocksPanelIsVisible,
        baseTenBlocksPanelIsVisible: !state.baseTenBlocksPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_BASE_TEN_BLOCKS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        baseTenBlocksPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.TOGGLE_BAR_MODEL_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: !state.barModelPanelIsVisible,
        barModelPanelIsVisible: !state.barModelPanelIsVisible,
      }
    }
    case ReaderControlsActionType.OPEN_BAR_MODEL_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        barModelPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.OPEN_DESMOS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: true,
        desmosCalcPanelIsVisible: true,
      }
    }

    case ReaderControlsActionType.CLOSE_DESMOS_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        aSlideOutPanelIsVisible: false,
        desmosCalcPanelIsVisible: false,
      }
    }

    case ReaderControlsActionType.HIDE_ALL_PANELS: {
      return {
        ...state,
        ...defaultPanelsState,
      }
    }

    case ReaderControlsActionType.TOGGLE_COLLECTION_INPUT: {
      return {
        ...state,
        collectionInputIsVisible: !state.collectionInputIsVisible,
      }
    }

    case ReaderControlsActionType.HIDE_ALL_MODALS: {
      return {
        ...state,
        ...defaultModalsState,
      }
    }

    case ReaderControlsActionType.TOGGLE_INFO_MODAL: {
      return {
        ...state,
        ...defaultPanelsState,
        infoModalIsVisible: !state.infoModalIsVisible,
        searchPanelIsVisible: action.selectedPanel === 'searchPanel',
        educatorToolsPanelIsVisible: action.selectedPanel === 'educatorTools',
      }
    }

    case ReaderControlsActionType.TOGGLE_WHITEBOARD_PANEL: {
      return {
        ...state,
        ...defaultPanelsState,
        whiteboardPanelIsVisible: !state.whiteboardPanelIsVisible,
      }
    }

    default: {
      return state
    }
  }
}
