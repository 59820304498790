import { createAction } from './index'
import { push } from 'connected-react-router'
import { LocationDescriptorObject } from 'history'
import {
  CollectionPageState,
  CollectionsGridState,
} from '../reducers/reducerTypes'

export enum CollectionActionType {
  TOGGLE_CREATE_CARD = 'EDWIN/COLLECTION/TOGGLE_CREATE_CARD',
  TOGGLE_SAVING_COLLECTION = 'EDWIN/COLLECTION/TOGGLE_SAVING_COLLECTION',
  TOGGLE_FILTER_COLLECTION_DROPDOWN = 'EDWIN/COLLECTION/TOGGLE_FILTER_DROPDOWN',
  SELECT_FILTER_COLLECTION_SUBJECT = 'EDWIN/COLLECTION/SELECT_FILTER_SUBJECT',
  TOGGLE_SORTMETHOD_COLLECTION_DROPDOWN = 'EDWIN/COLLECTION/TOGGLE_SORTMETHOD_DROPDOWN',
  SELECT_SORTMETHOD_COLLECTION_SUBJECT = 'EDWIN/COLLECTION/SELECT_SORTMETHOD_SUBJECT',
  SINGLE_TOGGLE_EDIT = 'EDWIN/SINGLE_COLLECTION/TOGGLE_EDIT',
  EXPAND_COLLECTION_DETAIL = 'EDWIN/SINGLE_COLLECTION/EXPAND_COLLECTION_DETAIL',
  CLEAR_EXPANDING_ITEM_ID = 'EDWIN/SINGLE_COLLECTION/CLEAR_EXPANDING_ITEM_ID',
  SINGLE_TOGGLE_DROPDOWN = 'EDWIN/SINGLE_COLLECTION/TOGGLE_DROPDOWN',
  SCROLL_TO_BOTTOM_OF_LIST = 'EDWIN/SINGLE_COLLECTION/SCROLL_TO_BOTTOM',
  TOGGLE_EDIT_MODAL = 'COLLECTIONS/GRID/SINGLE_COLLECTION/TOGGLE/EDIT_MODAL',
  FILTER_CHANGE_TAGS = 'COLLECTIONS/GRID/FILTER_CHANGE_TAGS',
  TOGGLE_ADD_ITEMS_MODAL = 'COLLECTIONS/SINGLE_COLLECTION/ADD_ITEMS_MODAL/TOGGLE',
  SET_COLLECTION_LAST_VISIBLE_LOCATION = 'COLLECTIONS/SINGLE_COLLECTION/LAST_VISIBLE_LOCATION/SET',
}

// collections grid actions
export const toggleCreateCardAction = () =>
  createAction(CollectionActionType.TOGGLE_CREATE_CARD)
export const toggleSavingCollectionAction = () =>
  createAction(CollectionActionType.TOGGLE_SAVING_COLLECTION)

export const toggleCollectionsFilterDropDownAction = () =>
  createAction(CollectionActionType.TOGGLE_FILTER_COLLECTION_DROPDOWN)

export const selectCollectionFilterSubjectAction = (
  selectedSubject: CollectionsGridState['selectedSubject'],
) =>
  createAction(CollectionActionType.SELECT_FILTER_COLLECTION_SUBJECT, {
    selectedSubject,
  })

// single collection actions
export const toggleEditingSingleCollectionAction = () =>
  createAction(CollectionActionType.SINGLE_TOGGLE_EDIT)

export const toggleCollDetailWidthAction = (
  id: CollectionPageState['expandingItemID'],
) => createAction(CollectionActionType.EXPAND_COLLECTION_DETAIL, { id })

export const clearExpandingItemIDAction = () =>
  createAction(CollectionActionType.CLEAR_EXPANDING_ITEM_ID)

export const toggleDropDownSingleCollectionAction = () =>
  createAction(CollectionActionType.SINGLE_TOGGLE_DROPDOWN)

export const scrollToBottomOfCollectionListAction = (scroll = true) =>
  createAction(CollectionActionType.SCROLL_TO_BOTTOM_OF_LIST, { scroll })

export const openEditModalAction = (
  value: CollectionPageState['editCollRowItemId'],
) => createAction(CollectionActionType.TOGGLE_EDIT_MODAL, { value })

// other actions
export const navigateToSingleCollectionAction = (
  collectionId: string,
  title: string,
) => {
  const location: LocationDescriptorObject<{ title: string }> = {
    pathname: `/collections/${collectionId}`,
    state: { title },
  }
  return push(location)
}

// new v2 actions
// todo: do we like using createAction()? or is a more traditional redux action fine, even if more verbose?
// collections grid tags/search/filter
export const filterChangeTagsAction = (
  tagValue: CollectionsGridState['tagValue'],
) => ({
  type: CollectionActionType.FILTER_CHANGE_TAGS,
  payload: { tagValue },
})

// single collection page
export const toggleAddItemsModalAction = () =>
  createAction(CollectionActionType.TOGGLE_ADD_ITEMS_MODAL)

export const setCollectionLastVisibleLocationAction = ({
  path,
  index,
}: CollectionPageState['lastVisibleLocation']) =>
  createAction(CollectionActionType.SET_COLLECTION_LAST_VISIBLE_LOCATION, {
    path,
    index,
  })
