import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { reduce } from 'lodash'
import { NavMenuActionType } from '../actions/navMenuActions'
import { NavMenuState } from './reducerTypes'

type ButtonStates = Pick<
  NavMenuState,
  | 'homeIsActive'
  | 'libraryIsActive'
  | 'collectionsIsActive'
  | 'portfolioIsActive'
  | 'dashboardIsActive'
  | 'adminIsActive'
  | 'gradebookIsActive'
  | 'settingsIsActive'
  | 'helpIsActive'
  | 'toolsIsActive'
  | 'notesIsActive'
  | 'notificationsMenuIsActive'
>

const homeIsActive = 'homeIsActive'
const libraryIsActive = 'libraryIsActive'

const routeToActiveMenuButtonMap: Record<string, keyof ButtonStates> = {
  '': homeIsActive,
  login: homeIsActive,
  library: libraryIsActive,
  'math-tools': 'toolsIsActive',
  notes: 'notesIsActive',
  reader: libraryIsActive,
  collections: 'collectionsIsActive',
  portfolio: 'portfolioIsActive',
  dashboard: 'dashboardIsActive',
  admin: 'adminIsActive',
  gradebook: 'gradebookIsActive',
  settings: 'settingsIsActive',
}

const defaultMenuStates = {
  aMenuIsOpen: false,
  notificationsMenuIsOpen: false,
}

const defaultButtonStates = {
  homeIsActive: false,
  libraryIsActive: false,
  collectionsIsActive: false,
  portfolioIsActive: false,
  dashboardIsActive: false,
  adminIsActive: false,
  gradebookIsActive: false,
  settingsIsActive: false,
  helpIsActive: false,
  toolsIsActive: false,
  notesIsActive: false,
  notificationsMenuIsActive: false,
}

export const defaultState: NavMenuState = {
  aMenuWasOpen: false,
  ...defaultMenuStates,
  ...defaultButtonStates,
  prevPath: null,
}

const aDifferentWindowIsOpen = (
  state: NavMenuState,
  menuStateKey: keyof NavMenuState,
) => {
  if (state[menuStateKey]) {
    // if the current window is closed, don't need to check if other windows open
    return false
  }

  // map through other defaultMenuStates keys and see if a window is open
  return reduce(
    defaultMenuStates,
    (acc, val, key) => {
      const stateKey = key as keyof NavMenuState
      if (state[stateKey] && stateKey !== menuStateKey) {
        return acc || state[stateKey]
      }
      return acc
    },
    {} as NavMenuState,
  )
}

type Action =
  | { type: NavMenuActionType.CLOSE_NAV_FLYOUT }
  | { type: NavMenuActionType.TOGGLE_NOTIFICATIONS_MENU }
  | LocationChangeAction

export function navMenuReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case NavMenuActionType.CLOSE_NAV_FLYOUT: {
      return {
        ...state,
        ...defaultMenuStates,
        ...getButtonStates(state.prevPath),
      }
    }

    case NavMenuActionType.TOGGLE_NOTIFICATIONS_MENU: {
      const isOpen = state.notificationsMenuIsOpen
      const buttonStates = isOpen
        ? getButtonStates(state.prevPath)
        : defaultButtonStates
      return {
        ...state,
        ...defaultMenuStates,
        ...buttonStates,
        aMenuIsOpen: !isOpen,
        notificationsMenuIsOpen: !isOpen,
        notificationsMenuIsActive: !isOpen,
        aMenuWasOpen: aDifferentWindowIsOpen(state, 'notificationsMenuIsOpen'),
      }
    }

    case LOCATION_CHANGE: {
      const pathname = action?.payload?.location?.pathname ?? ''
      const basePath = pathname.split('/')[1]
      return {
        ...state,
        ...defaultMenuStates,
        ...getButtonStates(basePath),
        prevPath: basePath,
      }
    }

    default: {
      return state
    }
  }
}

const getButtonStates = (basePath: NavMenuState['prevPath']) => {
  const activeStates: ButtonStates = {
    ...defaultButtonStates,
  }
  if (basePath === null) {
    return activeStates
  }
  const activeName = routeToActiveMenuButtonMap[basePath]
  if (typeof activeName === 'undefined') {
    // no-op
  } else {
    activeStates[activeName] = true
  }
  return activeStates
}
