import { firebaseAuth } from '../services/authentication'

import { config } from '../services/config'

const documentServiceEndpoint = config.get(
  'app.services.documents.serviceEndpoint',
)

export async function negotiateDocumentServiceSession() {
  const firebaseUser = firebaseAuth.currentUser
  if (firebaseUser != null) {
    try {
      const sessionLoginEndpoint = new URL(
        '/auth/sessionLogin',
        documentServiceEndpoint,
      )
      const token = await firebaseUser.getIdToken()
      const sessionResponse = await fetch(sessionLoginEndpoint.toString(), {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (sessionResponse.ok) {
        const payload = await sessionResponse.json()
        const sessionSuccess = payload?.success
        if (sessionSuccess === true) {
          console.log('document service session login succeeded')
          return true
        }
      }
    } catch (err) {
      console.log('document service session login error:', err)
    }
  }

  console.log('document service login rejected')
  return false
}
