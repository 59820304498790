const willPrint = (log) => {
  if (!window.logger.hidden) {
    log()
  } else {
    if (window.logger.hiddenLogs.length >= 100) {
      window.logger.hiddenLogs.shift()
    }
    window.logger.hiddenLogs.push(log)
  }
}

export const initLogger = () => {
  // noinspection JSUnresolvedVariable
  window.logger = {}
  window.logger.hiddenLogs = []

  // hide logdown loggers if on production
  if (process.env.NODE_ENV === 'production') {
    window.localStorage.debug = false
    window.logger.hidden = true
  } else {
    window.localStorage.debug = '*'
    window.logger.hidden = false
  }

  window.logger.show = () => {
    window.logger.hidden = false
    window.localStorage.debug = '*'
    console.log('Showing logs')
    window.logger.hiddenLogs.forEach((log) => log())
    window.logger.hiddenLogs = []
  }

  window.logger.hide = () => {
    window.localStorage.debug = false
    window.logger.hidden = true
    console.log('Hiding logs')
  }

  window.logger.log = (...args) => {
    willPrint(() => console.log(...args))
  }

  window.logger.warn = (...args) => {
    willPrint(() => {
      console.warn(...args)
      // console.trace()
    })
  }

  window.logger.error = (...args) => {
    // show errors no matter what
    console.error(...args)
    // console.trace()
  }

  window.logger.dir = (...args) => {
    willPrint(() => console.dir(...args))
  }
}
