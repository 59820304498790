import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware, { END } from 'redux-saga'
import thunk from 'redux-thunk'
import createReducer from './reducers/index'
import rootSaga from './sagas/rootSaga'
import analyticsMiddleware from './middleware/analytics'
import swMiddleware from './middleware/sw'

const logStyle = `
  color: #14D0FF;
  background-color: #444;
  padding: 2px 4px;
  border-radius: 2px;
`

// noinspection JSUnusedLocalSymbols
const logActionTypeMiddleware = (store) => (next) => (action) => {
  console.log('%cDispatch', logStyle, action.type)
  return next(action)
}

export function configureStore(history) {
  const sagaMiddleware = createSagaMiddleware()

  let middleware = []
  if (process.env.NODE_ENV !== 'production') {
    middleware = middleware.concat([logActionTypeMiddleware])
  }
  middleware = middleware.concat([
    thunk, // todo: re-evaluate if we can remove this dependency and use saga for all async dispatches
    sagaMiddleware,
    analyticsMiddleware,
    swMiddleware,
    routerMiddleware(history),
  ])

  const enhancers = [applyMiddleware(...middleware)]

  // Our Redux store is large and we need to exclude some actions/data
  const actionSanitizer = (action) => action
  const stateSanitizer = (state) => state

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: 'Edwin Core',
          shouldHotReload: false,
          actionSanitizer, // comment out this line to debug excluded actions with redux dev tools
          stateSanitizer, // comment out this line to debug excluded states with redux dev tools
        })
      : compose

  const store = createStore(
    createReducer(history),
    composeEnhancers(...enhancers),
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.close = () => store.dispatch(END)
  store.runSaga(rootSaga)

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (module.hot) {
    module.hot.accept('./reducers/', () => {
      store.replaceReducer(createReducer(history))
    })
  }

  return { store }
}
