import firebase from 'firebase/app'
import { createAction } from './index'

export enum AuthActionType {
  LOGOUT = 'EDWIN/AUTH/LOGOUT',
  STORE_USER_DATA = 'EDWIN/AUTH/USER_DATA/STORE',
  CLEAR_USER_DATA = 'EDWIN/AUTH/CLEAR_USER_DATA',
  SESSION_STARTED = 'EDWIN/AUTH/SESSION_STARTED',
  SESSION_ENDED = 'EDWIN/AUTH/SESSION_ENDED',
  OFFLINE_AUTH_ERROR = 'EDWIN/AUTH/OFFLINE_ERROR',
}

export const logoutAction = () => createAction(AuthActionType.LOGOUT)

export const storeUserDataAction = (token: firebase.auth.IdTokenResult) =>
  createAction(AuthActionType.STORE_USER_DATA, { token })

export const clearUserDataAction = () =>
  createAction(AuthActionType.CLEAR_USER_DATA)

export const startSessionAction = () =>
  createAction(AuthActionType.SESSION_STARTED)

export const endSessionAction = () => createAction(AuthActionType.SESSION_ENDED)
