import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'
import {
  ButtonBlack,
  ACTIVE_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR_40,
  UnreadNotificationIcon,
} from '@edwin-edu/ui-web-components'
import XBlackSVG from '../../assets/svg/icon-x-black.svg'
import InfoWhiteBoldSVG from '../../assets/svg/icon-info-white-bold.svg'
import { useActions } from '../../hooks/useActions'
import { toggleNotificationsMenuAction } from '../../actions/navMenuActions'
import { useUpdateNotificationsLastViewed } from '../../graphql/notifications/notifications'
import { DASHBOARD_PATH, HOME_PATH } from '../../constants'
import { isBefore, parseISO } from 'date-fns'
import {
  useListNotificationsQuery,
  useGetNotificationsProfileQuery,
  useGetNewUserDashboardToastClickedQuery,
} from '../../types/graphql'
import { authService } from '../../services/authentication'

const DASHBOARD = 'Dashboard'
const NOTIFICATION = 'Notification'

type NotificationsToastProps = {
  notificationType?: string
}

export const NotificationsToast: FC<NotificationsToastProps> = ({
  notificationType,
}) => {
  const isTeacher = authService.getIsTeacherOrAdmin()
  const { isExact } = useRouteMatch({ path: HOME_PATH, strict: true }) ?? {
    isExact: false,
  }

  const dashboard = notificationType === DASHBOARD
  const {
    loading,
    error,
    data: dashboardNotification,
  } = useGetNewUserDashboardToastClickedQuery()
  const displayDashboardToast =
    (!dashboardNotification?.user?.newUserDashboardToastClicked && isTeacher) ??
    false
  if (error) console.warn('Dashboard Notification Error:', error)
  const history = useHistory()
  const notificationClicked = () => {
    history.push(DASHBOARD_PATH)
  }

  const notification = notificationType === NOTIFICATION
  const [toggleNotificationsMenu] = useActions([toggleNotificationsMenuAction])
  const { data } = useListNotificationsQuery()
  const notifications = data?.notifications ?? []
  const { data: notificationData } = useGetNotificationsProfileQuery()
  const notificationsViewedTime =
    notificationData?.user?.notificationsViewedTime ?? ''
  const updateNotificationsLastViewed = useUpdateNotificationsLastViewed()
  const unreadExists = notifications
    ?.filter((notification) => {
      return isBefore(parseISO(notification?.availableDate ?? ''), new Date())
    })
    .some((notification) => {
      const availableDate = parseISO(notification?.availableDate ?? '')
      return notificationsViewedTime
        ? isBefore(parseISO(notificationsViewedTime), availableDate)
        : true
    })
  const notificationsShouldAlert =
    (notificationData?.user?.notificationsShouldAlert &&
      unreadExists &&
      !displayDashboardToast) ??
    false

  if (
    loading ||
    (dashboard && !displayDashboardToast) ||
    (notification && !notificationsShouldAlert)
  ) {
    return null
  }

  return (
    <>
      <UnreadNotificationIcon top={6} right={6} />
      {isExact && (
        <Container>
          <OpenButton
            onClick={dashboard ? notificationClicked : toggleNotificationsMenu}
          >
            <div>
              <InfoWhiteBoldSVG />
            </div>
            <span>
              {dashboard ? (
                <FormattedMessage id="NavMenu_dashboardToast" />
              ) : (
                <FormattedMessage id="NavMenu_notificationToast" />
              )}
            </span>
          </OpenButton>
          {!dashboard && (
            <ButtonBlack onClick={updateNotificationsLastViewed}>
              <XBlackSVG />
            </ButtonBlack>
          )}
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  position: absolute;
  display: flex;
  height: 48px;
  left: 54px;
  top: 0px;
  background: #e0f4ff;
  border: 1px solid ${ACTIVE_COLOR};
  box-shadow: 0px 2px 4px rgba(1, 122, 204, 0.25);
  border-radius: 4px;

  z-index: 10;

  button {
    padding: 8px 16px;
  }

  > *:hover {
    transition: background 300ms ease-in;
    background: ${WHITE_COLOR_40};
  }
`
const OpenButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
  padding: 8px 8px 8px 4px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: ${PRIMARY_COLOR};
    box-shadow: 0px 2px 4px rgba(1, 122, 204, 0.25);
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
  }

  > span {
    flex-shrink: 0;
    user-select: none;
  }
`
