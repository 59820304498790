export enum ShellActionType {
  OPEN_EXTERNAL_URL = 'OPEN_EXTERNAL_URL',
  URL_PROTOCOL_ACTIVATION = 'URL_PROTOCOL_ACTIVATION',
}

export function openExternalUrlAction(url: string) {
  window.open(url)
  return {
    type: ShellActionType.OPEN_EXTERNAL_URL,
    payload: { url },
  }
}
