export function checkForServiceWorkerUpdates() {
  const log = window.logger.log

  if ('serviceWorker' in navigator) {
    log('EdwinCore:Waiting for SW.ready...')

    navigator.serviceWorker.ready
      .then((registration) => {
        log('EdwinCore:A service worker is active:', registration.active)

        navigator.serviceWorker.addEventListener('message', (e) => {
          log('EdwinCore:received sw message', e.data)
          const { data } = event
          if (data.command === 'EdwinSWUpdated') {
            window.__EDWIN_SW_UPDATED = true
            navigator.serviceWorker.controller.postMessage({
              command: 'CoreWillRefresh',
            })
          }
        })
      })
      .catch((err) => {
        log(`EdwinCore:ServiceWorker registration failed: ${err}`)
      })
  } else {
    log('Browser does not support serviceWorker')
  }
}
