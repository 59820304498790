// add to this as needed until we do a full TypeScript refactor. useful for typing useSelector
import { useSelector } from 'react-redux'
import { SelectOption } from '@edwin-edu/ui-web-components'
import { STUDENT_LOS_PAGE, STUDENTS_PAGE } from './dashboardReducer'
import { SortDirectionType } from 'react-virtualized/dist/es/Table'
import {
  SearchMetadataCounts,
  SearchFilters,
  LoMetadata,
  CurriculumSubject,
  ProvinceTree,
  Maybe,
} from '../types/graphql'
import { NotesState } from '../containers/reader/Panels/shared/Note'

export type AuthUserData = {
  expirationTime: string | null
  authTime: string | null
  issuedAtTime: string | null
  signInProvider: string | null
  signInSecondFactor: string | null
  name: string | null
  picture: string | null
  userId: string | null
  profile: string | null
  email: string | null
  emailVerified: boolean
}

export type AuthenticationState = {
  authorized: boolean
  userData: AuthUserData
}

export type CollectionPageState = {
  isEditing: boolean
  navDropDownIsOpen: boolean
  addItemsModalIsOpen: boolean
  scrollToBottom: boolean
  collectionDetailExpanded: boolean
  expandingItemID: string
  editCollRowItemId: string
  lastVisibleLocation: {
    path: string | null
    index: number
  }
}

export type CollectionsGridState = {
  createCardIsVisible: boolean
  isSavingCollection: boolean
  selectedSubject: { labelId: string; value: string }
  showFilterDropdown: boolean
  sortMethod: SelectOption
  sortDropDownIsOpen: boolean
  tagOptions: SelectOption[]
  tagValue: SelectOption[]
  cards: unknown
}

export type CollectionsSearchState = {
  searchTerm: string
}

export type DashboardState = {
  dayRange: SelectOption
  joinClassModalisOpen: boolean
  dashboardStudentInfoModalIsVisible: boolean
  dashboardLOInfoModalIsVisible: boolean
  dashboardStudentInfoModalLoId?: string
  tables: {
    [STUDENTS_PAGE]: {
      sortBy: string
      sortDirection: SortDirectionType
    }
    [STUDENT_LOS_PAGE]: {
      sortBy: 'title'
      sortDirection: SortDirectionType
    }
  }
}

export type FractionRowState = Record<string, string[]>

export type MathToolsState = {
  fractionTrayIsCollapsed: boolean
  patternBlocksIsCollapsed: boolean
  algebraTilesIsCollapsed: boolean
  spinnerPanelIsCollapsed: boolean
  integerChipsIsCollapsed: boolean
  baseTenBlocksIsCollapsed: boolean
  barModelIsCollapsed: boolean
  whiteboardIsCollapsed: boolean
  infoScreenIsOpen: boolean
  savedFractionStripsState: FractionRowState
  savedFractionStripsNotes: NotesState
  desmosPanelIsCollapsed: boolean
  desmosPanelType: string
  screenShotIsAnimating: boolean
  screenShotIsDisabled: boolean
}

export type NavMenuState = {
  aMenuWasOpen: boolean
  prevPath: string | null
  aMenuIsOpen: boolean
  notificationsMenuIsOpen: boolean
  homeIsActive: boolean
  libraryIsActive: boolean
  collectionsIsActive: boolean
  portfolioIsActive: boolean
  dashboardIsActive: boolean
  adminIsActive: boolean
  gradebookIsActive: boolean
  settingsIsActive: boolean
  helpIsActive: boolean
  toolsIsActive: boolean
  notesIsActive: boolean
  notificationsMenuIsActive: boolean
}

export type NotesPanelState = {
  isCollapsed: boolean
}

export type ReaderState = {
  learningObjectId: string | null
  enableQuery: boolean
  selectedDocumentId: string
  showScreenshotChoice: boolean
  mathToolScreenShot: string
}

export type ReaderControlsState = {
  aSlideOutPanelIsVisible: boolean
  assessmentPanelIsVisible: boolean
  notesPanelIsVisible: boolean
  relatedLearningObjPanelIsVisible: boolean
  educatorToolsPanelIsVisible: boolean
  searchPanelIsVisible: boolean
  fractionStripsPanelIsVisible: boolean
  patternBlocksPanelIsVisible: boolean
  algebraTilesPanelIsVisible: boolean
  spinnerPanelIsVisible: boolean
  integerChipsPanelIsVisible: boolean
  baseTenBlocksPanelIsVisible: boolean
  barModelPanelIsVisible: boolean
  mathToolsPanelIsVisible: boolean
  whiteboardPanelIsVisible: boolean
  desmosCalcPanelIsVisible: boolean
  mathToolIndexToSaveToGoogle: number | string | boolean
  citationsDropDownIsVisible: boolean
  collectionsDropDownIsVisible: boolean
  aDropDownIsVisible: boolean
  infoModalIsVisible: boolean
  collectionInputIsVisible: boolean
  printDropDownIsVisible: boolean
  scrollToSection?: string
  citationLinkCopied: boolean
}

export type ReaderIFrameState = {
  scrollTop: unknown
  showTopBar: boolean
  tableOfContents: { id: string; title: string; type: string }[]
  loaded: boolean
  sidePanelWidth: number
}

// todo: can import this from react-router?
export type RouterState = {
  location: {
    search: {
      crosslinked: string
    }
  }
}

export type ReadingPosition = {
  learningObjectId: string
  startIndex: number
  endIndex: number
}

export type TextToSpeechState = {
  showInitialControls: boolean
  isPaused: boolean
  showSettingsMenu: boolean
  voiceSpeed: number
  voiceId: number
  readingPositions: { [key: string]: ReadingPosition }
}

export interface Data {
  loId: string
  learningObject: LoMetadata
}

export interface SearchData {
  data: Data
  score: number
  type: string
}

export interface BrowseData {
  data: Data
  score: number
  type: string
}

export type CountResult = {
  id: string
  count: number
}

export interface BrowseCurriculum {
  itemTypes: string[]
  mediaTypes: string[]
  focuses: string[]
  overallExpectations: string[]
  specificExpectations: string[]
  strands: string[]
  subFocuses: string[]
  subjects: string[]
  topics: string[]
  libraryBrowse?: string
}

export type SearchResults = {
  count?: number
  nextPaginationToken?: string
  metadata?: SearchMetadataCounts
  filters?: SearchFilters
}

export type SearchResultsData = {
  data: SearchResults
  score: number
  type: string
}

export type Location = {
  index: number
  path?: string
}

export type LibrarySearchState = {
  searchTerm: string
  updatedDate: string
  filters: SearchFilters
  displaySearchClearButton: boolean
  lastVisibleLocation: Location
  previousSearchResults: SearchResults & { data: SearchResultsData[] }
  searchOutsideUserSubjects: boolean
}

export type BrowseResultsData = {
  data: Data
  score: number
  type: string
}

export type BrowseObject = {
  label: string
  value: string
}

export type LibraryBrowseState = Omit<
  LibrarySearchState,
  'previousSearchResults' | 'searchOutsideUserSubjects'
> & {
  selectedBrowseCurriculum: BrowseCurriculum
  selectedBrowseSubject: BrowseObject
  selectedBrowseTopic: BrowseObject
  selectedBrowseFocus: BrowseObject
  selectedBrowseSubFocus: BrowseObject
  selectedBrowseType: string
  previousBrowseResults: SearchResults & { data: BrowseResultsData[] }
  expandedTocItems: string[]
  storedTocSubjectId?: string
}

export type LibraryPageState = {
  collectionsMenuOpen: string | null
}

export interface CurriculumSubjectNameWithGrade extends CurriculumSubject {
  nameWithGrade?: Maybe<string>
  grade?: number
}

export interface CurriculumMarketingSubject {
  name: string
  subjects: CurriculumSubjectNameWithGrade[]
  grade?: string
  title?: string
}

export interface Gps {
  provinceId: string
  gradeId: string
  subjectId: string
}

export type CurriculumState = {
  gpsArray: Gps[]
  userCurriculum: ProvinceTree[]
  marketingSubjects: CurriculumMarketingSubject[]
  timestamp: number
}

export interface SubjectsObject {
  [key: string]: string[]
}

export type UserFiltersState = {
  provinces: string[]
  grades: string[]
  subjects: SubjectsObject
  isTeacher: boolean
  isInitialized: boolean
}

export interface AppState {
  initialized: boolean
  online: boolean
  resetCache: boolean
  appError: boolean
  locationChangedSinceVersionCheck: boolean
  isUpdateModalOpen: boolean
  rootScrollable: boolean
  appRootMinWidth?: number
}

export interface BreadCrumb {
  path: string
  enabled: boolean
}

export interface BreadcrumbsState {
  crumbs: BreadCrumb[]
}

export type ShellState = {
  activationUrl: string
}

export interface RootState {
  authentication: AuthenticationState
  collectionPage: CollectionPageState
  collectionsGrid: CollectionsGridState
  collectionsSearch: CollectionsSearchState
  dashboard: DashboardState
  mathTools: MathToolsState
  navMenu: NavMenuState
  notesPanel: NotesPanelState
  reader: ReaderState
  readerControls: ReaderControlsState
  readerIFrame: ReaderIFrameState
  router: RouterState
  textToSpeech: TextToSpeechState
  libraryPage: LibraryPageState
  libraryBrowse: LibraryBrowseState
  search: LibrarySearchState
  curriculum: CurriculumState
  userFilters: UserFiltersState
  edwin: AppState
  breadcrumbs: BreadcrumbsState
  shell: ShellState
}

export function useReduxSelector<K>(selector: (state: RootState) => K) {
  return useSelector<RootState, K>(selector)
}
