import { Middleware } from 'redux'
import { LOCATION_CHANGE } from 'connected-react-router'

const middleware: Middleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action)

    if (window.__EDWIN_SW_UPDATED === true && action.type === LOCATION_CHANGE) {
      const state = getState()
      const newPath = action.payload.location.pathname
      if (newPath === '/' && state.router.location.pathname === '/') {
        // no-op
        window.logger.log('__EDWIN_SW_UPDATED true, Initial render. No refresh')
      } else {
        window.logger.log('__EDWIN_SW_UPDATED true, fresh navigation', action)
        location.replace(window.location.origin + newPath)
      }
    }
    return result
  }
}

export default middleware
