import { LOCATION_CHANGE } from 'connected-react-router'
import { ReaderActionType } from '../actions/readerActions'
import { AppActionType } from '../actions/appActions'
import {
  matchHome,
  matchCollections,
  matchDashboard,
  matchSettings,
  matchSearchResults,
  splitPath,
} from '../utils/routes'
import { AppAction } from './appReducer'
import { BreadcrumbsState, BreadCrumb } from './reducerTypes'

export const defaultState: BreadcrumbsState = {
  crumbs: [],
}

interface Crumb {
  path: string
  enbled: boolean
}

interface BreadCrumbsAction extends AppAction {
  crumbs: Crumb[]
}

export function breadcrumbsReducer(
  state = defaultState,
  action: BreadCrumbsAction,
) {
  switch (action.type) {
    case ReaderActionType.READER_PAGE_LOADED:
    case LOCATION_CHANGE: {
      const pathname = action?.payload?.location?.pathname ?? ''
      const { crumbs } = state
      const currentCrumb: BreadCrumb = { path: pathname, enabled: true }
      // exclude crumbs from routes settings
      if (matchSettings(pathname)) {
        return state
      }
      // Clear crumbs when hitting home, collections, library search, or dashboard
      if (
        matchHome(pathname) ||
        matchCollections(pathname) ||
        matchDashboard(pathname) ||
        matchSearchResults(pathname)
      ) {
        return { crumbs: [currentCrumb] }
      }
      // remove outdated crumbs when navigating to previous screens
      for (const [index, crumb] of crumbs.entries()) {
        const crumbSplitPath = splitPath(crumb.path)
        const currentSplitPath = splitPath(pathname)
        if (crumb.path === pathname) {
          // if hitting previously viewed page, remove additional crumbs
          return { crumbs: crumbs.slice(0, index + 1) }
        } else if (crumbSplitPath.basePath === currentSplitPath.basePath) {
          // if hitting previous path with different id, replace old crumb with new path
          return { crumbs: crumbs.slice(0, index).concat([currentCrumb]) }
        }
      }
      // otherwise add new crumb for current screen
      return {
        crumbs: crumbs.concat([currentCrumb]),
      }
    }
    case AppActionType.OVERWRITE_BREADCRUMBS:
      return {
        crumbs: action.crumbs,
      }

    default: {
      return state
    }
  }
}
