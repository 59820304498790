import { CollectionActionType } from '../actions/collectionsActions'
import { CollectionPageState } from './reducerTypes'

export const defaultCollectionState: CollectionPageState = {
  isEditing: false,
  navDropDownIsOpen: false,
  addItemsModalIsOpen: false,
  scrollToBottom: false,
  collectionDetailExpanded: false,
  expandingItemID: '',
  editCollRowItemId: '',
  lastVisibleLocation: {
    path: null,
    index: 0,
  },
}

type Action =
  | { type: CollectionActionType.SINGLE_TOGGLE_EDIT }
  | { type: CollectionActionType.SINGLE_TOGGLE_DROPDOWN }
  | {
      type: CollectionActionType.EXPAND_COLLECTION_DETAIL
      id: CollectionPageState['expandingItemID']
    }
  | { type: CollectionActionType.CLEAR_EXPANDING_ITEM_ID }
  | { type: CollectionActionType.TOGGLE_ADD_ITEMS_MODAL }
  | {
      type: CollectionActionType.SCROLL_TO_BOTTOM_OF_LIST
      scroll: CollectionPageState['scrollToBottom']
    }
  | {
      type: CollectionActionType.TOGGLE_EDIT_MODAL
      value: CollectionPageState['editCollRowItemId']
    }
  | ({
      type: CollectionActionType.SET_COLLECTION_LAST_VISIBLE_LOCATION
    } & CollectionPageState['lastVisibleLocation'])

export function collectionReducer(
  state = defaultCollectionState,
  action: Action,
) {
  switch (action.type) {
    case CollectionActionType.SINGLE_TOGGLE_EDIT: {
      return {
        ...state,
        isEditing: !state.isEditing,
      }
    }

    case CollectionActionType.SINGLE_TOGGLE_DROPDOWN: {
      return {
        ...state,
        navDropDownIsOpen: !state.navDropDownIsOpen,
        isEditing: false,
      }
    }

    case CollectionActionType.EXPAND_COLLECTION_DETAIL: {
      return {
        ...state,
        expandingItemID: action.id,
        collectionDetailExpanded: !state.collectionDetailExpanded,
      }
    }

    case CollectionActionType.CLEAR_EXPANDING_ITEM_ID: {
      return {
        ...state,
        expandingItemID: defaultCollectionState.expandingItemID,
      }
    }

    // Collections V2

    case CollectionActionType.TOGGLE_ADD_ITEMS_MODAL: {
      return {
        ...state,
        addItemsModalIsOpen: !state.addItemsModalIsOpen,
      }
    }

    case CollectionActionType.SCROLL_TO_BOTTOM_OF_LIST: {
      return {
        ...state,
        scrollToBottom: action.scroll,
      }
    }

    case CollectionActionType.TOGGLE_EDIT_MODAL: {
      return {
        ...state,
        editCollRowItemId: action.value,
      }
    }

    case CollectionActionType.SET_COLLECTION_LAST_VISIBLE_LOCATION: {
      const { path, index } = action
      return {
        ...state,
        lastVisibleLocation: {
          ...state.lastVisibleLocation,
          path,
          index,
        },
      }
    }

    default: {
      return state
    }
  }
}
