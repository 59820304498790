import { createAction } from './index'
import { MathToolsState } from '../reducers/reducerTypes'

export enum MathToolsActionType {
  TOGGLE_INFO_SCREEN = 'EDWIN/READER/FRACTION_STRIPS/INFO_SCREEN/TOGGLE',
  RESIZE_FRACTION_STRIPS = 'EDWIN/READER/FRACTION_STRIPS/RESIZE',
  SAVE_FRACTION_STRIPS = 'EDWIN/READER/FRACTION_STRIPS/SAVE',
  RESIZE_PATTERN_BLOCKS = 'EDWIN/READER/PATTERN_BLOCKS/RESIZE',
  RESIZE_ALGEBRA_TILES = 'EDWIN/READER/ALGEBRA_TILES/RESIZE',
  RESIZE_SPINNER = 'EDWIN/READER/SPINNER/RESIZE',
  TOGGLE_DISABLE_SCREENSHOT = 'EDWIN/READER/SPINNER/DISABLE_SCREENSHOT',
  RESIZE_INTEGER_CHIPS = 'EDWIN/READER/INTEGER_CHIPS/RESIZE',
  RESIZE_BASE_TEN_BLOCKS = 'EDWIN/READER/BASE_TEN_BLOCKS/RESIZE',
  RESIZE_BAR_MODEL = 'EDWIN/READER/RESIZE_BAR_MODEL/RESIZE',
  RESIZE_WHITEBOARD = 'EDWIN/READER/WHITEBOARD/RESIZE',
  RESIZE_DESMOS_PANEL = 'EDWIN/READER/DESMOS_PANELS/RESIZE',
  SET_DESMOS_PANEL_TYPE = 'EDWIN/READER/DESMOS_PANELS/SET_TYPE',
  SCREENSHOT_IS_ANIMATING = 'EDWIN/READER/DESMOS_PANELS/ANIMATE_SCREENSHOT',
}

// Fraction strips
export const toggleInfoPanelAction = () =>
  createAction(MathToolsActionType.TOGGLE_INFO_SCREEN)

export const resizeFractionStripsAction = () =>
  createAction(MathToolsActionType.RESIZE_FRACTION_STRIPS)

export const saveFractionStripsAction = (
  stripsState: MathToolsState['savedFractionStripsState'],
  notesState: MathToolsState['savedFractionStripsNotes'],
) =>
  createAction(MathToolsActionType.SAVE_FRACTION_STRIPS, {
    stripsState,
    notesState,
  })

// Pattern Blocks
export const resizePatternBlocksAction = () =>
  createAction(MathToolsActionType.RESIZE_PATTERN_BLOCKS)

// Algebra Tiles
export const resizeAlgebraTilesAction = () =>
  createAction(MathToolsActionType.RESIZE_ALGEBRA_TILES)

// Spinner
export const resizeSpinnerAction = () =>
  createAction(MathToolsActionType.RESIZE_SPINNER)

export const toggleScreenshotDisableAction = () =>
  createAction(MathToolsActionType.TOGGLE_DISABLE_SCREENSHOT)

// Integer Chips
export const resizeIntegerChipsAction = () =>
  createAction(MathToolsActionType.RESIZE_INTEGER_CHIPS)

// Base Ten Blocks
export const resizeBaseTenBlocksAction = () =>
  createAction(MathToolsActionType.RESIZE_BASE_TEN_BLOCKS)

// Bar Model
export const resizeBarModelAction = () =>
  createAction(MathToolsActionType.RESIZE_BAR_MODEL)

// Whiteboard
export const resizeWhiteboardAction = () =>
  createAction(MathToolsActionType.RESIZE_WHITEBOARD)

// Desmos calcs panel
export const resizeDesmosPanelAction = () =>
  createAction(MathToolsActionType.RESIZE_DESMOS_PANEL)

export const setDesmosPanelTypeAction = (panelType: string) =>
  createAction(MathToolsActionType.SET_DESMOS_PANEL_TYPE, { panelType })

// Animations
export const animateScreenshotAction = (
  isAnimating: MathToolsState['screenShotIsAnimating'],
) => createAction(MathToolsActionType.SCREENSHOT_IS_ANIMATING, { isAnimating })
