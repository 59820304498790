// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c0d89ed8c123e1e4431b{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.c2494d18dda06cd4603c{position:relative;margin-left:-55px;z-index:100}.a4625cf3ac611a1e8da5{position:relative;top:-15px}.a4625cf3ac611a1e8da5 svg,.a4625cf3ac611a1e8da5 g{fill:#4a4a4a}", "",{"version":3,"sources":["webpack://./src/core/app/containers/LoadingPage.module.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAIF,sBACE,iBAAA,CACA,iBAAA,CACA,WAAA,CAIF,sBACE,iBAAA,CACA,SAAA,CAEA,kDAEE,YAAA","sourcesContent":["@use '~@edwin-edu/ui/vars' as *;\n\n.page {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  //animation: 200ms ease-out\n}\n\n.spinnerContainer {\n  position: relative;\n  margin-left: -55px;\n  z-index: 100;\n}\n\n//.edwinLogoIconHigherUp = styled(EdwinLogoSvg)`\n.edwinLogoIconHigherUp {\n  position: relative;\n  top: -15px;\n\n  svg,\n  g {\n    fill: #4a4a4a;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "c0d89ed8c123e1e4431b",
	"spinnerContainer": "c2494d18dda06cd4603c",
	"edwinLogoIconHigherUp": "a4625cf3ac611a1e8da5"
};
export default ___CSS_LOADER_EXPORT___;
