// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f491dab3274a5031e2f4{display:flex;padding-top:200px;justify-content:center}.aadd966ea2d212cc4697{padding:80px}.d2a467180501b6abdb53{color:#fff;margin:40px 50px}.fb4f347b88f0fe4da7bc{border-left:1px solid rgba(42,42,42,.5);height:300px}", "",{"version":3,"sources":["webpack://./src/core/app/containers/error/ErrorPage.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,iBAAA,CACA,sBAAA,CAEF,sBACE,YAAA,CAEF,sBACE,UAAA,CACA,gBAAA,CAEF,sBACE,uCAAA,CACA,YAAA","sourcesContent":["@use '~@edwin-edu/ui/vars' as *;\n\n.errorContainer { \n  display: flex;\n  padding-top: 200px;\n  justify-content: center;\n }\n.leftContainer { \n  padding: 80px;\n }\n.rightContainer { \n  color: white;\n  margin: 40px 50px;\n }\n.verticalDivider { \n  border-left: 1px solid rgba(42, 42, 42, 0.5);\n  height: 300px;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": "f491dab3274a5031e2f4",
	"leftContainer": "aadd966ea2d212cc4697",
	"rightContainer": "d2a467180501b6abdb53",
	"verticalDivider": "fb4f347b88f0fe4da7bc"
};
export default ___CSS_LOADER_EXPORT___;
