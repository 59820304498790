import { ShellActionType } from '../actions/shellActions'
import { ShellState } from './reducerTypes'

export const defaultState: ShellState = {
  activationUrl: '',
}

type Action =
  | { type: ShellActionType.OPEN_EXTERNAL_URL }
  | {
      type: ShellActionType.URL_PROTOCOL_ACTIVATION
      payload: { url: ShellState['activationUrl'] }
    }

export function shellReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case ShellActionType.OPEN_EXTERNAL_URL: {
      return state
    }

    case ShellActionType.URL_PROTOCOL_ACTIVATION: {
      return {
        ...state,
        ...{ activationUrl: action.payload.url },
      }
    }

    default: {
      return state
    }
  }
}
