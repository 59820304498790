import { authService } from '../services/authentication'
import { USER_CAPABILITIES } from '../constants'

/***
 * Hook returns true if user has all capabilities provided
 * @param {string|string[]} capability
 * @returns {boolean}
 */
export function useCapability(capability) {
  if (Array.isArray(capability)) {
    capability.every((c) => authService.hasCapability(c))
  } else if (typeof capability === 'string') {
    return authService.hasCapability(capability)
  } else {
    throw new Error('Invalid capability type')
  }
}

export function useUserCapability() {
  return {
    gpsSelf: useCapability(USER_CAPABILITIES.GPS_SELF),
    gpsSingle: useCapability(USER_CAPABILITIES.GPS_SINGLE),
    gpsMulti: useCapability(USER_CAPABILITIES.GPS_MULTI),
  }
}
