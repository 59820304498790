import styles from './StyledComponents.module.scss'
import React, { ReactNode } from 'react'
import { DESMOS_POPOUT_HEIGHT, DESMOS_POPOUT_WIDTH } from '../../constants'
import AlgebraTilesSVG from '../../assets/svg/mathTools/algebraTiles/algebra-tiles-icon.svg'
import FractionStripsSVG from '../../assets/svg/mathTools/fractionStrips/fraction-strips-icon.svg'
import PatternBlocksSVG from '../../assets/svg/mathTools/patternBlocks/pattern-blocks-icon.svg'
import SpinnerSVG from '../../assets/svg/mathTools/spinner/spinner-icon.svg'
import IntegerChipsSVG from '../../assets/svg/mathTools/integerChips/integer-chips-icon.svg'
import BaseTenBlocksSVG from '../../assets/svg/mathTools/baseTenBlocks/base-ten-blocks-icon.svg'
import BarModelSVG from '../../assets/svg/mathTools/barModel/bar-model-icon.svg'
import openPopOut from '../../utils/popOut.js'
import WhiteboardSVG from '../../assets/svg/icon-whiteboard.svg'
interface ToolsMenuItemProps {
  children: ReactNode
  isOpen: boolean
}
export const BottomFlyoutMenu = (props: ToolsMenuItemProps) => {
  const { children, isOpen } = props
  return (
    <ul className={isOpen ? styles.isOpen : styles.isClosed}>{children}</ul>
  )
}

interface ToolsMenuItemProps {
  children: ReactNode
}
export const ToolsMenuItem = (props: ToolsMenuItemProps) => {
  const { children } = props
  return <li className={styles.toolsMenuItem}>{children}</li>
}

type ToolsMenuButtonProps = {
  id: string
  popOutUrl?: string
  popOutWidth?: number
  popOutHeight?: number
  onClick: () => void
  children: React.ReactNode
}
export function ToolsMenuButton({
  id,
  popOutUrl,
  onClick,
  popOutWidth = DESMOS_POPOUT_HEIGHT,
  popOutHeight = DESMOS_POPOUT_WIDTH,
  children,
  ...rest
}: ToolsMenuButtonProps) {
  const onClickAndPopOut = () => {
    popOutUrl && openPopOut(popOutUrl, popOutWidth, popOutHeight)
    onClick && onClick()
  }

  return (
    <button
      className={styles.toolsMenuButtonStyles}
      onClick={onClickAndPopOut}
      id={id}
      {...rest}
    >
      {children}
    </button>
  )
}
interface ToolsMenuItemTitleProps {
  blue: boolean
  isActive: boolean
  children: React.ReactNode
}
export const ToolsMenuItemTitle = ({
  isActive,
  children,
}: ToolsMenuItemTitleProps) => {
  return (
    <span className={isActive ? styles.isActive : styles.toolsMenuItemTitle}>
      {children}
    </span>
  )
}
interface ToolsMenuItemDescProps {
  children: React.ReactNode
}
export const ToolsMenuItemDesc = ({ children }: ToolsMenuItemDescProps) => {
  return <span className={styles.toolsMenuItemDesc}>{children}</span>
}
export const AlgebraTilesIcon = () => {
  return <AlgebraTilesSVG className={styles.smallerIcon} />
}
export const FractionStripsIcon = () => {
  return <FractionStripsSVG className={styles.smallerIcon} />
}
export const PatternBlocksIcon = () => {
  return <PatternBlocksSVG className={styles.smallerIcon} />
}
export const IntegerChipsIcon = () => {
  return <IntegerChipsSVG className={styles.mathToolIcon} />
}
export const BaseTenBlocksIcon = () => {
  return <BaseTenBlocksSVG className={styles.mathToolIcon} />
}
export const BarModelIcon = () => {
  return <BarModelSVG className={styles.smallerIcon} />
}
export const SpinnerIcon = () => {
  return <SpinnerSVG className={styles.mathToolIcon} />
}
export const WhiteboardIcon = () => {
  return <WhiteboardSVG className={styles.mathToolIcon} />
}
